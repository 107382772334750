import { useState } from "react";
import StyledModal from "../../../../../components/StyledModal/StyledModal";
import Spinner from "../../../../../components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { updateCourseStatus } from "../../../../../store/actions/courseAction";

function PublishedCourseCourseConfirmModal({
  open,
  handleClose,
  data,
  isPreviewRedirection,
}) {
  const dispatch = useDispatch();
  const handleOk = () => {
    setIsLoading(true);
    dispatch(
      updateCourseStatus(
        data?.id,
        "PUBLISHED",
        data?.course_name,
        handleClose,
        isPreviewRedirection
      )
    );
  };
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  return (
    <StyledModal
      className={"draft-delete-modal"}
      centred
      title={"Publish Course"}
      visible={open}
      onCancel={handleClose}
      onOk={handleOk}
      closable={true}
      footer={isLoading ? null : undefined}
    >
      <div
        style={{
          marginTop: "30px",
          marginBottom: isLoading ? "20px" : "",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        {isLoading ? (
          <Spinner text={"Publishing Course..."} />
        ) : (
          `Do you want to publish "${data?.course_name}"?`
        )}
      </div>
    </StyledModal>
  );
}

export default PublishedCourseCourseConfirmModal;
