import { Steps } from "antd";
import "./GlobalStepper.scss";

const GlobalStepper = ({ current, items, ...restProps }) => {
  return (
    <div className="globalStepper-wrapper" data-testid="globalStepper-wrapper">
      <Steps current={current} items={items} {...restProps} />
    </div>
  );
};

export default GlobalStepper;
