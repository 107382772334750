import { useDispatch, useSelector } from "react-redux";
import "./ReviewsSection.scss";
import { getAllRating } from "../../../../../store/actions/ratingActions";
import { useEffect, useRef, useState } from "react";
import { ratingsSelector } from "../../../../../store/selectors/ratingSelector";
import Spinner from "../../../../../components/Spinner/Spinner";

import { ALL_RATINGS, REMOVE_RATINGS } from "../../../../../store/actiontypes";
import ReviewsCommentList from "../../../../../components/ReviewsCommentsList/ReviewsCommentList";

const ReviewsSection = (props) => {
  const { courseId } = props;
  const [isLoading, setIsLoading] = useState(true);
  const reviewListParentRef = useRef(null);
  const dispatch = useDispatch();
  const ratings = useSelector(ratingsSelector);
  const allRatings = () => {
    setIsLoading(true);

    dispatch(
      getAllRating(
        {
          course: courseId,
        },
        ALL_RATINGS,
        () => {
          setIsLoading(false);
        }
      )
    );
  };
  useEffect(() => {
    allRatings();
  }, [courseId]);

  useEffect(() => {
    return () => {
      dispatch({ type: REMOVE_RATINGS });
    };
  }, []);
  return (
    <div
      className="review-side"
      // style={ratings?.results.length == 0 ? { height: "100vh" } : null}
      ref={reviewListParentRef}
    >
      <div className="review-header">Reviews</div>
      {isLoading ? (
        <Spinner />
      ) : reviewListParentRef.current ? (
        <ReviewsCommentList ratings={ratings} parentRef={reviewListParentRef} />
      ) : null}
    </div>
  );
};

export default ReviewsSection;
