import {
  // faCalendarAlt,
  faFile,
  // faFileCode,
  faListAlt,
  faUser,
  faWindowMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CourseIcons.scss";
const Contents = (props) => {
  const icon = props.icon;
  const label = props.label;
  return (
    <div className="contents-each">
      <FontAwesomeIcon size="lg" icon={icon} color="#4B4B4B" />
      <label>{label}</label>
    </div>
  );
};

const CourseIcons = (props) => {
  const { course } = props;
  const isPublicUrl = window.location.href.includes("public");

  return (
    <div className="progress-section" data-testid="progress-section">
      <div className="values-display flex-grow">
        <Contents icon={faUser} label={`${course.no_of_enrolls} Enrolled`} />
        {/* <Contents icon={faCalendarAlt} label={course.estimated_time} /> */}
        <Contents icon={faWindowMaximize} label={course.category} />
        {isPublicUrl && (
          <Contents
            icon={faListAlt}
            label={course.modules?.length + " Modules"}
          />
        )}
      </div>
      {!isPublicUrl && (
        <div className="values-display">
          <Contents
            icon={faFile}
            label={course.modules?.length + " Assignments"}
          />

          <Contents
            icon={faListAlt}
            label={course.modules?.length + " Modules"}
          />
        </div>
      )}
    </div>
  );
};
export default CourseIcons;
