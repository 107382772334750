import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "white";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "#696969";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.padding = "14px";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const StatisticsCustomTooltip = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const calendarIcon = (
        <FontAwesomeIcon icon={faCalendar} style={{ marginRight: "5px" }} />
      );

      const iconContainer = document.createElement("div");

      ReactDOM.render(calendarIcon, iconContainer);

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      th.style.display = "flex";
      const text = document.createTextNode(title);
      iconContainer.appendChild(text);

      th.appendChild(iconContainer);
      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, lineIndex) => {
      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      if (Array.isArray(body) && body.length > 0) {
        const words = body[0].split(" ");
        for (let i = 0; i < words.length; i++) {
          const word = words[i];
          const text = document.createTextNode(word);

          if ((lineIndex === 0 && i === 0) || (lineIndex === 1 && i <= 1)) {
            // Change the font weight of the first word of the first line and the first two words of the second line
            const wordSpan = document.createElement("span");
            wordSpan.style.fontWeight = "bold";
            wordSpan.style.fontSize = "15px";
            wordSpan.appendChild(text);
            td.appendChild(wordSpan);
          } else {
            td.appendChild(text);
          }

          // Add a space if this is not the last word
          if (i < words.length - 1) {
            td.appendChild(document.createTextNode(" "));
          }
        }
      }
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltip.yAlign) {
    tooltipEl.classList.add(tooltip.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  const position = chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left =
    position.left + window.pageXOffset + tooltip.caretX + "px";
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.boxShadow = "0px 4px 4px rgba(0, 0, 0, 0.25)";
  tooltipEl.style.backgroundColor = "#FFF";
};

export default StatisticsCustomTooltip;
