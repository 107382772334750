import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./RepositoryStep.scss";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Link } from "../../../../assets/link.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { wizardStepsSelector } from "../../../../store/selectors/wizardSelectors";
import { createRepo } from "../../../../store/actions/wizardActions";
import RepoLoader from "../../../../components/RepoLoader/RepoLoader";
import Banner from "../../../../components/Banner/Banner";
import {
  CREATE_REPO_LOADING,
  RESPOSITORY_STEP_ERROR_BANNER,
} from "../../../../store/actiontypes";
function RepositoriesStep() {
  const dispatch = useDispatch();
  const [loading, setisLoading] = useState(false);
  const [error, setIsError] = useState(false);
  const steps = useSelector(wizardStepsSelector);
  useEffect(() => {
    if (steps.stepThree == undefined) {
      setisLoading(true);
      dispatch({ type: CREATE_REPO_LOADING, payload: true });
      dispatch(
        createRepo(
          {
            course_id: steps?.stepOne?.id,
            repo_name:
              steps?.stepOne?.course_name.replace(/\s+/g, "_") +
              "_" +
              Math.floor(Math.random() * Date.now()).toString(36),
          },
          setisLoading,
          setIsError
        )
      );
    }
  }, [steps?.stepOne?.id]);
  return (
    <>
      {error ? (
        <Banner id={RESPOSITORY_STEP_ERROR_BANNER} />
      ) : (
        <>
          {" "}
          {loading ? (
            <>
              <RepoLoader />
            </>
          ) : (
            <>
              {" "}
              <div className="main_container">
                <h3>
                  Repositories &nbsp;
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    style={{ color: "#030303" }}
                  />
                </h3>
                <p>
                  Hurray! We have created repositories for you. Kindly go
                  through the documentation and push your contents in those
                  repositories.
                </p>
                <div className="link_container">
                  <h4>
                    Boilerplate code &nbsp;{" "}
                    <a
                      href={steps?.stepThree?.boilerplate}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Link />
                    </a>
                  </h4>
                  <p>
                    Boilerplate Code Repository is a streamlined template
                    designed for student developers. It offers a structured
                    starting point for writing their code, incorporating
                    essential files, guidelines, and a suggested project
                    structure. For more information <a> click here.</a>
                  </p>
                </div>
                <div className="link_container">
                  <h4>
                    Test Repository &nbsp;{" "}
                    <a
                      href={steps?.stepThree?.test_repo}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Link />
                    </a>
                  </h4>
                  <p>
                    The Test Repo is a separate repository that contains
                    pre-written unit tests and test cases corresponding to the
                    Boilerplate Repository. It allows students to validate their
                    code implementations. For more information{" "}
                    <a> click here.</a>
                  </p>
                </div>
                <div className="link_container">
                  <h4>
                    Solutions &nbsp;{" "}
                    <a
                      href={steps?.stepThree?.solutions}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Link />
                    </a>
                  </h4>
                  <p>
                    The Solutions Repo is a separate repository that provides
                    exemplary solutions and implementations for the assignments
                    or projects associated with the Boilerplate Repository. For
                    more information <a> click here.</a>
                  </p>
                </div>
              </div>{" "}
            </>
          )}
        </>
      )}
    </>
  );
}

export default RepositoriesStep;
