export const questionFormOptions = [
  {
    value: "Single",
    label: "Single choice",
  },
  {
    value: "Multiple",
    label: "Multiple choice",
  },
  {
    value: "Subjective",
    label: "Subjective",
  },
];

export const quillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
  ],

  clipboard: {
    matchVisual: false,
  },
};

export const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const public_page_constants = {
  PASSED_MESSAGE: (course_name) =>
    `it seems you're uptodate with ${course_name}. Would you like to review a crash course.`,
  FAILED_MESSAGE: (course_name) =>
    `How about a refresher course on ${course_name}?`,
  RESULT_DECREASE: (course_name) =>
    `Would you consider signing up for a full course on ${course_name}?`,
  EIGENEVAL_TEXT:
    "EigenLearn is a comprehensive learning platform designed to support a wide range of training needs, including technical stack training, security training, organizational training, and more.",
};
