import { useNavigate } from "react-router-dom";
import "./Footer.scss";

function Footer() {
  const navigate = useNavigate();
  let footerclass = "footer-main-container";

  if (window.location.href.includes("/start/public")) {
    footerclass = "testfooter";
  }

  return (
    <div role="footer-contentinfo">
      <div className={footerclass}>
        <div className="footer-content">
          <span>
            Powered by{" "}
            <a
              className="footer-link"
              href="https://www.coriolis.co.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Coriolis Technologies Pvt. Ltd.
            </a>
          </span>
          <span
            className="footer-link"
            onClick={() => {
              navigate("/public/privacy_policy");
            }}
          >
            Privacy policy
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
