import { Select } from "antd";

function MultiSelectDropDown({ selectProps }) {
  return (
    <>
      <Select {...selectProps} />
    </>
  );
}
export default MultiSelectDropDown;
