import { actionFactory } from "../../libs/actionFactory";
import { ADD_RATING, ALL_RATINGS, MY_RATING } from "../actiontypes";

export const postRating = (ratingDetails, successCallback) => {
  return actionFactory({
    api: "post/api/ratings/submit/",
    actionBody: {
      body: ratingDetails,
    },
    failureToast: true,
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: ADD_RATING,
              payload: resp["data"].data,
            });
            dispatch(
              getAllRating(
                {
                  course: ratingDetails.course_id,
                  user: ratingDetails.user_id,
                },
                MY_RATING
              )
            );
            dispatch(
              getAllRating({
                course: ratingDetails.course_id,
              })
            );
            successCallback();
          }
        })
        .catch((_err) => {});
    },
  });
};

export const updateRating = (id, ratingDetails, successCallback) => {
  return actionFactory({
    api: `patch/api/ratings/${id}/`,
    actionBody: {
      body: {
        comment: ratingDetails.comment,
        rating: ratingDetails.rating,
      },
    },
    failureToast: true,
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch(
              getAllRating({
                course: ratingDetails.course_id,
                user: ratingDetails.user_id,
              })
            );
            successCallback();
          }
        })
        .catch((_err) => {});
    },
  });
};

export const getAllRating = (
  params = {},
  actiontype = ALL_RATINGS,
  callback = () => {}
) => {
  return actionFactory({
    api: "get/api/ratings/",
    actionBody: {
      params: { ...params },
    },
    failureToast: true,
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: actiontype,
              payload: resp["data"].data,
            });
            callback();
          }
        })
        .catch((_err) => {});
    },
  });
};
