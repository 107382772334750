/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useSelector } from "react-redux";
import {
  publicModuleIndexSelector,
  selectedQuizIndexSelector,
} from "../../store/selectors/coursesSelectors";
import {
  setPublicModuleIndex,
  setSelectedQuizIndex,
} from "../../store/actions/courseAction";
import { CURRENT_MODULE } from "../../store/actiontypes";
import { useDispatch } from "react-redux";

const ExpoTestContent = ({ name, description, course }) => {
  const navigate = useNavigate();
  const publicModuleIndex = useSelector(publicModuleIndexSelector);
  const selectedQuizIndex = useSelector(selectedQuizIndexSelector);
  const dispatch = useDispatch();

  const startTest = (id) => {
    if (course) {
      navigate(`/tests/${id}/start/public`);
      window.location.reload();
    }
  };

  const combinedDetails = [
    ...(course[0]?.module_details || []),
    ...(course[0]?.mcq_details || []),
  ];

  const handleModuleChange = (direction) => {
    const newIndex = publicModuleIndex + direction;
    const moduleDetailsLength = course[0]?.module_details.length || 0;

    if (newIndex >= 0 && newIndex < combinedDetails.length) {
      // For modules_details
      if (newIndex < moduleDetailsLength) {
        dispatch(setPublicModuleIndex(newIndex));
        dispatch({
          type: CURRENT_MODULE,
          payload: combinedDetails[newIndex],
        });
      }
      // For  mcq_details
      else {
        const quizIndex = newIndex - moduleDetailsLength;
        dispatch(setPublicModuleIndex(newIndex));
        dispatch({
          type: CURRENT_MODULE,
          payload: course[0]?.mcq_details[quizIndex],
        });
        dispatch(setSelectedQuizIndex(quizIndex));
      }
    }
  };

  return (
    <div className="test-container">
      <div className="about-test-header">
        <div className="about-module-module-name">{name}</div>
        <div className="number-of-questions">
          {course
            ? course[0]?.mcq_details[selectedQuizIndex]?.total_questions
            : "9000"}{" "}
          <span className="number-of-questions-span">Questions</span>
        </div>
      </div>
      <div className="about-module-subtitle">{description}</div>
      <div className="test-startbutton-container">
        <Button
          className="test-start-button"
          onClick={() =>
            startTest(course[0]?.mcq_details[selectedQuizIndex]?.mcq.id)
          }
        >
          Start
        </Button>
      </div>

      <div className="quiz-section-buttons">
        <Button
          className={`quiz-section-button ${
            publicModuleIndex === 0 && "disabled"
          }`}
          onClick={() => handleModuleChange(-1)}
          disabled={publicModuleIndex === 0}
        >
          Previous
        </Button>
        <Button
          className={`quiz-section-button ${
            publicModuleIndex === combinedDetails.length - 1 && "disabled"
          }`}
          onClick={() => handleModuleChange(1)}
          disabled={publicModuleIndex === combinedDetails.length - 1}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ExpoTestContent;
