import { MoreOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { ReactComponent as Approve } from "../../../../../assets/approve.svg";
import { ReactComponent as Preview } from "../../../../../assets/Preview.svg";
import { ReactComponent as Reject } from "../../../../../assets/reject.svg";
import { useDispatch } from "react-redux";
import { isPreviewConstant } from "../../../../../store/actions/wizardActions";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import RejectCourseConfirmModal from "./RejectCourseConfirmModal";
import PublishedCourseCourseConfirmModal from "./PublishedCourseConfirmModal";
import moment from "moment";
const items = [
  {
    label: <div className="dropdown-items">Preview</div>,
    key: "Preview",
    icon: <Preview />,
  },
  {
    label: <div className="dropdown-items">Publish</div>,
    key: "Publish",
    icon: <Approve />,
  },
  {
    label: <div className="dropdown-items">Reject</div>,
    key: "Reject",
    icon: <Reject />,
  },
];
const PendingCourseList = ({ data }) => {
  const handleMenuClick = (e, courseId) => {
    if (e.key == "Publish") handlePublishedCourse(courseId);
    if (e.key == "Reject") handleRejectCourse(courseId);
    if (e.key == "Preview") previewClickHandler(courseId);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRejectedModalOpen, setIsRejectedCourseModalOpen] = useState(false);
  const [isPublishedModalOpen, setIsPublishedCourseModalOpen] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const handleRejectCourse = (courseId) => {
    openRejectCourseConfirmModal(courseId);
  };
  const handlePublishedCourse = (courseId) => {
    openPublishedCourseConfirmModal(courseId);
  };
  const openRejectCourseConfirmModal = (courseId) => {
    setIsRejectedCourseModalOpen(true);
    setCourseData(data.find((o) => o.id === courseId));
  };
  const openPublishedCourseConfirmModal = (courseId) => {
    setIsPublishedCourseModalOpen(true);
    setCourseData(data.find((o) => o.id === courseId));
  };

  const closeRejectCourseConfirmModal = () => {
    setIsRejectedCourseModalOpen(false);
  };
  const closePublishedCourseConfirmModal = () => {
    setIsPublishedCourseModalOpen(false);
  };
  const previewClickHandler = (id) => {
    dispatch(isPreviewConstant(true));
    navigate(`/courses/${id}/preview`);
  };

  return (
    <>
      <RejectCourseConfirmModal
        open={isRejectedModalOpen}
        handleClose={closeRejectCourseConfirmModal}
        data={courseData}
      />
      <PublishedCourseCourseConfirmModal
        open={isPublishedModalOpen}
        handleClose={closePublishedCourseConfirmModal}
        data={courseData}
      />
      {data?.map((course) => {
        const { id, course_name, created_by } = course;
        const menuProps = {
          items,
          onClick: (e) => {
            return handleMenuClick(e, id, course_name);
          },
        };
        return (
          <>
            <div className="pending-courses-container" key={id}>
              <div className="userList-title">
                <div>{course_name}</div>
              </div>
              <div className="userList-title">
                {created_by === null ? "-" : created_by.user_name}
              </div>
              <div className="userList-title">-</div>
              <div className="userList-title">
                {moment
                  .utc(created_by?.updated_at)
                  .format("MMMM D, YYYY, hh:mm A")}
              </div>
              <div className="userList-option">
                <Dropdown
                  menu={menuProps}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <a
                    data-testid="pending-dropdown"
                    onClick={(e) => e.preventDefault}
                  >
                    {" "}
                    <MoreOutlined
                      style={{
                        fontSize: "16px",
                        color: "#000",
                      }}
                    />
                  </a>
                </Dropdown>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
export default PendingCourseList;
