import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import "./McqPreviewMode.scss";
import McqQuestionSection from "./McqQuestionSection";
import Spinner from "../../../../components/Spinner/Spinner";
import MenuDropDown from "../../../../components/MenuDropDown/MenuDropDown";
import {
  getMcqs,
  wizardGetParticularTest,
} from "../../../../store/actions/wizardActions";
import {
  currentEditingQuestions,
  currentEditingTestDetails,
} from "../../../../store/selectors/wizardSelectors";

const McqPreviewMode = () => {
  const dispatch = useDispatch();
  const testData = useSelector(currentEditingTestDetails);
  const questionData = useSelector(currentEditingQuestions)?.results;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedDropdownValue, setSelectedDropdownValue] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const testId = location.pathname.split("/")[4];

  const currentQuestion = questionData?.[currentQuestionIndex];

  const { total_time_limit_of_test, test_name, time_limit_for_each_question } =
    testData || {};
  const { question_text, question_type, options } = currentQuestion || {};

  // For conversion of added questions with html tag to plain text.
  const question = parse(String(question_text));

  const option =
    questionData?.map((question, index) => ({
      label: `Question ${index + 1}`,
      value: index,
    })) || [];

  const getDefaultValue = () => {
    let selected = option.filter(
      (o) => o.label === `Question ${currentQuestionIndex + 1}`
    )[0];
    return selected;
  };

  const menuDropDownHandler = (selectedValue) => {
    const getQuestionNumber = selectedValue[0]?.value;
    setCurrentQuestionIndex(getQuestionNumber);
    setSelectedDropdownValue(getQuestionNumber);
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) =>
      Math.min(prevIndex + 1, questionData?.length - 1)
    );
    setSelectedDropdownValue(currentQuestionIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setSelectedDropdownValue(currentQuestionIndex - 1);
  };

  //To store the data in redux.
  useEffect(() => {
    if (testId) {
      dispatch(wizardGetParticularTest(testId, undefined, setIsLoading));
      dispatch(getMcqs(testId));
    }
  }, [testId]);

  useEffect(() => {
    if (questionData && testData) {
      setIsLoading(false);
    }
  }, [questionData, testData]);

  return isLoading ? (
    <Spinner />
  ) : questionData && testData ? (
    <>
      <div className="previewMode-container">
        <div className="previewMode-header-1">
          <div>{test_name}</div>
          <div>
            Time to answer:
            <div>
              {total_time_limit_of_test || time_limit_for_each_question}
            </div>
          </div>
        </div>
        <div className="previewMode-header-2">
          <div>
            <FontAwesomeIcon icon={faEye} />
            Preview Mode
          </div>
          <button onClick={window.close}>Close Preview</button>
        </div>
        <div className="previewMode-question-section">
          <McqQuestionSection
            currentQuestionIndex={currentQuestionIndex}
            questionData={questionData}
            question={question}
            question_type={question_type}
            options={options}
          />
        </div>
        <div className="previewMode-last-section">
          <MenuDropDown
            options={option}
            defaultSelected={() => getDefaultValue()}
            dropdownPosition="auto"
            onClickHandle={(selectedValue) =>
              menuDropDownHandler(selectedValue)
            }
            selected={selectedDropdownValue}
          />
          <div>
            <button
              className={`previewMode-last-section-button ${
                currentQuestionIndex === 0 && "disabled"
              }`}
              onClick={handlePreviousQuestion}
              disabled={currentQuestionIndex === 0}
            >
              Previous
            </button>
            <button
              className={`previewMode-last-section-button ${
                currentQuestionIndex === questionData?.length - 1 && "disabled"
              }`}
              onClick={handleNextQuestion}
              disabled={currentQuestionIndex === questionData?.length - 1}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  ) : (
    navigate("/404")
  );
};

export default McqPreviewMode;
