var constants = {
  LOGIN_PAGE_URL: "login",
  PUBLIC_RESULT_PAGE: "/tests/:id/start/public/results",
  PRIVACY_URL: "/public/privacy_policy",
  PUBLIC_CONTENT_PAGE: "/courses/:id/public/content",
  PUBLIC_COURSE_PAGE: "/courses/:id/public/course",
  PUBLIC_COURSE: "/tests/:id/start/public/",
  USERS_PAGE_URL: "users",
  PIPELINE_STATUS: {
    passed: "success",
    failed: "failed",
    running: "running",
    approved: "approved",
    notaprroved: "notapproved",
    waitingforapproval: "waitingforapproval",
    COMPLETED: "COMPLETED",
    LOCKED: "LOCKED",
    IN_PROGRESS: "IN_PROGRESS",
    VIEW: "VIEW",
  },
  SUCCESS_TOAST: "success",
  ERROR_TOAST: "error",
  INFO_TOAST: "info",
  WARNING_TOAST: "warning",
  UN_ENROLL: "Unenroll",
  ENROLL: "Enroll",
  ASSIGNMENT_TOOLTIP:
    "Assignment link will be available after the previous module is completed.",
  EMPTY_DATA: "No data to show",
  PORTAL_ADMIN: "PORTAL_ADMIN",
  CREATORS: "CREATORS",
  CANDIDATE: "CANDIDATE",
  HR: "HR",
};
export const USER_LIST_ID = "USER_LIST_ID";
export const CANDIDATE_LIST_ID = "CANDIDATE_LIST_ID";
export const AUTHORED_LIST_ID = "AUTHORED_LIST_ID";
export const QUESTION_LIST_ID = "QUESTION_LIST_ID";
export const ENROLL_LIST_ID = "ENROLL_LIST_ID";
export const PUBLISHED_COURSES_ID = "PUBLISHED_COURSES_ID";
export const GRAPH_SUMMARY_MODAL_ID = "GRAPH_SUMMARY_MODAL_ID";
export const PORTAL_ADMIN_USERS_MENU = [
  {
    label: "Enroll",
    key: "Enroll",
  },
  {
    label: "Edit",
    key: "Edit",
  },
  {
    label: "Archive",
    key: "Archive",
  },
];

export const PORTAL_ADMIN_ROLES_MENU = [
  {
    label: "PORTAL_ADMIN",
    value: 1,
  },
  {
    label: "CANDIDATE",
    value: 2,
  },
  {
    label: "CREATOR",
    value: 3,
  },
  {
    label: "HR",
    value: 4,
  },
];
export const USER_STATUS = [
  {
    label: "active",
    key: false,
  },
  {
    label: "inactive",
    key: true,
  },
];
export const COMPLETION_STATUS = [
  {
    label: "false",
    key: false,
  },
  {
    label: "true",
    key: true,
  },
];

export const CANDIDATES_TAB_FILTERS = [
  { filterName: "Last Month", value: "LAST_MONTH" },
  { filterName: "Current Year", value: "CURRENT_YEAR" },
  { filterName: "Last Year", value: "LAST_YEAR" },
];

export const selfUser =
  'Please confirm whether you would like to assign the role of "{groups}" to yourself. After this you will be logged out...';

export const otherUsers =
  'Please confirm whether you would like to assign the role of "{groups}" to "{name}".';

export const CONTACT_US_EIGENEVAL = "contact@eigeneval.com";
export default constants;
