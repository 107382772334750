import { Button, Form, Rate } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRating,
  postRating,
  //updateRating,
} from "../../store/actions/ratingActions";
import { myRatingsSelector } from "../../store/selectors/ratingSelector";
import Spinner from "../Spinner/Spinner";
import "./UserFeedbackCard.scss";
import { loadSelectedCourse } from "../../store/actions/courseAction";
import { useParams } from "react-router-dom";
import { MY_RATING } from "../../store/actiontypes";
import { Field, Formik } from "formik";
import { reviewComment } from "../../features/CourseListFeature/WizardCourseSteps/GeneralInfoStep/CourseFormUtils";
import { StyledInputField } from "../StyledFields/StyledInputField";
import { StyledRatingInput } from "../StyledFields/StyledRatingInput";

const UserFeedbackCard = (props) => {
  const { userId, courseId } = props;
  const [isEditRating, setisEditRating] = useState(false);
  const [value, setValue] = useState(0);
  const [text, setText] = useState("");
  //const [isUpdateRating, setisisUpdateRating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const ref = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const allRatings = () => {
    dispatch(
      getAllRating(
        {
          course: courseId,
          user: userId,
        },
        MY_RATING
      )
    );
  };

  useEffect(() => {
    allRatings();
  }, []);
  const ratings = useSelector(myRatingsSelector);
  useEffect(() => {
    if (ratings?.results.length > 0) {
      setisEditRating(true);
      setValue(ratings?.results[0].rating);
      setText(ratings?.results[0]?.comment);
    }
    setText(ratings?.results[0]?.comment);
    setIsLoading(false);
  }, [userId, courseId, ratings?.results.length, isEditRating]);
  // const onCancel = () => {
  //   setValue(ratings?.results[0].rating);
  //   setisEditRating(true);
  //   setisisUpdateRating(false);
  // };
  const successCallBack = () => {
    setSubmitIsLoading(false);
    dispatch(loadSelectedCourse(id));
  };
  const onSubmit = () => {
    //if (!isUpdateRating) {
    dispatch(
      postRating(
        {
          user_id: userId,
          course_id: courseId,
          comment: ref?.current?.values?.comment,
          rating: ref?.current?.values?.rating,
        },
        successCallBack
      )
    );
    //}
    // else {
    //   dispatch(
    //     updateRating(
    //       ratings?.results[0].id,
    //       {
    //         user_id: userId,
    //         course_id: courseId,
    //         comment: ref?.current?.values?.comment,
    //         rating: ref?.current?.values?.rating,
    //       },
    //       successCallBack
    //     )
    //   );
    //   setisEditRating(true);
    //   setisisUpdateRating(false);
    // }
    setSubmitIsLoading(true);
  };
  // const onEditDetails = () => {
  //   setisEditRating(false);
  //   setisisUpdateRating(true);
  // };
  const INITIAL_FORM_VALUES = {
    rating: value ? value : 0,
    comment: text ? text : "",
  };
  return (
    <div className="review-section" data-testid="user-feedback-card">
      {isLoading ? (
        <div data-testid="spinner">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="course-subtitle" data-testid="rating-subtitle">
            {isEditRating ? "My review" : "Rate this Course"}
          </div>
          {isEditRating ? null : (
            <div className="module-subtitle" data-testid="rating-description">
              {" "}
              Tell others what you think
            </div>
          )}

          {isEditRating ? (
            <>
              <div className="edit_subtitle">
                <Rate value={value} disabled className="rate-edit" />
                <p className="date" data-testid="date">
                  {moment(ratings.results[0]?.updated_at).format(
                    "dddd, MMMM Do YYYY"
                  )}
                </p>
              </div>
              <p className="textarea" data-testid="textarea">
                {ratings.results[0]?.comment}
              </p>
            </>
          ) : (
            <>
              <Formik
                fluid
                innerRef={ref}
                validateOnChange
                validateOnBlur
                validateOnMount
                validationSchema={reviewComment}
                initialValues={INITIAL_FORM_VALUES}
                enableReinitialize
                isInitialValid={false}
              >
                {({ setFieldValue, isValid }) => (
                  <Form className="form" data-testid="form-div">
                    <Field
                      label=""
                      id="rating"
                      name="rating"
                      type="number"
                      data-testid="ratecomponent"
                      component={StyledRatingInput}
                    />
                    <Field
                      label=""
                      id="comment"
                      name="comment"
                      type="textarea"
                      onChange={(event) => {
                        setFieldValue("comment", event.target.value);
                      }}
                      className="my-textarea"
                      data-testid="commentsection"
                      showCount={true}
                      maxLength={255}
                      rows={4}
                      autoSize={{
                        minRows: 3,
                        maxRows: 3,
                      }}
                      component={StyledInputField}
                      placeholder="Comment*"
                    />
                    {!isEditRating ? (
                      <div className="edit-subtitle">
                        {" "}
                        {
                          //isUpdateRating ? (
                          //   <Button
                          //     type="primary"
                          //     className="edit-button"
                          //     onClick={onCancel}
                          //   >
                          //     Cancel
                          //   </Button>
                          // ) : null}
                          <Button
                            data-testid="submit-button"
                            type="primary"
                            onClick={
                              /*isEditRating ? onEditDetails :*/ onSubmit
                            }
                            disabled={!isValid}
                            className={
                              isEditRating ? "edit-button" : "submit-button"
                            }
                            loading={submitIsLoading}
                          >
                            {isEditRating ? "Edit Review" : "Submit"}
                          </Button>
                        }
                      </div>
                    ) : null}
                  </Form>
                )}
              </Formik>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default UserFeedbackCard;
