import "./CandidateTable.scss";
import CandidateListItem from "./CandidateTableListItem";
function CandidateList({ data, unEnrollClickhandler }) {
  if (data?.length === 0) {
    return (
      <div className="userList-container-empty">
        <div>No records to show!</div>
      </div>
    );
  }

  return data?.map((user) => {
    return (
      <CandidateListItem
        data={user}
        key={user.user_id}
        unEnrollClickhandler={unEnrollClickhandler}
      />
    );
  });
}
export default CandidateList;
