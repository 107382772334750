import {
  CLEAR_FILTER_VALUES,
  REMOVE_ISARCHIVE_VALUES,
  REMOVE_ROLE_VALUES,
  REMOVE_SEARCH_VALUES,
  SET_PAGINATION_LIMIT,
  SET_PAGINATION_OFFSET,
  SET_SEARCH_VALUES,
} from "../actiontypes";

export const updateLimit = (props) => ({
  type: SET_PAGINATION_LIMIT,
  payload: { ...props },
});

export const updateOffset = (props) => ({
  type: SET_PAGINATION_OFFSET,
  payload: { ...props },
});

export const updateSearchValues = (props) => ({
  type: SET_SEARCH_VALUES,
  payload: { ...props },
});

export const removeSearchValue = (props) => ({
  type: REMOVE_SEARCH_VALUES,
  payload: { ...props },
});
export const removeRoleValue = (props) => ({
  type: REMOVE_ROLE_VALUES,
  payload: { ...props },
});
export const removeisArchiveValue = (props) => ({
  type: REMOVE_ISARCHIVE_VALUES,
  payload: { ...props },
});

export const clearFilterValues = (props) => ({
  type: CLEAR_FILTER_VALUES,
  payload: { ...props },
});
