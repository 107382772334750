import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import CourseDetailsPage from "./CourseUnenrolledPage/CourseUnenrolledPage";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../store/selectors/authSelectors";
import { loadEnrolledCourses } from "../../store/actions/courseAction";
import { enrolledCourse } from "../../store/selectors/coursesSelectors";
import CourseEnrolledPage from "./CourseEnrolledPage/CourseEnrolledPage";
import Spinner from "../../components/Spinner/Spinner";
import "./CoursePage.scss";
import { isPreviewSelector } from "../../store/selectors/wizardSelectors";
import { isPreviewConstant } from "../../store/actions/wizardActions";
import { CLEAR_ENROLLED_COURSE } from "../../store/actiontypes";

function CoursePage() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isPreview = useSelector(isPreviewSelector);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const enrolledCourses = useSelector(enrolledCourse);
  let course_id = "";
  if (enrolledCourses) {
    course_id = enrolledCourses[1]?.enroll_details[0]?.course?.id;
  }

  useEffect(() => {
    if (location.pathname.includes("/preview")) {
      dispatch(isPreviewConstant(true));
    }
  }, [location.pathname]);
  const loadEnrolledCourse = () => {
    if (enrolledCourses === null) dispatch(loadEnrolledCourses(user.id));
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    loadEnrolledCourse();
    return () => {
      dispatch({ type: CLEAR_ENROLLED_COURSE });
    };
  }, []);
  const checkIfEnrolled = () => {
    let enrolls = [];
    if (enrolledCourses.results) {
      enrolls = enrolledCourses.results;
    } else {
      enrolls = enrolledCourses;
    }
    enrolls.every((element) => {
      if (
        element.enroll_details[0].course.id === id &&
        element.enroll_details[0].user.id === user.id
      ) {
        setIsEnrolled(true);
        return false;
      } else {
        setIsEnrolled(false);
      }
      return true;
    });
    setLoading(false);
  };
  useEffect(() => {
    if (enrolledCourses !== null) checkIfEnrolled();
  });
  useEffect(() => {
    if (location.pathname.includes("/preview")) {
      dispatch(isPreviewConstant(true));
    }
  }, [location.pathname]);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {isPreview ? (
            <CourseEnrolledPage isPreview={isPreview} course_id={course_id} />
          ) : (
            <div
              data-testid="coursePage-container"
              className="coursePage-container"
            >
              {isEnrolled ? (
                <CourseEnrolledPage
                  isEnrolled={isEnrolled}
                  course_id={course_id}
                />
              ) : (
                <CourseDetailsPage
                  isEnrolled={isEnrolled}
                  course_id={course_id}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CoursePage;
