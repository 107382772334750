import { Checkbox, Radio } from "antd";
import "./McqOption.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const OptionMcq = ({
  userSelectedAnswer = [],
  option,
  question_type,
  disabled,
  handleChange,
}) => {
  const [isQuestionManager, setIsQuestionManager] = useState(false);
  const location = useLocation();
  const [isCheckedBox, setIscheckedBox] = useState(false);

  useEffect(() => {
    setIscheckedBox(userSelectedAnswer.includes(option.id));
  }, [userSelectedAnswer]);
  useEffect(() => {
    if (location.pathname.includes("manager")) {
      setIsQuestionManager(true);
    }
  }, [location.pathname]);
  const handleCheckBox = (e) => {
    if (e.target.checked) {
      userSelectedAnswer.push(option.id);
    }
    if (!e.target.checked) {
      const index = userSelectedAnswer.indexOf(option.id);
      if (index > -1) {
        userSelectedAnswer.splice(index, 1);
      }
    }
    setIscheckedBox(e.target.checked);
  };

  return (
    <div className={`${option.is_answer ? "optionMcq_correct" : "optionMcq"}`}>
      {question_type === "Multiple" ? (
        <Checkbox
          disabled={disabled}
          checked={isCheckedBox}
          onChange={handleCheckBox}
        />
      ) : question_type === "Single" ? (
        <Radio
          checked={
            !isQuestionManager ? userSelectedAnswer[0] === option.id : null
          }
          onChange={handleChange}
          value={option.id}
          disabled={disabled}
        />
      ) : null}
      {question_type === "Subjective" ? (
        <span className="subjective_option_text">{option.option_text}</span>
      ) : (
        <span className="option_text">{option.option_text}</span>
      )}
    </div>
  );
};
export default OptionMcq;
