import StyledModal from "../StyledModal/StyledModal";
import "./CandidateTestContainer.scss";

function StartConfirmTest({ open, handleClose }) {
  const handleOk = () => {
    handleClose();
  };

  return (
    <StyledModal
      className={"start-test-modal"}
      centred
      title={"Test Instructions"}
      visible={open}
      onCancel={handleClose}
      onOk={handleOk}
      closable={false}
      maskClosable={false}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <div className="start-confirm-test-container">
        <ol>
          <li>
            Question Type:
            <ul>
              <li>
                Multiple-Choice Questions: Choose the correct answer(s) from the
                provided options. Some questions may have more than one correct
                answer.
              </li>
              <li>
                Subjective Questions: Answer descriptively. Be clear, concise,
                and thorough.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </StyledModal>
  );
}

export default StartConfirmTest;
