/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import {
  createRepoLoaderSelector,
  isCheckedSelector,
  wizardCurrentStepSelector,
  wizardStepsSelector,
} from "../../../store/selectors/wizardSelectors";
import GlobalWizard from "../../../components/GlobalWizard/GlobalWizard";

import { Button } from "antd";
import GeneralInfoStep from "./GeneralInfoStep/GeneralInfoStep";
import McqStep from "./McqStep/McqStep";
import ModuleCreationStep from "./ModuleCreationStep/ModuleCreationStep";
import RepositoriesStep from "./RepositoriesStep/RepositoriesStep";
import SummaryStep from "./SummaryStep/SummaryStep";
import {
  generalInfoStepAction,
  isPreviewConstant,
  modulesCreationStepAction,
  wizardCancel,
  wizardCreateCourse,
  wizardLoading,
  wizardNext,
  wizardPrev,
} from "../../../store/actions/wizardActions";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

function CreateCourseWizard({ open, handleWizardClose }) {
  //   const courseFormStepOneRef = useCallback((ref) => {
  //     setTimeout(() => {
  //       if (ref) {
  //         setIsStepOneFormValid(!ref.isValid);
  //       }
  //     }, 0);
  //   });

  const steps = useSelector(wizardStepsSelector);
  const dispatchs = useDispatch();
  const isChecked = useSelector(isCheckedSelector);
  const [isstepOneButtonValid, setIfStepOneButtonValid] = useState(false);
  const [isStepTwoButtonValid, setIfStepTwoButtonValid] = useState(false);
  const [isStepThreeButtonValid, setIfStepThreeButtonValid] = useState(false);
  const [isStepFourButtonValid, setIfStepFourButtonValid] = useState(false);
  const [isStepFiveButtonValid, setIfStepFiveButtonValid] = useState(false);
  const currentStep = useSelector(wizardCurrentStepSelector);
  const onCreateHandler = (dispatch, wizardSteps) => {
    // console.log("Creating Course with Steps:", wizardSteps);
    dispatch(wizardLoading(true));
    dispatch(wizardCreateCourse(wizardSteps));
    // dispatch(loadDraftAndOwnedCoursesList(dispatch));
  };
  const isCourseCreateLoading = useSelector(createRepoLoaderSelector);
  const previewHandler = (dispatch, wizardSteps) => {
    dispatchs(isPreviewConstant(true));
    window.open(`#/courses/${wizardSteps.stepOne.id}/preview`, "_blank");
  };
  const checkIfStepOneButtonValid = (stepOne) => {
    if (stepOne === undefined) {
      return false;
    } else {
      const requiredKeys = [
        "course_name",
        "course_description",
        "category",
        "lab_required",
      ];
      const allKeysExist = requiredKeys.every((key) =>
        // eslint-disable-next-line no-prototype-builtins
        stepOne.hasOwnProperty(key)
      );
      const allValuesNotEmpty = requiredKeys.every((key) => {
        const value = stepOne[key];
        return typeof value === "string" && value.trim() !== "";
      });

      return allKeysExist && allValuesNotEmpty;
    }
  };

  const checkIfStepTwoButtonValid = (stepTwo) => {
    return stepTwo && stepTwo.length > 0;
  };
  const checkIfStepFourButtonValid = (stepFour) => {
    if (!isEmpty(stepFour)) {
      const testStatus = stepFour?.every((element) => {
        return element.test_status !== "IN_COMPLETE";
      });
      return stepFour && [stepFour].length > 0 && testStatus;
    }
    return false;
  };
  const checkIfStepFiveButtonValid = (stepFour) => {
    return stepFour && [stepFour].length > 0;
  };
  useEffect(() => {
    setIfStepOneButtonValid(checkIfStepOneButtonValid(steps?.stepOne));
    setIfStepTwoButtonValid(checkIfStepTwoButtonValid(steps?.stepTwo));
    setIfStepThreeButtonValid(checkIfStepFiveButtonValid(steps?.stepThree));
    setIfStepFourButtonValid(checkIfStepFourButtonValid(steps?.stepFour));
    setIfStepFiveButtonValid(
      checkIfStepFiveButtonValid(
        steps?.stepOne?.lab_required ? steps?.stepFour : steps?.stepThree
      )
    );
  }, [steps]);
  const COURSE_ACTION_STEPS = [
    {
      key: 0,
      title: "General Info",
      isBackButtonRequired: false,
      nextButtonToolbar: [
        <Button key="help" className="WizardFooter-button">
          Help
        </Button>,
        <Button
          key="cancel"
          className="WizardFooter-button"
          onClick={wizardCancel}
        >
          Cancel
        </Button>,
        <Button
          key="next"
          className={`WizardFooter-button ${
            !isstepOneButtonValid ? "disabled-footer-button" : "dark"
          }`}
          type="primary"
          onClick={generalInfoStepAction}
          disabled={!isstepOneButtonValid}
        >
          Save & Next
        </Button>,
      ],
      container: <GeneralInfoStep />,
    },
    {
      key: 1,
      title: "Module Creation",
      isBackButtonRequired: true,
      onBack: wizardPrev,
      nextButtonToolbar: [
        <Button key="help" className="WizardFooter-button">
          Help
        </Button>,
        <Button
          key="cancel"
          className="WizardFooter-button"
          onClick={wizardCancel}
        >
          Cancel
        </Button>,
        <Button
          key="next"
          className={`WizardFooter-button ${
            !isStepTwoButtonValid ? "disabled-footer-button" : "dark"
          }`}
          type="primary"
          onClick={modulesCreationStepAction}
          disabled={!isStepTwoButtonValid}
        >
          Save & Next
        </Button>,
      ],
      container: <ModuleCreationStep />,
    },
    (currentStep === 0 && steps?.stepOne?.lab_required === undefined) ||
    steps?.stepOne?.lab_required === "YES"
      ? {
          key: 2,
          title: "Repositories",
          isBackButtonRequired: true,
          onBack: wizardPrev,
          nextButtonToolbar: [
            <Button key="help" className="WizardFooter-button">
              Help
            </Button>,
            <Button
              key="cancel"
              className={`WizardFooter-button ${
                isCourseCreateLoading ? "disabled-footer-cancel-button" : ""
              }`}
              onClick={wizardCancel}
              disabled={isCourseCreateLoading}
            >
              Cancel
            </Button>,
            <Button
              key="next"
              className={`WizardFooter-button ${
                !isStepThreeButtonValid ? "disabled-footer-button" : "dark"
              }`}
              type="primary"
              onClick={wizardNext}
              disabled={!isStepThreeButtonValid}
            >
              Save & Next
            </Button>,
          ],
          container: <RepositoriesStep />,
        }
      : undefined,
    {
      key: steps?.stepOne?.lab_required === "NO" ? 2 : 3,
      title: "MCQ Test",
      isBackButtonRequired: true,
      onBack: wizardPrev,
      nextButtonToolbar: [
        <Button key="help" className="WizardFooter-button">
          Help
        </Button>,
        <Button
          key="cancel"
          className={`WizardFooter-button ${
            isCourseCreateLoading ? "disabled-footer-cancel-button" : ""
          }`}
          onClick={wizardCancel}
        >
          Cancel
        </Button>,
        <Button
          key="next"
          className={`WizardFooter-button ${
            steps?.stepOne?.lab_required === "YES" && isEmpty(steps?.stepFour)
              ? "dark"
              : !isStepFourButtonValid
              ? "disabled-footer-button"
              : "dark"
          }`}
          type="primary"
          disabled={
            steps?.stepOne?.lab_required === "YES" && isEmpty(steps?.stepFour)
              ? false
              : !isStepFourButtonValid
          }
          onClick={wizardNext}
        >
          Save & Next
        </Button>,
      ],
      container: <McqStep />,
    },
    {
      key: steps?.stepOne?.lab_required === "NO" ? 3 : 4,
      title: "Summary",
      isBackButtonRequired: true,
      onBack: wizardPrev,
      nextButtonToolbar: [
        <Button
          key="preview"
          className="WizardFooter-button"
          onClick={previewHandler}
          disabled={!isStepFiveButtonValid}
        >
          Preview
        </Button>,
        <Button
          key="cancel"
          className="WizardFooter-button"
          onClick={wizardCancel}
        >
          Cancel
        </Button>,
        <Button
          key="next"
          className={`WizardFooter-button ${
            !isChecked && isStepFiveButtonValid
              ? "disabled-footer-button"
              : "dark"
          }`}
          type="primary"
          onClick={onCreateHandler}
          disabled={!isChecked && isStepFiveButtonValid}
        >
          Create
        </Button>,
      ],
      container: <SummaryStep />,
    },
  ].filter(Boolean);
  return (
    <GlobalWizard
      isWizardOpen={open}
      handleWizardClose={handleWizardClose}
      actionSteps={COURSE_ACTION_STEPS}
      currentStep={currentStep}
    />
  );
}

export default CreateCourseWizard;
