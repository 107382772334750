/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import "./ProgressBar.scss";
const ProgressBar = (props) => {
  const { per, expected } = props;
  return (
    <div className="progress-bar-container" data-testid="progress-bar">
      <div className="subtitle">
        <div>Your progress</div>
        <div>{per} %</div>
      </div>
      <div className="progressBar">
        <div className="progressBarValue" style={{ maxWidth: `${per}%` }}></div>
        {/* <div
          className="progressBarExpected"
          style={{ left: `${expected}%` }}
        ></div> */}
      </div>
    </div>
  );
};
ProgressBar.propTypes = {
  per: PropTypes.any.isRequired,
  // expected: PropTypes.any.isRequired,
};

export default ProgressBar;
