import * as Yup from "yup";
import { CURRENT_EDITING_TEST_DETAILS } from "../../../../store/actiontypes";

const keys = [
  "test_name",
  "test_description",
  "allow_question_back_n_forth",
  "question_order",
  "passing_score",
  "max_number_of_attempts",
];

export const checkIfStepSaveButtonValid = (
  editingtestDetails,
  dataForReset
) => {
  if (!editingtestDetails) {
    return false;
  } else {
    if (dataForReset) {
      return keys.some((key) => {
        const detailValue = editingtestDetails[key];
        const resetValue = dataForReset[key];

        if (
          key === "total_time_limit_of_test" ||
          key === "time_limit_for_each_question"
        ) {
          return detailValue !== resetValue && detailValue !== "00:00:00";
        }

        return detailValue !== resetValue;
      });
    } else {
      return keys.every((key) => {
        if (key === "max_number_of_attempts") {
          return true;
        }
        return (
          key in editingtestDetails &&
          editingtestDetails[key] !== null &&
          editingtestDetails[key] !== undefined &&
          editingtestDetails[key] !== "" &&
          ((editingtestDetails["total_time_limit_of_test"] !== null &&
            editingtestDetails["total_time_limit_of_test"] !== "00:00:00") ||
            (editingtestDetails["time_limit_for_each_question"] !== null &&
              editingtestDetails["time_limit_for_each_question"] !==
                "00:00:00"))
        );
      });
    }
  }
};

export function handleChange(value, setFieldValue, type, ref, dispatch) {
  switch (type) {
    case "total_time_limit_of_test":
      setFieldValue("total_time_limit_of_test", value);
      setFieldValue("time_limit_for_each_question", null);
      break;
    case "time_limit_for_each_question":
      setFieldValue("time_limit_for_each_question", value);
      setFieldValue("total_time_limit_of_test", null);
      break;
    case "passing_score":
      setFieldValue(type, value);
      break;
    case "max_number_of_attempts":
      setFieldValue(type, value);
      // setFieldValue("attempt_limit", false);
      break;
    case "attempt_limit":
      if (value === true) {
        setFieldValue(type, value);
        setFieldValue("max_number_of_attempts", null);
      } else {
        setFieldValue(type, value);
        setFieldValue("max_number_of_attempts", "");
      }
      break;
    case "allow_question_back_n_forth":
      setFieldValue(type, value);
      setFieldValue("time_limit_for_each_question", null);
      break;
    default:
      setFieldValue(type, value.target.value);
      break;
  }
  setTimeout(() => {
    // if (ref?.current.isValid)
    dispatch({
      type: CURRENT_EDITING_TEST_DETAILS,
      payload: ref?.current.values,
    });
  }, 0);
}
export const stepFourValidateForm = Yup.object({
  test_name: Yup.string().required("This Field is Required"),
  test_description: Yup.string().required("This Field is Required"),
});
