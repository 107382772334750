/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyledModal from "../../components/StyledModal/StyledModal";
import { updateUser } from "../../store/actions/adminActions";
import Spinner from "../../components/Spinner/Spinner";
import { userSelector } from "../../store/selectors/authSelectors";
import { isCancelled, logoutUser } from "../../store/actions/authActions";
import { otherUsers, selfUser } from "../../constants";
import { ISCANCELLED } from "../../store/actiontypes";

function UserRoleConfirmModal({
  open,
  setOpen,
  role,
  name,
  callback,
  id,
  groups,
  prevGrp,
}) {
  const selectedGroups = [];
  for (const x in groups) {
    selectedGroups.push(groups[x].value);
  }
  const previousGrps = [];
  for (const x in prevGrp) {
    previousGrps.push(prevGrp[x].value);
  }
  const dispatch = useDispatch();
  const [loading, setLoading] = useState({ isLoading: false, message: "" });
  const user = useSelector(userSelector);
  const callbackSuccessUpdate = () => {
    setOpen(false);
    setLoading({ isLoading: false, message: "" });
    if (user.id === id) {
      dispatch(logoutUser);
    }
  };
  const handleOk = () => {
    setLoading({ isLoading: true, message: `Updating details of ${name}` });
    const user = { user_name: name, groups: selectedGroups };
    dispatch(updateUser(id, user, callbackSuccessUpdate));
  };
  const onCancel = () => {
    dispatch(isCancelled(true));
  };
  // Extract the "label" values from the groups array and join them into a string
  const groupsString = groups?.map((group) => group.label).join(",");
  return (
    <StyledModal
      title="Confirm Groups"
      centered
      open={open}
      onOk={handleOk}
      onCancel={() => {
        setLoading({ isLoading: false, message: "" });
        setOpen(false);
        onCancel();
      }}
      width="650px"
      footer={loading.isLoading ? null : undefined}
      closable={false}
    >
      <div
        className="modal-container"
        style={{ width: "fit-content", height: "fit-content" }}
      >
        {loading.isLoading && (
          <div
            style={{
              width: "600px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              color: "rgb(0, 110, 255)",
            }}
          >
            <Spinner small={true} />
            {loading.message && <p>{loading.message}</p>}
          </div>
        )}
        {!loading.isLoading &&
          groupsString !== undefined &&
          (user?.id === id
            ? selfUser.replace("{groups}", groupsString)
            : otherUsers
                .replace("{groups}", groupsString)
                .replace("{name}", name))}
      </div>
    </StyledModal>
  );
}

export default UserRoleConfirmModal;
