import { useDispatch } from "react-redux";
import { deleteQuestions } from "../../../../store/actions/wizardActions";
import StyledModal from "../../../../components/StyledModal/StyledModal";
import Spinner from "../../../../components/Spinner/Spinner";
import "./QuestionDeleteConfirmModel.scss";
function QuestionDeleteConfirmModal({
  open,
  onCancel,
  data,
  onsuccesscallback,
  loading,
  setLoading,
}) {
  const dispatch = useDispatch();
  const handleOk = () => {
    setLoading(true);
    dispatch(deleteQuestions(data, onsuccesscallback));
  };
  return (
    <StyledModal
      className={"draft-delete-modal"}
      centred
      title={"Delete Question"}
      visible={open}
      onCancel={() => {
        setLoading(false);
        onCancel();
      }}
      onOk={handleOk}
      closable={true}
    >
      <div className="question-delete-modal">
        {loading ? (
          <Spinner text={"Please wait.. deletion in progress..."} />
        ) : (
          "Are you sure you want to delete?"
        )}
      </div>
    </StyledModal>
  );
}

export default QuestionDeleteConfirmModal;
