/* eslint-disable no-unused-vars */
import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./QuestionReorder.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { currentEditingQuestions } from "../../../../store/selectors/wizardSelectors";
import { mcqReorder } from "../../../../store/actions/wizardActions";
import { useLocation } from "react-router-dom";

const QuestionReorder = ({ reorderSectionStateChange }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const questionData = useSelector(currentEditingQuestions)?.results;
  const questionCount = useSelector(currentEditingQuestions)?.results.length;
  const [draggedItems, setDraggedItems] = useState(questionData);

  const containerRef = useRef(null);

  const testId = location.pathname.split("/")[4];

  const adjustHeight = () => {
    const containerHeight = containerRef?.current?.clientHeight;
    containerRef.current.style.minHeight = `${containerHeight + 10}px`;
  };

  useEffect(() => {
    adjustHeight();
  }, [questionCount, containerRef.current]);

  const questionIds = draggedItems.map((items) => items.question_id);

  const handleReorderSave = () => {
    dispatch(mcqReorder(testId, { test: testId, questions: questionIds }));
    reorderSectionStateChange(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    // Reorder the items based on the drag-and-drop result
    const items = Array.from(draggedItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDraggedItems(items);
  };

  const handleCancelButton = () => {
    reorderSectionStateChange(false);
  };

  return (
    <div className="questionReorder-container">
      <div className="questionReorder-header">
        <div>Question Manager</div>
        <div>
          <Button
            onClick={handleCancelButton}
            className="questionReorder-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={handleReorderSave}
            type="primary"
            htmlType="submit"
            className="questionReorder-submit-btn"
          >
            Save
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="draggedItems" direction="vertical">
          {(provided) => (
            <div
              className="questionReorder-questionList-container"
              {...provided.droppableProps}
              ref={(el) => {
                containerRef.current = el;
                provided.innerRef(el);
              }}
            >
              {draggedItems.map((question, index) => (
                <Draggable
                  key={question.question_id}
                  draggableId={question.question_id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      key={index}
                      ref={provided.innerRef}
                      className="questionReorder-questionList-items"
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div>
                        <div className="questionNumber">
                          {"Q " + question.order_no}
                        </div>
                        <div>{parse(String(question?.question_text))}</div>
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faGripLines} color="#CDCDCD" />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default QuestionReorder;
