import { Navigate, Route } from "react-router-dom";
// import CourseDetailsPage from "../pages/CourseDetailsPage/CourseDetailsPage";
import { group } from "../constants/groups";
import {
  commonRoute,
  creatorRoute,
  hRRoute,
  portalAdminRoute,
} from "../constants/routes";
const GROUP_ROUTE = {
  CANDIDATE: commonRoute,
  PORTAL_ADMIN: portalAdminRoute,
  CREATOR: creatorRoute,
  HR: hRRoute,
};
export const getUserRoutes = (groups) => {
  const dynamicRoutes = [];
  const groupArr = [];
  groups?.forEach((item) => {
    dynamicRoutes.push(...GROUP_ROUTE[group[item.id]]);
    groupArr.push(item.id);
  });
  const uniquePathsMaps = new Map();

  const allRoutes = [...commonRoute, ...dynamicRoutes];
  // Filter out duplicates based on the path property
  const filteredArrays = allRoutes
    .map((obj) => {
      if (!uniquePathsMaps.has(obj.path)) {
        uniquePathsMaps.set(obj.path, true);
        if (
          (groupArr.includes(1) || groupArr.includes(4)) &&
          obj.path === "/"
        ) {
          return { ...obj, element: <Navigate to="/courses" /> };
        }
        return obj;
      }
      return null;
    })
    .filter((obj) => obj !== null);
  return (
    <>
      {filteredArrays.map((item) => (
        <Route exact path={item.path} element={item.element} key={item.path} />
      ))}
    </>
  );
};
