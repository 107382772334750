/* eslint-disable no-unused-vars */
import {
  faCircleInfo,
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {
  HIDE_BANNER,
  SHOW_BANNER_ERROR,
  SHOW_BANNER_INFO,
  SHOW_BANNER_WARNING,
} from "../actiontypes/index";

const initialState = [];
const findRemainingBanners = (state, id) => {
  return state.filter((elem) => id !== elem.id);
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  let remainingBanners = null;
  let data = null;
  switch (type) {
    case SHOW_BANNER_ERROR:
      remainingBanners = findRemainingBanners(state, payload.id);
      data = {
        color: "#C64141",
        icon: faExclamationTriangle,
        ...payload,
      };
      return [...remainingBanners, data];
    case SHOW_BANNER_WARNING:
      remainingBanners = findRemainingBanners(state, payload.id);
      data = {
        color: "#FFB84D",
        icon: faExclamationCircle,
        ...payload,
      };
      return [...remainingBanners, data];
    case SHOW_BANNER_INFO:
      remainingBanners = findRemainingBanners(state, payload.id);
      data = {
        color: "#0E89E2",
        icon: faCircleInfo,
        ...payload,
      };
      return [...remainingBanners, data];
    case HIDE_BANNER:
      remainingBanners = findRemainingBanners(state, payload.id);
      return [...remainingBanners];
    default:
      return state;
  }
}
