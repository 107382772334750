import TestEnrolledPage from "./TestEnrolledPage";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import "./PublicCourseDetailPage.scss";
import { CLEAR_SELECTED_COURSES } from "../../store/actiontypes";
import { selectedCourseSelector } from "../../store/selectors/coursesSelectors";
import { loadSelectedCourse } from "../../store/actions/courseAction";
import { isPreviewConstant } from "../../store/actions/wizardActions";
import Spinner from "../../components/Spinner/Spinner";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function PublicCourseDetailPage() {
  const dispatch = useDispatch();
  const course = useSelector(selectedCourseSelector);
  const navigate = useNavigate();

  const link = window.location.href;
  const segments = link.split("/");
  const course_id = segments[segments.indexOf("courses") + 1];

  const loadSelectedCourseDetails = (course_id) => {
    dispatch(loadSelectedCourse(course_id));
  };
  useEffect(() => {
    loadSelectedCourseDetails(course_id);
    return () => {
      dispatch(isPreviewConstant(false));
      dispatch({ type: CLEAR_SELECTED_COURSES });
    };
  }, []);
  if (course?.[0]?.course?.type === "PRIVATE") {
    navigate(-1);
    return null;
  }
  return (
    <>
      <NavBar />
      <div className="public-course-container">
        {course ? <TestEnrolledPage course={course} /> : <Spinner />}
      </div>
      <Footer />
    </>
  );
}

export default PublicCourseDetailPage;
