import AboutCourse from "../../features/CourseDetailsFeature/CourseEnrolled/TabScreen.js/AboutCourse";
import ContentPage from "./ContentPage";

const TestTabScreen = ({ activeTab, course }) => {
  const renderTab = () => {
    switch (activeTab) {
      case "content":
        return <ContentPage course={course} />;
      case "course":
        return <AboutCourse />;
      default:
        return null;
    }
  };

  return renderTab();
};

export default TestTabScreen;
