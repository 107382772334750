const coursesSelector = (state) => {
  return state.courses;
};

export const myCoursesSelector = (state) => {
  return coursesSelector(state).myCourses;
};

export const coursesListSelector = (state) => {
  return coursesSelector(state).courses;
};

export const selectedCourseSelector = (state) => {
  return coursesSelector(state).selectedCourse;
};

export const selectSelectedPiplineStatusPercentage = (state) => {
  return coursesSelector(state).selectedCourseStatusPercentage;
};
export const currentModule = (state) => {
  return coursesSelector(state).currentModule;
};
export const enrolledCourse = (state) => {
  return coursesSelector(state).enrolledCourse;
};
export const inReviewCoursesSelector = (state) => {
  return coursesSelector(state).inReviewCoursess;
};
export const draftCoursesSelector = (state) => {
  return coursesSelector(state).draftCourses;
};

export const ownedCoursesSelector = (state) => {
  return coursesSelector(state).ownedCourses;
};

export const authoredCoursesSelector = (state) => {
  return coursesSelector(state).authoredCourses;
};

export const candidatesEnrolledSelector = (state) => {
  return coursesSelector(state).candidatesEnrolled;
};

export const avgCompletionTimeSelector = (state) => {
  return coursesSelector(state).avgCompletionTime;
};

export const getTestQuestions = (state) => {
  return coursesSelector(state).giving_test?.details;
};

export const testDetails = (state) => {
  return coursesSelector(state).giving_test?.mcq;
};

export const getCurrentMcqIndex = (state) => {
  return coursesSelector(state).current_mcq_index;
};

export const publicModuleIndexSelector = (state) => {
  return coursesSelector(state).publicModuleIndex;
};

export const selectedQuizIndexSelector = (state) => {
  return coursesSelector(state).selectedQuizIndex;
};
