// import React from "react";
import "./ResultsContainer.scss";
import { ReactComponent as ResultPassed } from "../../../src/assets/check-circle.svg";
import { ReactComponent as ResultFailed } from "../../../src/assets/times-circle.svg";
import { ReactComponent as ClockSVG } from "../../../src/assets/clock.svg";
import Spinner from "../Spinner/Spinner";
import ProgressCircle from "./ProgressCircle";
function ResultsContainer({ testResult, isResultLoading }) {
  const formattedDate = testResult?.submitted_date?.slice(0, 10);
  const percentage = testResult?.latest_score;
  const circleColor =
    testResult?.test_result === "Passed" ? "#4BC949" : "#FF5959";
  return isResultLoading ? (
    <div data-testid="result-loader" className="result-loader-div">
      <Spinner />
    </div>
  ) : (
    <div className="Result-Container">
      <h3 className="Result-heading">Results</h3>
      {testResult ? (
        <div className="result-content">
          <div className="Result-content-left">
            <h6
              className="test-pass-fail-heading"
              style={{ color: circleColor }}
            >
              {testResult.test_result === "Passed" ? (
                <>
                  <ResultPassed className="result-icon-passed" /> Test Passed
                </>
              ) : (
                <>
                  <ResultFailed className="result-icon-failed" /> Test Failed
                </>
              )}
            </h6>
            <span className="result-sections">Attempts</span>
            <span className="result-sections-detail">
              <span className="no-attempt-attempted">
                {testResult?.user_total_attempts}
              </span>
              {testResult.max_attempts ? "/" + testResult.max_attempts : null}
            </span>
            <span className="result-sections">Best Score</span>
            <span className="result-sections-detail">
              {testResult?.best_score}%
            </span>
          </div>
          <div className="Result-content-center">
            <ProgressCircle percentage={percentage} circleColor={circleColor} />
          </div>
          <div className="Result-content-right">
            <h6 className="total-test-time-heading">
              <ClockSVG className="test-time-icon" /> Total time
            </h6>
            <span className="result-sections-time">
              {testResult?.attempt_duration}
              {testResult?.total_time_limit_of_test && (
                <span className="result-sections-totaltime">
                  {"       "} / {"       "}
                  {testResult?.total_time_limit_of_test}
                </span>
              )}
            </span>
            <span className="result-sections">Date</span>
            <span className="result-section-date">{formattedDate}</span>
          </div>
        </div>
      ) : (
        <div className="Result--empty-Container">----</div>
      )}
    </div>
  );
}

export default ResultsContainer;
