import { useEffect, useState } from "react";
import Pagination from "../../../../../components/Pagination/Pagination";
import SearchBar from "../../../../../components/SearchBar/SearchBar";
import { searchPropsByForAuthoredList } from "../../../../../constants/SearchBarProps";
import "./AuthoredTabScreen.scss";
import AuthoredTable from "./AuthoredTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { authoredCoursesSelector } from "../../../../../store/selectors/coursesSelectors";
import {
  clearAuthoredCourseList,
  loadAuthoredCoursesList,
} from "../../../../../store/actions/courseAction";
import Spinner from "../../../../../components/Spinner/Spinner";
import { useSearchParams } from "react-router-dom";
import {
  clearFilterValues,
  updateLimit,
  updateOffset,
} from "../../../../../store/actions/listParamsAction";
import { AUTHORED_LIST_ID } from "../../../../../constants";
import {
  getListLimit,
  getListOffset,
} from "../../../../../store/selectors/listParamsSelectors";

const AuthoredTabScreen = () => {
  // Extracting the 'id' from the URL
  const id = location.hash.split("/")[2];

  // State for loading indicator
  const [isLoading, setIsLoading] = useState(true);

  // Redux dispatch function
  const dispatch = useDispatch();

  // Getting query parameters from the URL
  const [searchParams] = useSearchParams();

  // Redux selectors
  const authoredCoursesList = useSelector(authoredCoursesSelector);
  const searchListParams = useSelector(
    (state) => state.listParams[AUTHORED_LIST_ID]
  );
  const defaultValue = useSelector((state) =>
    getListLimit(state, AUTHORED_LIST_ID)
  );
  const offSetValue = useSelector((state) =>
    getListOffset(state, AUTHORED_LIST_ID)
  );
  const totalCount = authoredCoursesList?.count;

  // Function to update the 'defaultValue' when limit is changed
  const changeDefaultValue = (value) => {
    dispatch(
      updateLimit({
        id: AUTHORED_LIST_ID,
        limit: value[0].label,
        offset: 0,
      })
    );
  };

  // Function to update the 'offSetValue' when offset is changed
  const changeOffsetValue = (value) => {
    dispatch(
      updateOffset({
        id: AUTHORED_LIST_ID,
        offset: value,
      })
    );
    dispatch(
      loadAuthoredCoursesList(id, {
        ...searchListParams,
        offset: value,
      })
    );
  };

  // Function to handle search
  const handleSearch = (params) => {
    if (defaultValue) {
      dispatch(
        updateOffset({
          id: AUTHORED_LIST_ID,
          offset: 0,
        })
      );
      searchPropsByForAuthoredList.onSearch(
        {
          ...params,
          limit: defaultValue,
        },
        dispatch,
        id
      );
    }
  };

  // Function to load courses based on search parameters
  const loadCourses = () => {
    const filters = {};

    for (const [key, value] of searchParams.entries()) {
      filters[key] = value;
    }

    if (Object.keys(filters).length === 0) {
      dispatch(
        loadAuthoredCoursesList(id, {
          ...searchListParams,
          limit: defaultValue,
          offset: offSetValue,
        })
      );
    } else {
      handleSearch(filters);
    }
  };

  // Initial setup when the component mounts
  useEffect(() => {
    dispatch(
      updateLimit({
        id: AUTHORED_LIST_ID,
        limit: 5,
        offset: 0,
      })
    );

    // Cleanup function when the component unmounts
    //cleaning the redux of search values in search bar
    return () => {
      dispatch(clearAuthoredCourseList());
      dispatch(
        clearFilterValues({
          id: AUTHORED_LIST_ID,
        })
      );
    };
  }, []);

  // Effect to load courses when 'defaultValue' changes
  useEffect(() => {
    if (defaultValue !== undefined && offSetValue !== undefined) {
      loadCourses();
    }
  }, [defaultValue]);

  // Effect to handle loading indicator
  useEffect(() => {
    if (authoredCoursesList !== null) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [authoredCoursesList]);

  return (
    <>
      <div className="authoredHeaderContainer">
        {/* SearchBar component */}
        <SearchBar
          filterValues={searchPropsByForAuthoredList.filterValues}
          onSearch={handleSearch}
        />
      </div>
      {isLoading ? (
        // Display loading spinner when isLoading is true
        <div className="authored-loading-container">
          <Spinner />
        </div>
      ) : (
        <div className="authoredTabScreenContent-container">
          {/* AuthoredTable component */}
          <AuthoredTable authoredCoursesList={authoredCoursesList?.results} />
        </div>
      )}
      <div className="authoredTabScreen-footer">
        {/* Pagination component */}
        <Pagination
          defaultValue={defaultValue}
          totalCount={totalCount}
          onClickHandlerDefaultValue={changeDefaultValue}
          offSetValue={offSetValue}
          onClickHandlerOffSetValue={changeOffsetValue}
          userList={authoredCoursesList}
        />
      </div>
    </>
  );
};

export default AuthoredTabScreen;
