/* eslint-disable prettier/prettier */
import StyledModal from "../../../components/StyledModal/StyledModal";

function CourseCreateLimitModal({ open, handleClose }) {
  return (
    <StyledModal
      className={"course-limit-modal"}
      centred
      title={"Draft Limit"}
      visible={open}
      onCancel={handleClose}
      closable={true}
      footer={null}
    >
      <div
        style={{
          marginTop: "30px",
          marginBottom: "20px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        Maximum draft limit of three has been reached. You cannot create more
        drafts.
      </div>
    </StyledModal>
  );
}

export default CourseCreateLimitModal;
