import "./WizardContent.scss";
import GlobalStepper from "../GlobalStepper/GlobalStepper";
import WizardFooter from "./WizardFooter";
import PropTypes from "prop-types";

const WizardContent = ({ currentStep, actionSteps, handleWizardClose }) => {
  const currentActionStep = actionSteps.find(
    (step) => step.key === currentStep
  );
  const itemLength = actionSteps.length;

  return (
    <div className="WizardContent-container">
      {/* Render the GlobalStepper component */}
      <GlobalStepper
        current={currentStep}
        items={actionSteps.map(({ key, title }) => ({ key, title }))}
        size="small"
        labelPlacement="vertical"
      />

      <div className="WizardBody-container">
        {/* Render the container of the current action step */}
        {currentActionStep && currentActionStep.container}
      </div>

      {/* Render the WizardFooter component */}
      <WizardFooter
        currentStep={currentStep}
        limit={itemLength - 1}
        isBackRequired={currentActionStep?.isBackButtonRequired}
        onBack={currentActionStep?.onBack}
        nextButtonToolbar={currentActionStep?.nextButtonToolbar}
        handleWizardClose={handleWizardClose}
      />
    </div>
  );
};

// PropTypes
WizardContent.propTypes = {
  currentStep: PropTypes.number.isRequired,
  actionSteps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      container: PropTypes.node.isRequired,
      isBackButtonRequired: PropTypes.bool.isRequired,
      onBack: PropTypes.func,
      nextButtonToolbar: PropTypes.arrayOf(PropTypes.element),
    })
  ).isRequired,
};

export default WizardContent;
