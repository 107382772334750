/* eslint-disable quotes */
/* eslint-disable no-unused-vars*/
import { actionFactory } from "../../libs/actionFactory";
import { CANDIDATE_LIST } from "../actiontypes";

export const getAllCandidateList = (id, params = {}, callback) => {
  return actionFactory({
    api: `get/api/courses/${id}/enroll/candidates/`,
    actionBody: {
      params: { ...params },
    },
    actionBase: CANDIDATE_LIST,
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise.then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          dispatch({
            type: CANDIDATE_LIST,
            payload: resp["data"].data,
          });
        }
        if (callback) {
          callback();
        }
      });
    },
  });
};
