/* eslint-disable no-unused-vars */
import { Button } from "antd";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import "./WizardFooter.scss";
import React from "react";
import { useSelector } from "react-redux";
import { wizardStepsSelector } from "../../store/selectors/wizardSelectors";
const WizardFooter = ({
  isBackRequired,
  nextButtonToolbar,
  onBack,
  limit,
  currentStep,
  handleWizardClose,
}) => {
  const dispatch = useDispatch();
  const wizardSteps = useSelector(wizardStepsSelector);
  // Handle the back button click
  const handleBack = (onClickCallback) => {
    onClickCallback(dispatch, {
      limit: limit,
      currentStep: currentStep,
    });
  };

  // Handle the button click
  const handleClick = (onClickCallback) => {
    onClickCallback(dispatch, {
      limit,
      currentStep,
      handleWizardClose: handleWizardClose,
      ...wizardSteps,
    });
  };

  return (
    <div className="WizardFooter">
      <div className="WizardFooter-buttons">
        {/* Render the Back button if isBackRequired is true */}
        {isBackRequired && (
          <Button
            className="WizardFooter-button left"
            onClick={() => handleBack(onBack)} // Callback function from props passed as params
          >
            Back
          </Button>
        )}
        {/* Render the nextButtonToolbar */}
        {nextButtonToolbar &&
          nextButtonToolbar.map((button) => {
            const onClick = button.props.onClick || null;
            return React.cloneElement(button, {
              onClick: onClick ? () => handleClick(onClick) : null,
            });
          })}
      </div>
    </div>
  );
};

// PropTypes
WizardFooter.propTypes = {
  isBackRequired: PropTypes.bool.isRequired,
  nextButtonToolbar: PropTypes.arrayOf(PropTypes.element).isRequired,
  onBack: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  setIsWizardOpen: PropTypes.func.isRequired,
};

export default WizardFooter;
