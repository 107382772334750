import "../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionListItem.scss";
// import OptionMcq from "./McqOptions";
import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { SUBMIT_ANSWER } from "../../store/actiontypes";
import OptionMcq from "../../features/CourseListFeature/WizardCourseSteps/McqStep/McqOptions";
import parse from "html-react-parser";
export const QuestionListItemPublic = ({
  index,
  question,
  options,
  userSelectedAnswer,
  disabled,
  givingTest,
}) => {
  const dispatch = useDispatch();

  // Function to dispatch the submit answer action
  const submitAnswer = (questionIndex, userAnswer) => {
    dispatch({
      type: SUBMIT_ANSWER,
      payload: {
        questionIndex,
        userAnswer,
      },
    });
  };

  const checkQuestionImgTag = (text) => {
    if (!givingTest) {
      let imageTagStr = text.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/i);
      if (imageTagStr) {
        text = text.replaceAll(/<img[^>]*>/g, "< Question Image >&nbsp;");
      }
    }
    return text;
  };

  const questiontext = parse(
    `<div classname=${!givingTest ? "para-graph" : ""}>${checkQuestionImgTag(
      question?.question_text
    )}</div>`
  );

  const handleAnswerChange = (answer) => {
    // Use a more flexible handler to account for event objects and direct values
    let userAnswer;
    if (answer.target) {
      userAnswer = answer.target.value; // For events like radio/checkbox
    } else {
      userAnswer = answer; // Direct value, e.g., for subjective answers
    }
    submitAnswer(index, userAnswer); // Dispatch the answer
  };

  return (
    <div className="questionListitem">
      <div className="questionListitem-body">{questiontext}</div>
      {options?.map((option, index) => (
        <div key={index}>
          {question?.question_type !== "Subjective" || disabled ? (
            <>
              <OptionMcq
                userSelectedAnswer={userSelectedAnswer}
                option={option}
                question_type={question.question_type}
                disabled={false}
                handleChange={(e) => handleAnswerChange(e)} // Ensure event is passed correctly
              />
            </>
          ) : (
            <Formik initialValues={{ subjectiveAnswer: userSelectedAnswer }}>
              {({ setFieldValue }) => (
                <Form className="form-subjective" data-testid="form-div">
                  <Field
                    as="textarea"
                    type="textarea"
                    name={"subjectiveAnswer"}
                    className="subjective-answer-text-area"
                    showCount={true}
                    maxLength={5000}
                    onChange={(event) => {
                      setFieldValue("subjectiveAnswer", event.target.value);
                      handleAnswerChange(event.target.value); // Pass direct value for subjective answers
                    }}
                    rows={4}
                    autoSize={{
                      minRows: 5,
                      maxRows: 5,
                    }}
                    placeholder="Enter your answer here."
                  />
                </Form>
              )}
            </Formik>
          )}
        </div>
      ))}
    </div>
  );
};
