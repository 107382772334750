import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Button } from "antd";

// import { ReactComponent as GroupSvg } from "../../../src/assets/Group684.svg";
// import { Button } from "antd";
import "../../components/TestContent/CandidateTestContainerPublic.scss";

// import { QuestionListItem } from "../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionListItem";
import { QuestionListItemPublic } from "./QuestionListItemPublic";
import { CURRENT_MCQ_INDEX } from "../../store/actiontypes";
// import { usePageVisibility } from "../../hooks/usePageVisibility";
// import MenuDropDown from "../../components/MenuDropDown/MenuDropDown";
import { ARE_YOU_SURE, AUTO_SUBMIT, TIMES_UP } from "../../constants/McqTest";
import {
  startCandidateTestPublic,
  submitCandidateTestPublic,
} from "../../store/actions/courseAction";
import {
  getCurrentMcqIndex,
  getTestQuestions,
  testDetails,
} from "../../store/selectors/coursesSelectors";
// import TestTimer from "../../components/TestContent/TestTimer";
// import StartConfirmTest from "../../components/TestContent/StartConfirmTest";
// import BrowserChangeModal from "../../components/TestContent/BrowserChangeModal";
import SubmitTestModalPublic from "./SubmitTestModalPublic";
// import TestTimerPublic from "./TestTimerPublic";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import { Modal as MyModal } from "antd";
// StyledModal

const CandidateTestContainerPublic = () => {
  const dispatch = useDispatch();
  const [testStartTime] = useState(null);

  // const [isStartModalOpen, setIsStartModalOpen] = useState(true);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState({
    autoSubmit: false,
    confirmation: false,
  });

  // const [stickyIndex, setStickyIndex] = useState(null);
  const [Modal, SetModal] = useState(false);

  if (!sessionStorage.getItem("isCookies")) {
    sessionStorage.setItem("isCookies", true);
  }
  const [BannerHide, SetBannerHide] = useState(
    sessionStorage.getItem("isCookies") === "false" ? false : true
  );
  const [submitLoader, setsubmitLoader] = useState(false);
  const hash = window.location.hash;

  // Extract the test ID using a regular expression
  const match = hash.match(/tests\/([a-f0-9-]+)\/start\/public/);
  const testId = match ? match[1] : null;

  // const [countTabChange, setIsCountTabChange] = useState(0);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [makeItRed, setMakeItRed] = useState(false);
  const [isAllAtteptsModalOpen, setIsAllAtteptsModalOpen] = useState(false);
  const questionRefs = useRef([]);
  const currentMcqIndex = useSelector(getCurrentMcqIndex);

  // const isVisible = usePageVisibility();
  // const raiseWarning = () => {
  //   setMakeItRed(true);
  // };
  // useEffect(() => {
  //   if (!isVisible) {
  //     setIsCountTabChange(countTabChange + 1);
  //   }
  //   if (countTabChange < 3 && isVisible && countTabChange > 0) {
  //     setIsModalOpen(true);
  //   }
  //   if (isVisible && countTabChange === 3) {
  //     setIsModalOpen(false);
  //     SetModal(true);
  //     setIsAllAtteptsModalOpen(true);
  //   }
  // }, [isVisible]);
  useEffect(() => {
    dispatch(startCandidateTestPublic(testId));
  }, []);

  // functions to handle pop screen
  // const closeStartConfirmModal = () => {
  //   const now = new Date();
  //   setTestStartTime(now);
  //   setIsStartModalOpen(false);
  // };
  const questions = useSelector(getTestQuestions);
  const testDetail = useSelector(testDetails);
  const [timerKey, setTimerKey] = useState(0);
  useEffect(() => {
    if (!testDetail?.total_time_limit_of_test)
      setTimerKey((prevKey) => prevKey + 1);
  }, [currentMcqIndex]);
  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val)) // Adding leading zeros
      .join(":");
  };

  const onTimeComplete = (email = "", name, setisColor) => {
    if (
      testDetail?.time_limit_for_each_question &&
      currentMcqIndex < questions.length - 1 &&
      !isAllAtteptsModalOpen
    ) {
      dispatch({ type: CURRENT_MCQ_INDEX, payload: currentMcqIndex + 1 });
    } else {
      if (
        !isSubmitModalOpen.autoSubmit &&
        !isSubmitModalOpen.confirmation &&
        !isAllAtteptsModalOpen
      ) {
        // here we are opening the submit modal to show time is up message and test will auto submit in 5 seconds
        setIsSubmitModalOpen({ autoSubmit: true, confirmation: false });
        SetModal(true);
      } else if (
        !isSubmitModalOpen.autoSubmit ||
        isAllAtteptsModalOpen ||
        !isSubmitModalOpen.confirmation
      ) {
        // Here we ca submitting test and then the window will close
        const now = new Date();
        let attemptDurationSeconds = Math.floor((now - testStartTime) / 1000);
        if (
          attemptDurationSeconds > testDetail?.total_time_limit_of_test &&
          testDetail?.total_time_limit_of_test
        ) {
          attemptDurationSeconds = testDetail?.total_time_limit_of_test;
        } else if (
          testDetail?.time_limit_for_each_question &&
          isSubmitModalOpen.autoSubmit
        ) {
          attemptDurationSeconds = attemptDurationSeconds - 5;
        }
        const formattedAttemptDuration = formatDuration(attemptDurationSeconds);
        dispatch(
          submitCandidateTestPublic(
            testId,
            questions,
            formattedAttemptDuration,
            callbackSubmit,
            email,
            name,
            setsubmitLoader,
            setisColor
          )
        );
        // setsubmitLoader(true);
        SetModal(true);
        setIsAllAtteptsModalOpen(false);
      }
    }
  };
  const callbackSubmit = () => {
    setsubmitLoader(false);
  };
  const onSubmit = () => {
    setIsSubmitModalOpen({ autoSubmit: false, confirmation: true });
    SetModal(true);
    setsubmitLoader(true);
  };
  const isLastIndex = questions?.length - 1;
  // useEffect(() => {
  //   if (isStartModalOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  // }, [isStartModalOpen]);
  const isHideBanner = () => {
    SetBannerHide((BannerHide) => !BannerHide);
    sessionStorage.setItem("isCookies", false);
  };

  return (
    questions &&
    testDetail && (
      <div className="testPage-ContainerPublic">
        <div className="navbarstart">
          <NavBar />

          {/* <StartConfirmTest
            open={isStartModalOpen}
            handleClose={closeStartConfirmModal}
            data={testDetail}
          /> */}
          <div className="testPage-Header-contentPublic">
            <div className="testPage-Header-leftPublic">
              {/* <GroupSvg /> */}
              <span className="testPage-testNamePublic">
                {testDetail?.test_name}
              </span>
            </div>
            {/* <div className="testPage-Header-rightPublic"> */}
            {/* <span className="testPage-testTimePublic"> */}
            {/* {testDetail?.time_limit_for_each_question
                  ? "Time to answer:"
                  : "Time left:"} */}
            {/* </span> */}
            {/* <span */}
            {/* className={`testPage-testTimer ${ */}
            {/* makeItRed ? "testTimer-red" : "testTimer-black" */}
            {/* style={{ */}
            {/* color: makeItRed ? "#C64141" : "#1A2B3C", */}
            {/* <TestTimerPublic
                  key={timerKey}
                  autoStart={isStartModalOpen}
                  testTime={
                    testDetail?.total_time_limit_of_test ||
                    testDetail?.time_limit_for_each_question
                  }
                  onTimeComplete={onTimeComplete}
                  raiseWarning={raiseWarning}
                /> */}
            {/* </span> */}
            {/* </div> */}
          </div>
          <div className="color-div"></div>
        </div>
        <SubmitTestModalPublic
          open={Modal}
          setOpen={SetModal}
          // countTabChange={countTabChange}
          onTimeComplete={onTimeComplete}
          onSubmit={onSubmit}
          key={timerKey}
          message={
            isAllAtteptsModalOpen
              ? AUTO_SUBMIT
              : isSubmitModalOpen.confirmation
              ? ARE_YOU_SURE
              : TIMES_UP
          }
          submitLoader={submitLoader}
        />
        {/* <BrowserChangeModal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          countTabChange={countTabChange}
        /> */}

        <div className="test-giving-sectionPublic">
          <div className="question-button-containerPublic">
            {questions.map((question, index) => (
              <div
                key={index}
                ref={(el) => (questionRefs.current[index] = el)}
                className={`testPage-question_containerPublic ${
                  index === isLastIndex ? "last-question" : ""
                }`}
              >
                <div className="testPage-question-numberPublic">
                  {index + 1}.
                </div>
                <QuestionListItemPublic
                  index={index}
                  question={question?.question}
                  options={question?.options}
                  userSelectedAnswer={question?.user_answer}
                  disabled={false}
                  givingTest={true}
                />
              </div>
            ))}
            <div className="testPage-buttons-containerPublic"></div>
          </div>
          <Button
            className="testPage-test-submitButtonPublic"
            onClick={onSubmit}
            loading={false}
          >
            Submit
          </Button>

          <MyModal
            className="modal-cookies"
            open={BannerHide}
            closable={false}
            footer={null}
            style={{ top: "75%" }}
          >
            <div
              className="children-wrapper"
              data-testid="styled-modal-wrapper"
            >
              <div className="public-testpage-banner">
                <div className="left-box">
                  <span className="banner-text">
                    We use third-party cookies to personalize content, ads and
                    analyze site traffic.
                  </span>
                </div>
                <div className="right-box">
                  <Button className="test-banner-button" onClick={isHideBanner}>
                    Okay
                  </Button>
                </div>
              </div>
            </div>
          </MyModal>
        </div>

        <Footer />
      </div>
    )
  );
};

export default CandidateTestContainerPublic;
