const ratingSelector = (state) => {
  return state.rating;
};

export const ratingsSelector = (state) => {
  return ratingSelector(state).ratings;
};

export const myRatingsSelector = (state) => {
  return ratingSelector(state).myRating;
};
