// import React from "react";

const ProgressCircle = ({ percentage, circleColor }) => {
  const radius = 82;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference * (1 - percentage / 100) + 2.8;
  return (
    <svg width="187" height="187">
      <circle
        cx="100"
        cy="100"
        r={radius}
        fill="none"
        stroke="#ccc"
        strokeWidth="8"
      />
      <circle
        cx="100"
        cy="100"
        r={radius}
        fill="none"
        stroke={circleColor}
        strokeWidth="8"
        strokeDasharray={circumference}
        strokeDashoffset={progress}
        strokeLinecap="round"
        transform={"rotate(-90, 100, 100)"}
      />
      <text
        className="result-circle-inner-text"
        x="105"
        y="110"
        textAnchor="middle"
        fontSize="20"
        fill={circleColor}
      >
        {percentage}%
      </text>
    </svg>
  );
};

export default ProgressCircle;
