import { useEffect, useState } from "react";
import UserList from "./UserList";
import UserRoleConfirmModal from "./UserRoleConfirmModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { updateSearchValues } from "../../store/actions/listParamsAction";
import { USER_LIST_ID } from "../../constants";
import Spinner from "../../components/Spinner/Spinner";

/* eslint-disable react/prop-types */
const UserTable = ({
  data,
  editClickHandler,
  archiveClickHandler,
  enrollClickHandler,
}) => {
  const [clickedIcon, setClickedIcon] = useState("");
  const [arrowIcons, setArrowIcons] = useState({
    user_name: true,
    emp_id: true,
  });
  const dispatch = useDispatch();
  const [userListLoading, setUserListLoading] = useState(false);
  useEffect(() => {
    setUserListLoading(false);
  }, [data]);
  const orderTheList = (orderingField, isAscending) => {
    dispatch(
      updateSearchValues({
        id: USER_LIST_ID,
        ordering: isAscending ? orderingField : `-${orderingField}`,
      })
    );
    setUserListLoading(true);
    setArrowIcons({
      user_name: orderingField === "user_name" ? isAscending : true,
      emp_id: orderingField === "emp_id" ? isAscending : true,
    });
  };

  const renderArrowIcon = (field) => {
    return (
      <FontAwesomeIcon
        icon={arrowIcons[field] ? faArrowUp : faArrowDown}
        onClick={() => {
          setClickedIcon(field);
          orderTheList(field, !arrowIcons[field]);
        }}
        className={clickedIcon === field ? "arrowIcon" : "nonColoredIcon"}
      />
    );
  };
  const [openUserRoleConfirmationModal, setOpenUserRoleConfirmationModal] =
    useState(false);
  const [user, setUser] = useState({ name: "", role: "", id: "" });
  // const [roleCallback, setRoleCallback] = useState(() => {});
  // const selectedRoleCallback = (setSelectedRole) => {
  //   setRoleCallback(() => setSelectedRole);
  // };
  const roleSelectHandle = (role, username, id, group, menu_group) => {
    setUser((prev) => {
      return {
        ...prev,
        name: username,
        role: role[0],
        id: id,
        groups: group,
        prevGrp: menu_group,
      };
    });
    setOpenUserRoleConfirmationModal(true);
  };
  return (
    <>
      <UserRoleConfirmModal
        open={openUserRoleConfirmationModal}
        setOpen={setOpenUserRoleConfirmationModal}
        name={user.name}
        role={user.role}
        id={user.id}
        groups={user.groups}
        prevGrp={user.prevGrp}
      />
      <div data-testid="table-container" className="table-container">
        <div className="table-title-container">
          <div className="table-title">
            Username{""} {renderArrowIcon("user_name")}
          </div>
          <div className="table-title">
            ID {""}
            {renderArrowIcon("emp_id")}
          </div>
          <div className="table-title">User Roles</div>
          <div className="table-title">Status</div>
          <div className="table-option">{/*Menu*/}</div>
        </div>
        {userListLoading ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          <UserList
            data={data?.results}
            editClickHandler={editClickHandler}
            archiveClickHandler={archiveClickHandler}
            enrollClickHandler={enrollClickHandler}
            roleSelectHandle={roleSelectHandle}
          />
        )}
      </div>
    </>
  );
};

export default UserTable;
