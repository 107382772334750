import StyledModal from "../../../components/StyledModal/StyledModal";

/* eslint-disable quotes */
function ContactAdminModal({ open, setOpen }) {
  return (
    <StyledModal
      closable={true}
      title={`Contact Admin`}
      centered
      open={open}
      width={867}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{
        style: {
          width: "106px",
          height: "41px",
        },
      }}
    >
      <div
        style={{
          marginTop: "20px",
          marginBottom: "61px",
          color: "#696969",
          fontSize: "15px",
          paddingLeft: "24px",
        }}
      >
        {`Please contact ${process.env.REACT_APP_PORTAL_ADMIN_EMAIL_ID} to enroll you into this course.`}
      </div>
    </StyledModal>
  );
}

export default ContactAdminModal;
