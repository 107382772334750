import "../PublicResultDisplay.scss";

const ResultCard = ({
  index,
  question,
  score,
  user_answer,
  rating,
  justifications,
}) => {
  const formatUserAnswer = (userAnswer) => {
    if (userAnswer.length === 0) return "";
    const formattedAnswer = userAnswer.join(", ") + ".";
    return `Your answer: ${formattedAnswer}`;
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-heading-question">
            {index + 1}.&nbsp;{question}
          </div>
          <div className="card-heading-answer">
            {user_answer && user_answer.length > 0 ? (
              <>
                <span className="card-answer">
                  {formatUserAnswer(user_answer)}
                </span>
              </>
            ) : (
              <p className="card-answer">Your answer: N/A</p>
            )}
          </div>
          <span className="card-heading-score">Score: {score}</span>
          {rating && (
            <span className="card-heading-rating">Rating: {rating}</span>
          )}
          {justifications && (
            <span className="card-heading-score">
              Justification: {justifications}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default ResultCard;
