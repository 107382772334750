import { useEffect, useState } from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rate } from "antd";
import moment from "moment";
import NoReviewImg from "../../assets/pana.svg";
import "./ReviewsCommentList.scss";

const ReviewsCommentList = ({ ratings, parentRef }) => {
  const [reviewListMaxCount, setReviewListMaxCount] = useState(100);
  const [loaded, setLoaded] = useState(false);
  const loadMore = () => {
    if (loaded) {
      let div = document.getElementById("map-container-2");
      if (div) {
        div.scrollTop = 0;
      }
    }
    setLoaded(!loaded);
  };

  useEffect(() => {
    if (parentRef?.current?.children[1]?.children[0]?.children) {
      const children = Array.from(
        parentRef.current.children[1].children[0].children
      );
      let childHeightSum = 0;
      let count = 0;
      const parentHeight = parentRef.current.offsetHeight - 85;
      children.forEach((child) => {
        if (
          parentHeight > childHeightSum &&
          Math.abs(parentHeight - childHeightSum) > 150
        ) {
          childHeightSum += child.offsetHeight;
          count++;
        } else {
          return;
        }
      });
      setReviewListMaxCount(count);
    }
  }, []);

  return (
    <div className="review-list">
      {ratings?.results.length > 0 ? (
        <>
          <div
            className={`mapping-section ${loaded ? "show-more" : "show-less"}`}
            id="map-container"
          >
            {ratings?.results?.slice(0, reviewListMaxCount).map((value) => {
              return (
                <div key={value.id} className="individual-div">
                  <div className="top">
                    <div className="name-icon" data-testid="review-username">
                      <div className="icon-review">
                        <FontAwesomeIcon
                          size="sm"
                          icon={faUser}
                          color="#121212"
                        />
                      </div>
                      {value.user.user_name}
                    </div>
                    <Rate
                      value={value.rating}
                      disabled
                      className="rating-stars-review"
                    />
                  </div>
                  <div className="middle" data-testid="comment">
                    {value.comment}
                  </div>
                  <div className="bottom" data-testid="updated_at">
                    {moment(value.updated_at).format("MMMM DD, YYYY")}
                    <div className="line"></div>
                  </div>
                </div>
              );
            })}
          </div>
          {loaded ? (
            <div
              className={`mapping-section ${
                loaded ? "show-more" : "show-less"
              }`}
              id="map-container-2"
            >
              {ratings?.results?.slice(reviewListMaxCount).map((value) => {
                return (
                  <div key={value.id} className="individual-div">
                    <div className="top">
                      <div className="name-icon" data-testid="review-username">
                        <div className="icon-review">
                          <FontAwesomeIcon
                            size="sm"
                            icon={faUser}
                            color="#121212"
                          />
                        </div>
                        {value.user.user_name}
                      </div>
                      <Rate
                        value={value.rating}
                        disabled
                        className="rating-stars-review"
                      />
                    </div>
                    <div className="middle" data-testid="comment">
                      {value.comment}
                    </div>
                    <div className="bottom" data-testid="updated_at">
                      {moment(value.updated_at).format("MMMM DD, YYYY")}
                      <div className="line"></div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
          {ratings?.results.length > reviewListMaxCount ? (
            <div className="load-more">
              <span
                onClick={loadMore}
                className="text"
                data-testid="loadmorebutton"
              >
                {loaded ? "Load Less" : "Load More"}
              </span>
            </div>
          ) : null}
        </>
      ) : (
        <div className="no-info">
          <img alt="no-review-svg" src={NoReviewImg} />
          <label data-testid="no-reviews">No Reviews</label>
        </div>
      )}
    </div>
  );
};
export default ReviewsCommentList;
