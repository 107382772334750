/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import "./AdminUserPage.scss";
import { getAllUserDetails } from "../../store/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { AllUserSelector } from "../../store/selectors/adminSelectors";
import UserEditModal from "../../features/AdminUserList/UserEditModal";
import UserArchiveModal from "../../features/AdminUserList/UserArchiveModal";
import UserTable from "../../features/AdminUserList/UserTable";
import { coursesListSelector } from "../../store/selectors/coursesSelectors";
import { loadCourses } from "../../store/actions/courseAction";
import UserEnrollmentModal from "../../features/AdminUserList/UserEnrollmentModal";
import Pagination from "../../components/Pagination/Pagination";

import {
  clearFilterValues,
  updateLimit,
  updateOffset,
} from "../../store/actions/listParamsAction";
import { USER_LIST_ID } from "../../constants";

import SearchBar from "../../components/SearchBar/SearchBar";
import { searchPropsForUserList } from "../../constants/SearchBarProps";
import {
  getListLimit,
  getListOffset,
} from "../../store/selectors/listParamsSelectors";
function AdminUserPage() {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState({});
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState("");
  const searchListParams = useSelector(
    (state) => state.listParams[USER_LIST_ID]
  );
  const defaultValue = useSelector((state) =>
    getListLimit(state, USER_LIST_ID)
  );
  const offSetValue = useSelector((state) =>
    getListOffset(state, USER_LIST_ID)
  );
  const [userListLoading, setUserListLoading] = useState(true);
  const changeDefaultValue = (value) => {
    dispatch(
      updateLimit({
        id: USER_LIST_ID,
        limit: value[0].label,
        offset: 0,
      })
    );
  };
  const changeOffsetValue = (value) => {
    dispatch(
      updateOffset({
        id: USER_LIST_ID,
        offset: value,
      })
    );
  };

  const handleUserEditAction = (user, key) => {
    setSelectedUser(user);
    setKey(key);
    setOpen(true);
  };

  const handleUserArchiveAction = (user, key) => {
    setSelectedUser(user);
    setKey(key);
    setOpen(true);
  };

  const handleUserEnrollAction = (user, key) => {
    setSelectedUser(user);
    setKey(key);
    setOpen(true);
  };

  const loadCoursesList = () => {
    dispatch(loadCourses());
  };
  const loadAllUsers = () => {
    dispatch(
      getAllUserDetails(
        { ...searchListParams, limit: defaultValue, offset: offSetValue },
        () => setUserListLoading(false)
      )
    );
  };
  useEffect(() => {
    loadAllUsers();
  }, [defaultValue, offSetValue, searchListParams]);

  useEffect(() => {
    loadCoursesList();
    dispatch(
      updateLimit({
        id: USER_LIST_ID,
        limit: 10,
        offset: 0,
      })
    );
  }, []);

  const userList = useSelector((state) => AllUserSelector(state));
  const coursesList = useSelector((state) => coursesListSelector(state));
  const totalCount = userList?.count;
  // Search Function
  const [activeTab, setActiveTab] = useState("all");
  useEffect(() => {
    // Set the active tab based on the current URL
    const pathSegments = (location.pathname + location.hash).split("/");
    const tabSegmentIndex = pathSegments.findIndex(
      (segment) => segment.toLowerCase() === "all"
    );
    if (tabSegmentIndex !== -1) {
      const tabValue = pathSegments[tabSegmentIndex].toLowerCase();
      setActiveTab(tabValue);
    }
  }, []);
  const handleSearch = (params, _setLoadingStatus) => {
    // Perform search based on the active tab
    dispatch(
      updateOffset({
        id: USER_LIST_ID,
        offset: 0,
      })
    );
    searchPropsForUserList[activeTab].onSearch(params, dispatch);
  };
  const handleRemove = (params, _setLoadingStatus) => {
    // Perform search based on the active tab
    dispatch(
      updateOffset({
        id: USER_LIST_ID,
        offset: 0,
      })
    );
    searchPropsForUserList[activeTab].onClear(params, dispatch);
  };
  //clean up function to remove the search values present in search bar except limit and offset
  useEffect(() => {
    return () => {
      dispatch(
        clearFilterValues({
          id: USER_LIST_ID,
        })
      );
    };
  }, []);
  return (
    <div className="adminPage-main">
      <div
        data-testid="adminUserPage-container"
        className="adminUserPage-container"
      >
        {key === "Edit" ? (
          <UserEditModal open={open} setOpen={setOpen} user={selectedUser} />
        ) : key === "Enroll" ? (
          <UserEnrollmentModal
            open={open}
            setOpen={setOpen}
            user={selectedUser}
            courseList={coursesList}
          />
        ) : (
          <UserArchiveModal open={open} setOpen={setOpen} user={selectedUser} />
        )}
        <div data-testid="adminUserPage-title" className="adminUserPage-title">
          Users
        </div>
        <SearchBar
          filterValues={searchPropsForUserList[activeTab].filterValues}
          onSearch={(params, _) => {
            // Handle search event
            handleSearch(params);
          }}
          onClear={(params, _) => {
            handleRemove(params);
          }}
        />
        {!userListLoading ? (
          <UserTable
            data={userList}
            editClickHandler={handleUserEditAction}
            archiveClickHandler={handleUserArchiveAction}
            enrollClickHandler={handleUserEnrollAction}
          />
        ) : null}
      </div>
      <Pagination
        defaultValue={defaultValue}
        totalCount={totalCount}
        onClickHandlerDefaultValue={changeDefaultValue}
        offSetValue={offSetValue}
        onClickHandlerOffSetValue={changeOffsetValue}
        userList={userList}
      />
    </div>
  );
}

export default AdminUserPage;
