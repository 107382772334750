import CourseForm from "./CourseForm";
import "./GeneralInfoStep.scss";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  wizardLoaderSelector,
  wizardStepsSelector,
} from "../../../../store/selectors/wizardSelectors";
import Spinner from "../../../../components/Spinner/Spinner";
function GeneralInfoStep() {
  const wizardLoading = useSelector(wizardLoaderSelector);
  const wizardSteps = useSelector(wizardStepsSelector);
  return (
    <div className="create-course" data-testid="create-course-div">
      {wizardLoading ? (
        <div className="loader" data-testid="create-course-loader-div">
          <Spinner
            text={
              Object.keys(wizardSteps).length === 0
                ? "Please Wait..."
                : "Saving as Draft"
            }
            color={"white"}
          />
        </div>
      ) : null}
      <div className="top">
        <div className="header-div">
          <h3 className="header">General Information</h3>
          <FontAwesomeIcon
            icon={faQuestionCircle}
            style={{ color: "#1a2b3c", width: "15px", height: "15px" }}
          />
        </div>
        <label className="sub-header">
          Let's fill the basic information of the course.
        </label>
      </div>
      <CourseForm />
    </div>
  );
}

export default GeneralInfoStep;
