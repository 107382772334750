import * as Yup from "yup";
import { WIZRAD_STEP_ONE } from "../../../../store/actiontypes";

export function handleChange(value, setFieldValue, type, ref, dispatch) {
  switch (type) {
    case "category":
      setFieldValue(type, value);
      break;
    default:
      setFieldValue(type, value.target.value);
      break;
  }
  setTimeout(() => {
    // if (ref?.current.isValid)
    dispatch({ type: WIZRAD_STEP_ONE, payload: ref?.current.values });
  }, 0);
}
export const selectOptions = [
  {
    value: "e.g UI Developement",
    label: "e.g UI Developement",
    disabled: true,
  },
  { value: "Backend", label: "Backend" },
  { value: "Frontend", label: "Frontend" },
  { value: "Programming Language", label: "Programming Language" },
];
export const stepOneValidateForm = Yup.object({
  course_name: Yup.string().required("This Field is Required"),
  course_description: Yup.string().required("This Field is Required"),
  category: Yup.string().required("Select a value From Dropdown"),
});
export const reviewComment = Yup.object({
  rating: Yup.number().min(1).required("Rating is mandatory"),
  comment: Yup.string().required("Comment cannot be empty"),
});
