import ModuleContent from "./ModuleContent";
import AboutCourse from "./AboutCourse";
import Faq from "./Faq";

const TabScreen = ({ activeTab, lab_required }) => {
  const renderTab = () => {
    switch (activeTab) {
      case "content":
        return <ModuleContent lab_required={lab_required} />;
      case "course":
        return <AboutCourse />;
      case "faq":
        return <Faq />;
      default:
        return null;
    }
  };

  return renderTab();
};

export default TabScreen;
