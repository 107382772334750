/* eslint-disable no-unused-vars */
import "./StatisticsTabGraph.scss";
import Chart from "chart.js/auto";
import { useEffect, useRef } from "react";
import StatisticsCustomTooltip from "./StatisticsCustomTooltip";

const GraphLabel = ({ color, labelText }) => {
  return (
    <div className="label-container">
      <div className="label-color" style={{ backgroundColor: color }}></div>
      <div className="label-text">{labelText}</div>
    </div>
  );
};
const StatisticsTabGraph = ({
  candidatesEnrolledData,
  avgCompletionTimeData,
}) => {
  const chartRef = useRef(null);

  //Function to map the data of one graph with the other.
  function funcWithMappedValues(Array1, Array2) {
    const newArray = Array1.map((item) => ({ ...item }));

    // Create a map of labels in Array2 to their corresponding data values
    const labelsToData = {};
    Array2.forEach((item) => {
      labelsToData[item.label] = item.data;
    });

    // Iterate through newArray and update the data values
    newArray.forEach((item) => {
      if (item.label in labelsToData) {
        item.data = labelsToData[item.label];
      } else {
        item.data = 0;
      }
    });
    return newArray;
  }

  function extractLabel(data) {
    return data.map((item) => {
      return item.label;
    });
  }

  let targetLabelsOf;
  let candidatesEnrolledDataArray;
  let avgCompletionTimeDataArray;
  useEffect(() => {
    //Calculating which graph's label to display.
    if (candidatesEnrolledData.length > avgCompletionTimeData.length) {
      targetLabelsOf = candidatesEnrolledData;
    } else {
      targetLabelsOf = avgCompletionTimeData;
    }

    // Data for both the graphs.
    if (targetLabelsOf == candidatesEnrolledData) {
      candidatesEnrolledDataArray = candidatesEnrolledData.map(
        (item) => item.data
      );
      const resArray = funcWithMappedValues(
        candidatesEnrolledData,
        avgCompletionTimeData
      );
      avgCompletionTimeDataArray = resArray.map((item) => item.data);
    } else {
      avgCompletionTimeDataArray = avgCompletionTimeData.map(
        (item) => item.data
      );
      const resArray = funcWithMappedValues(
        avgCompletionTimeData,
        candidatesEnrolledData
      );
      candidatesEnrolledDataArray = resArray.map((item) => item.data);
    }
  }, [candidatesEnrolledData, avgCompletionTimeData]);

  //Function to find the maximum data value from both the arrays.
  function findMaxValueInArrays(array1, array2) {
    if (array1.length === 0 && array2.length === 0) return null;

    const max1 = Math.max(...array1);
    const max2 = Math.max(...array2);
    return Math.max(max1, max2);
  }

  //Function to calculate variable stepSize.
  function calculateStepSize(maxDataValue) {
    const calculatedStepSize = maxDataValue / 5; //Here, 5 because I want 5 grid lines for y-axis.
    return calculatedStepSize;
  }

  useEffect(() => {
    //Implementing graphs
    const canvas = chartRef.current;
    const ctx = canvas.getContext("2d");
    canvas.style.backgroundColor = "#FCFCFC";

    //To set "week 1" in next line when timeline is WEEKLY.
    const labels = extractLabel(targetLabelsOf);
    const transformedLabels = labels.map((label) => {
      const parts = label.split(" ");
      return [parts[0], parts.slice(1).join(" ")];
    });

    //Calling above functions to set variable stepSize.
    let maxDataValue = findMaxValueInArrays(
      candidatesEnrolledDataArray,
      avgCompletionTimeDataArray
    );
    let calculatedStepSize = calculateStepSize(maxDataValue);

    const data = {
      labels: transformedLabels,
      datasets: [
        {
          label: "Candidates Enrolled",
          data: candidatesEnrolledDataArray,
          pointStyle: true,
          borderCapStyle: "round",
          // fill: true,
          borderColor: "rgba(255, 185, 81, 1)",
          backgroundColor: "rgba(255, 245, 229, 0.7)",
          pointBackgroundColor: "rgba(255, 185, 81, 1)",
          pointRadius: 5,
        },
        {
          label: "wks Avg. Completion Time",
          data: avgCompletionTimeDataArray,
          pointStyle: true,
          borderCapStyle: "round",
          // fill: true,
          borderColor: "rgba(58, 167, 56, 1)",
          backgroundColor: "rgba(223, 239, 223, 0.7)",
          pointBackgroundColor: "rgba(58, 167, 56, 1)",
          pointRadius: 5,
        },
      ],
    };

    const option = {
      scales: {
        x: {
          position: "bottom",
          // offset: true,
          beginAtZero: true,
          offsetGridLines: true,
          border: {
            color: "rgba(184, 196, 206, 0.1)",
          },
          grid: {
            display: false,
            drawOnChartArea: true,
          },
          ticks: {
            color: "rgba(0, 0, 0, 1)",
            stepSize: 2,
            font: { size: 14, fontWeight: "bold" },
            backdropColor: "#FFF5E5",
            padding: 10,
          },
          tiitle: {
            padding: 20,
          },
        },
        y: {
          beginAtZero: true,
          border: {
            display: false,
            dash: [5, 5],
          },
          ticks: {
            display: false,
            stepSize: calculatedStepSize, //Setting variable stepSize.
            min: 0,
          },
          grid: {
            color: "#D4D4D4",
          },
        },
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, // Remove legends
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.raw + " " + context.dataset.label;
            },
          },
          enabled: false,
          external: StatisticsCustomTooltip,
          position: "nearest",
        },
      },
      layout: {
        padding: 0,
      },
      interaction: {
        mode: "index",
      },
    };

    const myChart = new Chart(ctx, {
      type: "line",
      data: data,
      options: option,
    });

    return () => {
      myChart.destroy(); // performing cleanup so that new graph can render when the data changes.
    };
  }, [candidatesEnrolledData, avgCompletionTimeData]);

  return (
    <div className="statisticsTab-graph-container">
      <div className="chart-container">
        <canvas ref={chartRef} height={370}></canvas>
      </div>
      <div className="labels-container">
        <GraphLabel
          color={"rgba(255, 185, 81, 1)"}
          labelText={"Candidates Enrolled"}
        />
        <GraphLabel
          color={"rgba(58, 167, 56, 1)"}
          labelText={"Avg. Completion Time"}
        />
      </div>
    </div>
  );
};

export default StatisticsTabGraph;
