import courseBg1 from "../../../../../assets/courseBg1.jpg";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faExclamationTriangle,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import "./DraftTile.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  isPreviewConstant,
  wizardLoading,
} from "../../../../../store/actions/wizardActions";
import { loadDraftCourseDetails } from "../../../../../store/actions/courseAction";
import { Dropdown } from "antd";
import { DeleteOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { userSelector } from "../../../../../store/selectors/authSelectors";
import DeleteConfirmModal from "./DeleteConfirmModal";

const DraftTile = ({ data, setIsWizardOpen, testId }) => {
  const uid = useSelector(userSelector)?.id;
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const disabledPreview =
    data.modules?.length == 0 ||
    data.repository === undefined ||
    data.repository === null
      ? true
      : false;
  const disbaledDelete = data.status === "IN_REVIEW";
  const items = [
    {
      label: (
        <span
          style={{
            color: disabledPreview ? "#A1A09E" : "",
            fontSize: "14px",
          }}
        >
          <EyeOutlined
            style={{
              color: disabledPreview ? "#A1A09E" : "",
              fontSize: "14px",
              marginRight: "13px",
              height: "39px",
            }}
          />
          Preview
        </span>
      ),
      key: "Preview",
      disabled: disabledPreview,
    },
    {
      label: (
        <span
          style={{ color: disbaledDelete ? "#A1A09E" : "", fontSize: "14px" }}
        >
          <DeleteOutlined
            style={{
              color: disbaledDelete ? "#A1A09E" : "",
              fontSize: "14px",
              marginRight: "13px",
              height: "39px",
            }}
          />
          Delete
        </span>
      ),
      key: "Delete",
      disabled: disbaledDelete,
    },
  ];
  const menuProps = {
    items,
    onClick: (e) => {
      if (e.key === "Delete") handleDraftDelete();
      if (e.key === "Preview") previewClickHandler();
    },
  };

  const handleDraftDelete = () => {
    openDeleteConfirmModal();
  };

  const previewClickHandler = () => {
    dispatch(isPreviewConstant(true));
    navigate(`/courses/${data.id}/preview`);
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const DraftTileHeader = () => {
    const handleDraftEdit = (e) => {
      e.stopPropagation();
      dispatch(wizardLoading(true));
      setIsWizardOpen(true);
      dispatch(loadDraftCourseDetails(uid, data.id));
    };

    return (
      <div className="draft-tile-header">
        <div className="draft-course-left" onClick={handleToggle}>
          <img
            className="draft-course-img"
            src={courseBg1}
            alt="Course Background"
          />
          <div className="course-title">{data?.course_name}</div>
        </div>
        <div className="draft-course-right">
          {data.status === "IN_REVIEW" && (
            <div className="draft-review-indicator">Marked for review</div>
          )}
          <button
            className="draft-button-group"
            data-testid={`edit-${testId}`}
            disabled={data.status === "IN_REVIEW" ? true : false}
            onClick={(e) => {
              e.stopPropagation();
              handleDraftEdit(e);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
            <div className="edit-text">Edit</div>
          </button>
          <div className="draft-menu" data-testid={`menu-${testId}`}>
            <Dropdown
              placement="bottomRight"
              menu={menuProps}
              trigger={["click"]}
              overlayStyle={{ width: 168 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <MoreOutlined
                style={{
                  fontSize: "16px",
                  color: "#000",
                }}
              />
            </Dropdown>{" "}
          </div>
        </div>
      </div>
    );
  };

  const DraftTileContent = () => {
    return (
      <div className="draft-toggle-section">
        <div className="draft-description">
          <span className="placeholder-text">{data.course_description}</span>
        </div>
        {(data.status === "REJECTED" || data.status === "IN_REVIEW") && (
          <div className="draft-status">
            {data.status === "REJECTED" && (
              <>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="warning-icon"
                />
                <span>
                  Rejected by Portal Admin. Please contact{" "}
                  <strong>admin@gk-platform.com</strong>.
                </span>
              </>
            )}
            {data.status === "IN_REVIEW" && (
              <>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className="warning-icon"
                  style={{ color: "#1a2b3c" }}
                />
                <span>Your course is sent for review.</span>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openDeleteConfirmModal = () => {
    setIsModalOpen(true);
  };

  const closeDeleteConfirmModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="draft-tile-container" data-testid={testId}>
      <DeleteConfirmModal
        open={isModalOpen}
        handleClose={closeDeleteConfirmModal}
        data={data}
      />
      {data.status === "REJECTED" && (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="draft-notification"
          color="red"
          size="lg"
        />
      )}
      <DraftTileHeader />
      {isExpanded && <DraftTileContent />}
    </div>
  );
};

export default DraftTile;
