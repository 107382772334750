/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEdit,
  faExternalLink,
  faFileAlt,
  faGripLines,
  faListAlt,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./ModuleTile.scss";
import ModuleForm from "./ModuleForm";
import { useDispatch } from "react-redux";
import {
  wizardCreateModule,
  wizardDeleteModule,
  wizardEditModule,
} from "../../../../store/actions/wizardActions";
import Spinner from "../../../../components/Spinner/Spinner";
import DisplayInfoMessage from "../../../../components/DisplayInfoMessage/DisplayInfoMessage";

const handleTimeoutLogic = (ref, setIsDisabled) => {
  setTimeout(() => {
    if (ref?.current.isValid) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, 0);
};

const ModuleEditComponent = ({ data, handleModeChange }) => {
  const [fieldValue, setFieldValue] = useState(data);
  const [disabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleModuleFieldChange = (type, value, ref) => {
    setFieldValue((prevValue) => {
      return { ...prevValue, [type]: value };
    });
    handleTimeoutLogic(ref, setIsDisabled);
  };

  const handleSave = () => {
    if (!disabled) {
      wizardEditModule(dispatch, fieldValue, handleModeChange);
    }
  };

  const handleCancel = () => {
    handleModeChange("closed");
  };

  return (
    <div className="moduleEdit-container">
      <ModuleForm
        handleModuleFieldChange={handleModuleFieldChange}
        initialValues={data}
      />
      <div className="moduleEdit-footer">
        <button
          className="cancel-button"
          data-testid="moduleTile-cancel"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button className="save-button" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

const ModuleCreateComponent = ({ closeModule }) => {
  const [fieldValue, setFieldValue] = useState({});
  const [disabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();

  const handleModuleFieldChange = (type, value, ref) => {
    setFieldValue((prevValue) => {
      return { ...prevValue, [type]: value };
    });
    handleTimeoutLogic(ref, setIsDisabled);
  };

  const handleSave = () => {
    if (!disabled) {
      wizardCreateModule(dispatch, fieldValue, closeModule);
    }
  };

  const handleCancel = () => {
    closeModule();
  };

  return (
    <div className="moduleEdit-container">
      <ModuleForm handleModuleFieldChange={handleModuleFieldChange} />
      <div className="moduleEdit-footer">
        <button
          className="cancel-button"
          data-testid="moduleTileCancel"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          data-testid="moduleTileSave"
          className="save-button"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

const ModuleHeader = ({
  handleModeChange,
  mode,
  data,
  showDelete,
  setShowDelete,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const handleEditClick = (event) => {
    event.stopPropagation();
    handleModeChange("edit");
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setShowDelete(true);
  };

  const handleNo = (event) => {
    event.stopPropagation();
    setShowDelete(false);
  };

  const handleYes = (event) => {
    event.stopPropagation();
    setisLoading(true);
    wizardDeleteModule(dispatch, data, setisLoading);
  };

  return (
    <div className="moduleTile-header">
      {mode === "edit" || mode === "create" ? (
        <div className="editTitle-container">
          <FontAwesomeIcon icon={faPencilAlt} color="#696969" />
          <div className="editModule-title">{`${
            mode === "edit" ? "Edit" : "New"
          } Module`}</div>
        </div>
      ) : (
        <div className="moduleTile-title">{data?.module_name}</div>
      )}
      {mode !== "create" ? (
        <div className="button-group">
          {mode !== "edit" && !showDelete ? (
            <button className="button-edit" onClick={handleEditClick}>
              <FontAwesomeIcon icon={faEdit} />
              <div>Edit</div>
            </button>
          ) : (
            <></>
          )}
          {showDelete ? (
            <div className="deleteModule-container">
              <DisplayInfoMessage
                showIcon
                message={
                  <div className="deleteModule-textGroup">
                    {isLoading ? "Deleting..." : "Delete this module"}{" "}
                    {isLoading ? (
                      <Spinner small={true} color={"rgba(0,0,0,0.7)"} />
                    ) : (
                      <div>
                        <button
                          data-testid="moduleTile-delete-yes"
                          className="moduleTile-deleteButton"
                          onClick={handleYes}
                        >
                          Yes
                        </button>{" "}
                        /{" "}
                        <button
                          data-testid="moduleTile-delete-no"
                          className="moduleTile-deleteButton"
                          onClick={handleNo}
                        >
                          No
                        </button>{" "}
                      </div>
                    )}
                  </div>
                }
                type={"error"}
              />
            </div>
          ) : (
            <button
              data-testid="moduleTile-delete"
              className="button-delete"
              onClick={handleDeleteClick}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
              <div>Delete</div>
            </button>
          )}
          <button className="button-icon">
            <FontAwesomeIcon icon={faGripLines} color="#CDCDCD" />
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const ModuleContent = ({ data }) => {
  const [linkOpened, setLinkOpened] = useState(false);

  const openLink = (event, link) => {
    event.stopPropagation();
    window.open(link, "_blank");
  };

  return (
    <div className="moduleTile-content visible">
      <div className="moduleTile-description">{data.module_description}</div>
      <div className="moduleTile-footer">
        <div className="footer-group">
          <FontAwesomeIcon icon={faClock} />
          <div className="subtitle">{`${data.estimated_time} Weeks`}</div>
        </div>
        <div className="footer-group">
          <FontAwesomeIcon icon={faListAlt} />
          <div className="subtitle">
            Module Content{" "}
            <div
              data-testid="moduleTile-content"
              onClick={(e) => openLink(e, data.module_content)}
            >
              <FontAwesomeIcon
                icon={faExternalLink}
                color="#108CFF"
                fontSize={"14px"}
              />
            </div>
          </div>
        </div>
        <div className="footer-group">
          <FontAwesomeIcon icon={faFileAlt} />
          <div className="subtitle">
            Module Assignment{" "}
            <div
              data-testid="moduleTile-assignment"
              onClick={(e) => openLink(e, data.module_assignment)}
            >
              <FontAwesomeIcon
                icon={faExternalLink}
                color="#108CFF"
                fontSize={"14px"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModuleTile = ({ mode, data, closeModule, tileRef, ...otherProps }) => {
  const [currentMode, setCurrentMode] = useState(mode);
  const [showDelete, setShowDelete] = useState(false);
  const handleModeChange = (mode) => {
    setCurrentMode(mode);
  };

  const handleClick = () => {
    if (currentMode === "open" || currentMode === "closed")
      setCurrentMode(currentMode === "open" ? "closed" : "open");
  };

  return (
    <div
      ref={tileRef}
      className={`moduleTile-container ${
        currentMode === "open"
          ? "moduleTile-container-open"
          : currentMode === "edit" || currentMode === "create"
          ? "moduleTile-container-edit"
          : showDelete
          ? "moduleTile-deletePadding"
          : ""
      }`}
      onClick={handleClick}
      {...otherProps}
    >
      <ModuleHeader
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        handleModeChange={handleModeChange}
        mode={currentMode}
        data={data}
      />
      {currentMode === "open" && <ModuleContent data={data} />}
      {currentMode === "edit" && (
        <ModuleEditComponent data={data} handleModeChange={handleModeChange} />
      )}
      {currentMode === "create" && (
        <ModuleCreateComponent data={data} closeModule={closeModule} />
      )}
    </div>
  );
};

ModuleTile.propTypes = {
  mode: PropTypes.oneOf(["open", "closed", "edit", "create"]),
};

ModuleTile.defaultProps = {
  mode: "closed",
};

export default ModuleTile;
