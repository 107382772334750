import { Modal } from "antd";
import PropTypes from "prop-types";

import "./StyledModal.scss";

function StyledModal({ title, children, childrenClassname, ...props }) {
  return (
    <Modal
      className="modal"
      title={title}
      {...props}
      data-testid="styled-modal" // Add test ID to the Modal component
    >
      <div
        className={`${childrenClassname} children-wrapper`}
        data-testid="styled-modal-wrapper"
      >
        {children}
      </div>
    </Modal>
  );
}

StyledModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default StyledModal;
