/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import { useNavigate } from "react-router-dom";
import "./DashboardCourseCard.scss";
import { pipelineStatusIcon } from "../../libs/utils";
import { ReactComponent as NotApproved } from "../../assets/NotAproved.svg";
import { ReactComponent as Approved } from "../../assets/Approved.svg";
import { Tooltip } from "antd";
import bg1 from "../../assets/courseBg1Square.jpg";
function DashboardCourseCard(props) {
  const { course, uniqueId, completed } = props;
  const navigate = useNavigate();
  const onClickMyCardHandle = () => {
    navigate(`/courses/${course.enroll_details[0].course.id}/content/`);
  };
  return (
    <div
      onClick={onClickMyCardHandle}
      className="myCourseCard-container"
      data-testid={`myCourseCard-container-${uniqueId}`}
    >
      <div className="myCourseCardHeader">
        <img className="myCourse-avatar" src={bg1} />
        <div className="myCourse-content">
          {/* need to remove completed variable when completed course  section comes */}
          {!completed ? (
            <div
              className="myCourse-title"
              data-testid={`myCourse-title-${uniqueId}`}
            >
              {course?.enroll_details[0]?.course?.course_name}
            </div>
          ) : (
            <div
              className="myCourse-titleCompleted"
              data-testid={`myCourse-subtitle-${uniqueId}`}
            >
              {course?.enroll_details[0]?.course?.course_name}
            </div>
          )}
          <div
            className="myCourse-subtitle"
            data-testid={`myCourse-subtitle-${uniqueId}`}
          >
            {!completed ? (
              course?.current_module?.module?.module_name ||
              course?.current_module?.module?.test_name
            ) : (
              <small
                className="myCourse-title"
                data-testid={`myCourse-title-${uniqueId}`}
                style={{ color: "#61d800", fontWeight: 400 }}
              >
                Completed
              </small>
            )}
          </div>
          {!completed && course?.current_module?.gitlab_details ? (
            <div className="pipelinestatus" data-testid="Pipeline-Status">
              <Tooltip
                data-testid="pipeline-status-tooltip"
                overlayInnerStyle={{ textTransform: "capitalize" }}
                title={
                  "Pipeline " +
                  course?.current_module?.gitlab_details?.pipeline_status
                }
              >
                {pipelineStatusIcon(
                  course?.current_module?.gitlab_details?.pipeline_status
                )}
              </Tooltip>

              {course?.current_module?.gitlab_details?.approved ? (
                <Tooltip title="PR Approved">
                  <Approved
                    title="PR Approved"
                    data-testid={`PR-Status-${uniqueId}`}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="PR Not Approved">
                  <NotApproved
                    title="PR Not Approved"
                    data-testid={`PR-Status-${uniqueId}`}
                  />
                </Tooltip>
              )}
            </div>
          ) : (
            <div className="pipelinestatus"></div>
          )}
        </div>
      </div>
      <div className="myCourseCardFooter">
        <div className="courseFooter-bottom">
          <div
            className="progressIndicator-container"
            data-testid={`ProgressBar`}
          >
            <div
              className="progressBar"
              style={{
                maxWidth: `${course?.courseProgress}%`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCourseCard;
