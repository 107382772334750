/* eslint-disable no-unused-vars */
import {
  AUTHORED_LIST_ID,
  CANDIDATE_LIST_ID,
  COMPLETION_STATUS,
  USER_LIST_ID,
  USER_STATUS,
} from ".";
import { getAllUserDetails } from "../store/actions/adminActions";
import {
  clearAuthoredCourseList,
  clearCourseList,
  clearInreviewCourses,
  clearOwnedCourseList,
  loadAuthoredCoursesList,
  loadCourses,
  loadEnrolledCourses,
  loadInReviewCourses,
  loadOwnedCoursesList,
  ownedCallback,
} from "../store/actions/courseAction";
import {
  removeisArchiveValue,
  removeRoleValue,
  updateOffset,
  updateSearchValues,
} from "../store/actions/listParamsAction";
import { getAllCandidateList } from "../store/actions/profilePage";
import { loadSearchFilterValues } from "../store/actions/searchBarActions";

export const searchPropsByTabKey = {
  all: {
    filterValues: [
      {
        filter: "category",
        operators: ["="],
        fetchValuesFromApi: true,
        fetchValuesFunction: async (dispatch, callback) => {
          dispatch(loadSearchFilterValues("course", "category", callback));
        },
      },
      {
        filter: "rating",
        values: ["1", "2", "3", "4", "5"],
        operators: ["=", "<", ">", "<=", ">="],
      },
      {
        filter: "author",
        operators: ["="],
        fetchValuesFromApi: true,
        fetchValuesFunction: async (dispatch, callback) => {
          dispatch(loadSearchFilterValues("course", "created_by", callback));
        },
      },
    ],
    onSearch: (params, dispatch, _id) => {
      // Implement the onSearch functionality for the "all" key
      dispatch(clearCourseList());
      dispatch(
        loadCourses({
          ...params,
          status: "PUBLISHED",
          type: "PRIVATE",
        })
      );
    },
  },
  owned: {
    filterValues: [
      {
        filter: "category",
        operators: ["="],
        fetchValuesFromApi: true,
        fetchValuesFunction: async (dispatch, callback) => {
          dispatch(loadSearchFilterValues("course", "category", callback));
        },
      },
      {
        filter: "rating",
        values: ["1", "2", "3", "4", "5"],
        operators: ["=", "<", ">", "<=", ">="],
      },
    ],
    onSearch: (params, dispatch, id) => {
      dispatch(clearOwnedCourseList());
      dispatch(
        loadOwnedCoursesList(
          id,
          { ...params, status: "PUBLISHED" },
          ownedCallback
        )
      );
    },
  },
  approval: {
    filterValues: [
      {
        filter: "author",
        operators: ["="],
        fetchValuesFromApi: true,
        fetchValuesFunction: async (dispatch, callback) => {
          dispatch(loadSearchFilterValues("course", "created_by", callback));
        },
      },
      {
        filter: "status",
        values: ["Passed", "Failed"],
        operators: ["="],
      },
    ],
    onSearch: (params, dispatch, _id) => {
      dispatch(clearInreviewCourses());
      dispatch(
        loadInReviewCourses({
          ...params,
        })
      );
    },
  },
  // Define other tab-specific filter values and onSearch functions
  // ...
};
export const searchPropsForUserList = {
  all: {
    filterValues: [
      {
        filter: "status",
        values: [USER_STATUS[0].label, USER_STATUS[1].label],
        operators: ["="],
      },
      {
        filter: "role",
        operators: ["="],
        fetchValuesFromApi: true,
        fetchValuesFunction: async (dispatch, callback) => {
          dispatch(loadSearchFilterValues("users", "groups", callback));
        },
      },
    ],
    onSearch: (params, dispatch) => {
      // Implement the onSearch functionality for the "all" key
      //dispatch(clearCourseList());
      // if (params.role) {
      //   const Role = PORTAL_ADMIN_ROLES_MENU.find(
      //     (item) => item.label === params.role
      //   );
      //   params.role = Role.value;
      // }
      if (params.status) {
        params.status === "active"
          ? (params.isArchive = false)
          : (params.isArchive = true);
        delete params.status;
      }
      dispatch(
        getAllUserDetails({
          ...params,
        })
      );
      dispatch(
        updateSearchValues({
          id: USER_LIST_ID,
          ...params,
        })
      );
    },
    onClear: (params, dispatch) => {
      // Implement the onSearch functionality for the "all" key
      dispatch(clearCourseList());
      dispatch(
        updateOffset({
          id: USER_LIST_ID,
          offset: 0,
        })
      );
      switch (params) {
        case "role": {
          dispatch(
            removeRoleValue({
              id: USER_LIST_ID,
              role: undefined,
            })
          );
          break;
        }
        case "status": {
          dispatch(
            removeisArchiveValue({
              id: USER_LIST_ID,
              isArchive: undefined,
            })
          );
          break;
        }
        default:
          dispatch(
            updateSearchValues({
              id: USER_LIST_ID,
              isArchive: undefined,
              role: undefined,
              search: undefined,
            })
          );
      }
    },
  },
};

export const searchPropsByForAuthoredList = {
  filterValues: [
    {
      filter: "category",
      operators: ["="],
      fetchValuesFromApi: true,
      fetchValuesFunction: async (dispatch, callback) => {
        dispatch(loadSearchFilterValues("course", "category", callback));
      },
    },
  ],
  onSearch: async (params, dispatch, id) => {
    dispatch(clearAuthoredCourseList());
    dispatch(
      updateSearchValues({
        id: AUTHORED_LIST_ID,
        ...params,
      })
    );
    dispatch(
      loadAuthoredCoursesList(id, {
        ...params,
      })
    );
  },
};

export const searchPropsForCandidateList = {
  filterValues: [
    {
      filter: "Completed",
      values: [COMPLETION_STATUS[0].label, COMPLETION_STATUS[1].label],
      operators: ["="],
    },
  ],
  onSearch: (params, course_id, dispatch) => {
    // Implement the onSearch functionality
    if (params.Completed) {
      params.Completed === "false"
        ? (params.is_completed = false)
        : (params.is_completed = true);
      delete params.Completed;
    }
    dispatch(
      updateSearchValues({
        id: CANDIDATE_LIST_ID,
        ...params,
      })
    );
    dispatch(getAllCandidateList(course_id, { ...params }));
  },
  onClear: (params, course_id, dispatch) => {
    dispatch(
      updateSearchValues({
        id: CANDIDATE_LIST_ID,
        is_completed: undefined,
      })
    );
    dispatch(getAllCandidateList(course_id, { ...params }));
  },
};

export const searchPropsForProfileEnrolledTab = {
  filterValues: [
    {
      filter: "Status",
      values: ["Completed"],
      operators: ["=", "!="],
    },
  ],
  onSearch: (params, user_id, dispatch) => {
    if (params.Status) {
      let value = params.Status;
      params.status = value;
      delete params.Status;
    }
    dispatch(loadEnrolledCourses(user_id, { ...params }));
  },
  onClear: (params, user_id, dispatch) => {
    dispatch(loadEnrolledCourses(user_id, { ...params }));
  },
};
