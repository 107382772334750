import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { ConfigProvider } from "antd";
import { theme } from "./theme";
import { Provider } from "react-redux";
import store from "./store/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useSelector } from "react-redux";
import { loginFormSelector } from "./store/selectors/authSelectors";

const root = ReactDOM.createRoot(document.getElementById("root"));

const GurukulApp = () => {
  const client_id = useSelector(loginFormSelector);
  return (
    <GoogleOAuthProvider clientId={client_id?.client_id}>
      <ConfigProvider theme={theme}>
        <App />
      </ConfigProvider>
    </GoogleOAuthProvider>
  );
};
root.render(
  <Provider store={store}>
    <GurukulApp />
  </Provider>
);
