import { Navigate } from "react-router-dom";
import CoursePage from "../pages/CourseDetailsPage/CoursePage";
import CourseListPage from "../pages/CourseListPage/CourseListPage";
import UserDashboardPage from "../pages/UserDashboardPage/UserDashboardPage";
import AdminUserPage from "../pages/AdminUserPage/AdminUserPage";
import UserProfilePage from "../pages/UserProfilePage/UserProfilePage";
import McqTestCreation from "../features/CourseListFeature/WizardCourseSteps/McqStep/McqTestCreation";
import McqPreviewMode from "../features/CourseListFeature/WizardCourseSteps/McqStep/McqPreviewMode";
import QuestionForm from "../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionForm";
import PageNotFoundComp from "../pages/PageNotFound/PageNotFoundComp";
import CandidateTestContainer from "../components/TestContent/CandidateTestContainer";
import PublicCourseDetailPage from "../expo/PublicCourseDetailPage/PublicCourseDetailPage";
import AboutCourse from "../features/CourseDetailsFeature/CourseEnrolled/TabScreen.js/AboutCourse";
import CandidateTestContainerPublic from "../expo/PublicTestPage/CandidateTestContainerPublic";
import PublicResultDisplay from "../expo/PublicTestPage/PublicResultDisplay";

export const commonRoute = [
  {
    path: "/courses/:id/public/content",
    element: <PublicCourseDetailPage />,
  },
  {
    path: "/tests/:id/start/public/results",
    element: <PublicResultDisplay />,
  },
  {
    path: "/courses/:id/public/course",
    element: <AboutCourse />,
  },
  {
    path: "/",
    element: <UserDashboardPage />,
  },
  {
    path: "/*",
    element: <Navigate to="/login" />,
  },
  {
    path: "courses",
    element: <CourseListPage />,
  },
  {
    path: "courses/:id/content/",
    element: <CoursePage />,
  },
  {
    path: "courses/:id/course/",
    element: <CoursePage />,
  },
  {
    path: "/404",
    element: <PageNotFoundComp />,
  },
  {
    path: "/tests/:id/start/:id/",
    element: <CandidateTestContainer />,
  },
  {
    path: "/tests/:id/start/public/",
    element: <CandidateTestContainerPublic />,
  },
];
export const portalAdminRoute = [
  {
    path: "/",
    element: <h1>IN PROGRESS ...</h1>,
  },
  {
    path: "/courses/all",
    element: <CourseListPage />,
  },
  {
    path: "/courses/owned",
    element: <CourseListPage />,
  },
  {
    path: "/courses/approval",
    element: <CourseListPage />,
  },
  {
    path: "/courses/:id/preview",
    element: <CoursePage />,
  },
  {
    path: "/courses/:id/statistics/",
    element: <CoursePage />,
  },

  {
    path: "/courses/:id/candidate/",
    element: <CoursePage />,
  },
  {
    path: "users",
    element: <AdminUserPage />,
  },
  {
    path: "/users/:id/authored",
    element: <UserProfilePage />,
  },
  {
    path: "/users/:id/enrolled",
    element: <UserProfilePage />,
  },
];
export const creatorRoute = [
  {
    path: "/",
    element: <UserDashboardPage />,
  },
  {
    path: "/courses/all",
    element: <CourseListPage />,
  },
  {
    path: "/courses/owned",
    element: <CourseListPage />,
  },
  {
    path: "/courses/approval",
    element: <CourseListPage />,
  },
  {
    path: "/courses/:id/preview",
    element: <CoursePage />,
  },
  {
    path: "/courses/:id/statistics/",
    element: <CoursePage />,
  },
  {
    path: "/courses/:id/create-mcq/",
    element: <McqTestCreation />,
  },
  {
    path: "/courses/:id/test/:id/preview",
    element: <McqPreviewMode />,
  },
  {
    path: "/courses/:id/test/:id/details",
    element: <McqTestCreation />,
  },
  {
    path: "/courses/:id/test/:id/manager",
    element: <McqTestCreation />,
  },
  {
    path: "/courses/:id/test/:id/manager?id=",
    element: <QuestionForm />,
  },
];
export const hRRoute = [
  {
    path: "/",
    element: <h1>IN PROGRESS ...</h1>,
  },
  {
    path: "/courses/all",
    element: <CourseListPage />,
  },
  {
    path: "/courses/owned",
    element: <CourseListPage />,
  },
  {
    path: "/courses/approval",
    element: <CourseListPage />,
  },
  {
    path: "/courses/:id/preview",
    element: <CoursePage />,
  },
  {
    path: "/courses/:id/statistics/",
    element: <CoursePage />,
  },
  {
    path: "/courses/:id/candidate/",
    element: <CoursePage />,
  },
  {
    path: "users",
    element: <AdminUserPage />,
  },
  {
    path: "/users/:id/authored",
    element: <UserProfilePage />,
  },
  {
    path: "/users/:id/enrolled",
    element: <UserProfilePage />,
  },
];
