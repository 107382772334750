import { useDispatch, useSelector } from "react-redux";
import "./LoginPage.scss";
import { useGoogleLogin } from "@react-oauth/google";
import {
  getClientId,
  validateTokenAction,
} from "../../store/actions/authActions";
import {
  groupArraySelector,
  isLoggedInSelector,
  loginFormSelector,
} from "../../store/selectors/authSelectors";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import LdapContainer from "./LdapContainer";
import { ReactComponent as GoogleIcon } from "../../assets/google-icon.svg";
import EigenlearnLogin from "../../assets/eigenlearn-logo-light-large.svg"; // Import the new SVG file
import EigenEvalLogin from "../../assets/EigenEvalLogin.svg"; // Import the new SVG file

import Footer from "../../components/Footer/Footer"; // Import the Footer component
const TitleContainer = () => {
  return (
    <div className="title-container">
      <div className="inside-elements">
        {process.env.REACT_APP_PROJECT_TYPE === "eigenlearn" ? (
          <img
            className="title-logo"
            src={EigenlearnLogin}
            alt="Eigenlearn Logo"
          />
        ) : (
          <img
            className="title-logo"
            src={EigenEvalLogin}
            alt="EigenEval Logo"
          />
        )}
        {/* <div className="title">eigeneval</div> */}
        <div className="subtitle">
          An online learning platform by Coriolis Technologies
        </div>
      </div>
    </div>
  );
};

const LoginContainer = () => {
  const loginSelector = useSelector(loginFormSelector);

  return (
    <div className="login-container">
      <div
        className={`sign-header ${
          loginSelector?.google_creds ? "apply-margin-top" : ""
        }`}
      >
        <img
          className="login-image"
          src={require("../../assets/learning.png")}
        />

        <div className="login-title">Sign In</div>
        <div className="login-subtitle">
          {process.env.REACT_APP_PROJECT_TYPE === "eigenlearn"
            ? "Access your mail to Sign In to eigenlearn"
            : "Access your mail to Sign In to eigeneval"}
        </div>
      </div>
      <LdapContainer />
      {loginSelector?.google_creds ? <div className="ok-field">OR</div> : null}
      {loginSelector?.google_creds && <GoogleContainer />}
    </div>
  );
};
const GoogleContainer = () => {
  const dispatch = useDispatch();
  const loginHandle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      localStorage.setItem("token", codeResponse.code);
      dispatch(validateTokenAction());
    },
    flow: "auth-code",
  });
  return (
    <div className="login-button" onClick={loginHandle}>
      <GoogleIcon title="google-icon" />
      <div className="continue">Continue with Google</div>
    </div>
  );
};
function LoginPage() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("token");
  const groupsArr = useSelector(groupArraySelector);
  const isHrAdminGroup = () => {
    return groupsArr.includes("HR") || groupsArr.includes("PORTAL_ADMIN");
  };

  const redirectToHome = () => {
    if (isLoggedIn) {
      if (isHrAdminGroup()) {
        navigate("/courses", { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    }
  };
  //calling API for google client Id
  useEffect(() => {
    dispatch(getClientId());
  }, []);

  useEffect(() => {
    if (accessToken === null) {
      setIsLoading(false);
    }
  }, [accessToken]);

  useEffect(() => {
    redirectToHome();
    return () => {};
  }, [isLoggedIn]);

  return (
    <div data-testid="section" className="section">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="loginPage-container">
          <TitleContainer />
          <LoginContainer />
          <div className="powered-by">
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
