import PropTypes from "prop-types";
import { Alert } from "antd";

const DisplayInfoMessage = ({ msg, type, onClick, ...props }) => {
  return (
    <Alert
      onClose={onClick}
      style={{
        fontSize: "14px",
        textAlign: "left",
      }}
      showIcon
      message={msg}
      type={type}
      {...props} // spread other props
    />
  );
};

DisplayInfoMessage.propTypes = {
  msg: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default DisplayInfoMessage;
