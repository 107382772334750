import { Modal } from "antd";
import PropTypes from "prop-types";
import "./StyledModalPublic.scss";

function StyledModal({
  title,
  children,
  childrenClassname,
  okText,
  isColor,
  confirmLoading,
  ...props
}) {
  return (
    <Modal
      className={`modal ${isColor ? "modal-oncolor" : ""}`}
      title={title}
      okText={isColor ? "Submitting" : okText}
      centered
      maskClosable={false}
      {...props}
      data-testid="styled-modal"
      confirmLoading={confirmLoading} // Add confirmLoading prop
    >
      <div
        className={`${childrenClassname} children-wrapper`}
        data-testid="styled-modal-wrapper"
      >
        {children}
      </div>
    </Modal>
  );
}

StyledModal.defaultProps = {
  okText: "Yes",
};

StyledModal.propTypes = {
  title: PropTypes.string.isRequired,
  okText: PropTypes.string,
};

export default StyledModal;
