import "./RepoLoader.scss";
import { ReactComponent as Branch } from "../../assets/branch.svg";
function RepoLoader() {
  return (
    <div className="repo_loader main-container">
      <div className="loader">
        {" "}
        <div className="spinners"></div>
        <div className="spinner-text">
          <Branch title="Branch" />
        </div>
      </div>
      <div className="text-content">Preparing your repositories</div>
      <div className="text-description">
        Setting up your Boilerplate and test repositories..
      </div>
    </div>
  );
}
export default RepoLoader;
