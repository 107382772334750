/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import { useNavigate } from "react-router-dom";
import courseBg1 from "../../assets/courseBg1.jpg";
import "./CourseCard.scss";
import { Rate } from "antd";

function CourseCard(props) {
  const { course } = props;
  const navigate = useNavigate();
  const clickCardHandle = (id) => {
    navigate(`/courses/${id}/content/`);
  };
  return (
    <div
      data-testid="courseCard-main-div"
      onClick={() => {
        clickCardHandle(course.id);
      }}
      className="courseCard-container"
    >
      <div className="courseCardHeader">
        <img className="course-bgImage" src={courseBg1} />
      </div>
      <div className="courseCard-content">
        <div className="courseCard-title" data-testid="courseCard-title">
          {course.course_name}
        </div>
        <div className="courseCard-author">{`${
          course.created_by === null
            ? "Coriolis Team"
            : course?.created_by?.user_name
        }`}</div>
        <div className="courseCard-rating">
          <Rate className="rating-stars" value={course?.rating} disabled />
          <div className="rating-count">{`( ${course?.no_of_ratings} )`}</div>
        </div>
        <div className="courseContent-footer">
          <div className="courseCard-modules">
            {course?.modules?.length + " Modules"}
          </div>
          <div className="courseCard-category">{course?.category}</div>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
