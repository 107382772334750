import { ReactComponent as IconInfo } from "../../../../../assets/icon_info.svg";
import GlobalEmptyContainer from "../../../../../components/GlobalEmptyContainer/GlobalEmptyContainer";
import PendingCourseList from "./PendingCourseList";
import EmptyContainerImage from "../../../../../assets/owned-courses-empty.png";
import { useEffect, useState } from "react";
const PendingCourseListTable = ({ data }) => {
  const [isEmpty, setIsEmpty] = useState(true);
  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify([])) {
      setIsEmpty(false);
    }
  }, [data]);
  return (
    <>
      {isEmpty ? (
        <GlobalEmptyContainer
          imgContent={<img src={EmptyContainerImage} alt="Empty container" />}
          content={
            <>
              <p className="title">Course Approval Center</p>
              <p className="subtitle">No Pending Approvals</p>
            </>
          }
        />
      ) : (
        <div className="table-container">
          <div className="table-title-container-pending">
            <div className="table-title">Course name</div>
            <div className="table-title">Author</div>
            <div className="table-title">
              Check Status &nbsp;
              <IconInfo />
            </div>
            <div className="table-title">Updated On</div>
          </div>
          <PendingCourseList data={data} />
        </div>
      )}
    </>
  );
};

export default PendingCourseListTable;
