import "./Banner.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { bannerSelector } from "../../store/selectors/bannerSelector";

const Banner = (props) => {
  const { id } = props;
  const banner = useSelector(bannerSelector(id));
  return (
    <>
      {banner ? (
        <div
          className="banner"
          data-testid="banner"
          style={{
            backgroundColor: banner.color + "20",
            border: "0.5px solid " + banner.color,
          }}
        >
          <div className="icon-div">
            <FontAwesomeIcon
              size="xl"
              color={banner.color}
              icon={banner.icon}
            />
          </div>
          <div className="tittle" data-testid="banner-title">
            {banner.message}
          </div>
        </div>
      ) : null}
    </>
  );
};
Banner.propTypes = {
  id: PropTypes.string.isRequired,
};
export default Banner;

// To use a Banner call
// dispatch({
//   type: SHOW_BANNER_ERROR | SHOW_BANNER_WARNING | SHOW_BANNER_INFO, //depending upon what type you want error| warning | info
//   payload: { id: 1234, message: "Error Message To Display" }, //Id for each component/modal keep it unique
// });
// using the dispatch particular banner with id 1234 will be visible in the component
// And To display a banner inside a modal just use
// <Banner id={1234}/>
