import StyledModal from "../../components/StyledModal/StyledModal";
import Spinner from "../../components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { unEnrollIntoCourse } from "../../store/actions/courseAction";

function UserUnenrollmentConfirmModal({
  open,
  onCancel,
  data,
  onsuccesscallback,
  loading,
  setLoading,
}) {
  const dispatch = useDispatch();
  const handleOk = () => {
    setLoading(true);
    dispatch(unEnrollIntoCourse(data.enroll_id, onsuccesscallback));
  };
  return (
    <StyledModal
      className={"draft-delete-modal"}
      centred
      title={"Unenroll User"}
      visible={open}
      onCancel={() => {
        setLoading(false);
        onCancel();
      }}
      onOk={handleOk}
      closable={true}
    >
      <div
        style={{
          marginTop: "30px",
          marginBottom: loading ? "20px" : "",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        {loading ? (
          <Spinner text={"Please wait.. Unenrollment in progress..."} />
        ) : (
          `Do you want to Unenroll "${data?.user_name}" from "${data?.course_name}"?`
        )}
      </div>
    </StyledModal>
  );
}

export default UserUnenrollmentConfirmModal;
