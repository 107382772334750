/* eslint-disable quotes */
import PropTypes from "prop-types";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import "./NavSideBar.scss";

import Sider from "antd/es/layout/Sider";
import MenuItem from "antd/es/menu/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  groupArraySelector,
  userSelector,
} from "../../store/selectors/authSelectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBell,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { logoutUser } from "../../store/actions/authActions";
import constants from "../../constants";
import NavConfirmModal from "./NavConfirmModal";

const NavSideBar = (props) => {
  const hash = window.location.hash.includes("/public");
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  let path = useLocation();
  useEffect(() => {
    routePaths.find((a) => {
      if (
        path &&
        path.pathname &&
        path.pathname?.split("/").length > 1 &&
        `/${a?.to?.split("/")[1]}` === `/${path.pathname.split("/")[1]}`
      ) {
        setSelected(a.key || a.to);
      }
    });
  }, [path.pathname]);
  const logoutHandle = () => {
    dispatch(logoutUser);
    navigate("/login", { replace: true });
  };

  const onClick = () => {
    logoutHandle();
  };
  const { routePaths, collapse } = props;
  const [collapsed, setCollapsed] = useState(collapse);
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();
  const groupsArr = useSelector(groupArraySelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onsuccesscallback = () => {
    setIsLoading(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    setCollapsed(collapse);
  }, [collapse]);

  const handleMenuItems = (event, path) => {
    setSelected(event.key);
    navigate(path);
  };
  return (
    <>
      <NavConfirmModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onsuccesscallback={onsuccesscallback}
        loading={isLoading}
        setLoading={setIsLoading}
        setIsModalOpen={setIsModalOpen}
      />
      {!hash && (
        <Sider
          width={collapsed ? '"fit-content"' : 230}
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="sideNavMenu-style">
            <Menu
              className="sideNav-menu"
              selectedKeys={selected}
              selectable={false}
            >
              <div className="sideNavMenu-title">
                <div className="sideNavMenu-logo">G</div>
                {collapsed ? null : <label>GURUKUL</label>}
              </div>
              <Menu.ItemGroup className="top-sideNavMenu-group">
                {routePaths.map((path) => {
                  if (
                    (groupsArr?.includes(constants.PORTAL_ADMIN) ||
                      groupsArr?.includes(constants.HR)) &&
                    path.name === "Dashboard"
                  ) {
                    return null;
                  }
                  return (
                    <MenuItem
                      title={path.name}
                      id={path.key}
                      data-testid={path.key}
                      key={path.key}
                      onClick={(e) => handleMenuItems(e, path.to)}
                    >
                      <div
                        className={`sideNavMenu-item ${
                          collapsed ? "sideNavMenu-collapsed" : ""
                        }`}
                      >
                        <div className="sideNavMenu-icon">{path.icon}</div>
                        {!collapsed ? (
                          <div className="sideNavMenu-names">
                            <label>{path.name}</label>
                          </div>
                        ) : null}
                      </div>
                    </MenuItem>
                  );
                })}
              </Menu.ItemGroup>
              <Menu.ItemGroup className="bottom-navbar">
                {collapsed ? null : (
                  <div className="navbar-username">{user?.user_name}</div>
                )}
                <div>
                  {groupsArr?.includes(constants.PORTAL_ADMIN) && (
                    <Menu.Item
                      title="Export Data"
                      key="3"
                      data-testid={"3"}
                      className="menu-item-bottom"
                      onClick={() => setIsModalOpen(true)}
                    >
                      <div
                        className={`sideNavMenu-item ${
                          collapsed ? "sideNavMenu-collapsed" : ""
                        }`}
                      >
                        <div className="sideNavMenu-icon">
                          <FontAwesomeIcon
                            icon={faDownload}
                            style={{ color: "#ffffff", fontSize: "20px" }}
                          />
                        </div>
                        {!collapsed ? (
                          <div className="sideNavMenu-names">
                            <label>Export Data</label>
                          </div>
                        ) : null}
                      </div>
                    </Menu.Item>
                  )}

                  <Menu.Item key="1" className="menu-item-bottom" disabled>
                    <div
                      className={`sideNavMenu-item ${
                        collapsed ? "sideNavMenu-collapsed" : ""
                      }`}
                    >
                      <div className="sideNavMenu-icon">
                        <FontAwesomeIcon
                          icon={faBell}
                          style={{ color: "#ffffff", fontSize: "20px" }}
                        />
                      </div>
                      {!collapsed ? (
                        <div className="sideNavMenu-names">
                          <label className="disbaled-notfiy">
                            Notification
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    title="Logout"
                    key="2"
                    data-testid={"2"}
                    className="menu-item-bottom"
                    onClick={() => onClick("2")}
                  >
                    <div
                      className={`sideNavMenu-item ${
                        collapsed ? "sideNavMenu-collapsed" : ""
                      }`}
                    >
                      <div className="sideNavMenu-icon">
                        <FontAwesomeIcon
                          icon={faArrowRightFromBracket}
                          style={{ color: "#ffffff", fontSize: "20px" }}
                        />
                      </div>
                      {!collapsed ? (
                        <div className="sideNavMenu-names">
                          <label>Logout</label>
                        </div>
                      ) : null}
                    </div>
                  </Menu.Item>
                </div>
              </Menu.ItemGroup>
            </Menu>
          </div>
        </Sider>
      )}
    </>
  );
};

NavSideBar.propTypes = {
  routePaths: PropTypes.arrayOf(Object).isRequired,
};

export default NavSideBar;
