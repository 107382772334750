import { useDispatch, useSelector } from "react-redux";
import { CURRENT_MODULE } from "../../store/actiontypes";
import { currentModule } from "../../store/selectors/coursesSelectors";
import Spinner from "../../components/Spinner/Spinner";
import { setPublicModuleIndex } from "../../store/actions/courseAction";

const TestModuleSection = (props) => {
  const { course } = props;
  const dispatch = useDispatch();
  const currentModules = useSelector(currentModule);

  const onClickHandler = (ele, index) => {
    dispatch({
      type: CURRENT_MODULE,
      payload: ele,
    });
    dispatch(setPublicModuleIndex(index));
  };

  if (course === null) {
    return <Spinner />;
  }

  return (
    <>
      <div className="modules-section-enrolled">
        {course[0]?.module_details?.map((element, index) => {
          return (
            <div
              key={index}
              className={
                currentModules?.module?.id === element?.module?.id
                  ? "accordian-style-in-progress"
                  : "accordian-style"
              }
              data-testid={`module-section-${index}`}
              onClick={() => onClickHandler(element, index)} //Pass index
            >
              <span className="enrolled-title enrolled-color">
                <p>{element.module.module_name}</p>
              </span>
              <p className={"enrolled-subtitle"}>
                <>{element.module.module_description}</>
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TestModuleSection;
