import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function CodeCopyBtn({ children }) {
  const [copyOk, setCopyOk] = useState(false);
  const icon = copyOk ? faCheckCircle : faCopy;
  const handleClick = () => {
    navigator.clipboard.writeText(children);
    setCopyOk(true);
    setTimeout(() => {
      setCopyOk(false);
    }, 2000);
  };
  return (
    <div
      className="code-copy-btn"
      data-testid="code-copy-btn"
      onClick={handleClick}
      style={{ cursor: "pointer", paddingRight: "15px" }}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
}
