import { CANDIDATE_LIST } from "../actiontypes";

const initialState = { candidates: null };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CANDIDATE_LIST:
      return { ...state, candidates: payload };
    default:
      return state;
  }
}
