import { useState } from "react";
import { Button } from "antd";
import UnEnrolledTabDisplay from "./UnEnrolledTabDisplay";
import Breadcrumbs from "../../../../components/BreadCrumb/BreadCrumbs";
import UnEnrolledTabHeaders from "./UnEnrolledTabHeaders";
import "./UnEnrolledTabScreen.scss";
import { useEffect } from "react";
const UnEnrolledTabScreen = ({ courseList, setIsModalOpen }) => {
  const [activeTab, setActiveTab] = useState("content");

  useEffect(() => {
    // Set the active tab based on the current URL
    const pathSegments = (location.pathname + location.hash).split("/");
    const tabSegmentIndex = pathSegments.findIndex(
      (segment) =>
        segment.toLowerCase() === "statistics" ||
        segment.toLowerCase() === "content" ||
        segment.toLowerCase() === "candidate"
    );
    if (tabSegmentIndex !== -1) {
      const tabValue = pathSegments[tabSegmentIndex].toLowerCase();
      setActiveTab(tabValue);
    }
  }, []);
  return (
    <>
      {courseList.map((values) => {
        return (
          <div className="courseUnenrolledPage-header" key={values.course.id}>
            <div className="header-left">
              <Breadcrumbs />
              <div className="course-title">{values.course.course_name}</div>
              <UnEnrolledTabHeaders
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            <div className="header-right">
              <Button
                onClick={() => setIsModalOpen(true)}
                type="primary"
                size="large"
                block="true"
                style={{ background: "#1A2B3C" }}
                data-testid="enroll-button"
              >
                ENROLL
              </Button>
            </div>
          </div>
        );
      })}
      <UnEnrolledTabDisplay activeTab={activeTab} />
    </>
  );
};

export default UnEnrolledTabScreen;
