/* eslint-disable no-unused-vars */
import "./CourseEnrolledPage.scss";
import Breadcrumbs from "../../../components/BreadCrumb/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedCourseSelector,
  selectSelectedPiplineStatusPercentage,
} from "../../../store/selectors/coursesSelectors";
import { useEffect, useState } from "react";
import { loadSelectedCourse } from "../../../store/actions/courseAction";
import { useParams } from "react-router";
import Spinner from "../../../components/Spinner/Spinner";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import CourseTabs from "../../../features/CourseDetailsFeature/CourseEnrolled/CourseTabs";
import TabScreen from "../../../features/CourseDetailsFeature/CourseEnrolled/TabScreen.js/TabScreen";
import { isPreviewConstant } from "../../../store/actions/wizardActions";
import { CLEAR_SELECTED_COURSES } from "../../../store/actiontypes";
import PreviewModeRejectOrPublish from "../../../components/PreviewModeRejectOrPublish/PreviewModeRejectOrPublish";
import { groupArraySelector } from "../../../store/selectors/authSelectors";
import { getCoursePipelineStatus } from "../../../store/actions/courseAction";

import constants from "../../../constants";

function CourseEnrolledPage(props) {
  const { isPreview, course_id } = props;
  const dispatch = useDispatch();
  const course = useSelector(selectedCourseSelector);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("content");
  const coursePercentage = useSelector(selectSelectedPiplineStatusPercentage);
  const [percent, setPercent] = useState(0);
  const loadSelectedCourseDetails = (id) => {
    dispatch(loadSelectedCourse(id));
  };
  const groupsArr = useSelector(groupArraySelector);
  useEffect(() => {
    loadSelectedCourseDetails(id);
    return () => {
      dispatch(isPreviewConstant(false));
      dispatch({ type: CLEAR_SELECTED_COURSES });
    };
  }, []);
  useEffect(() => {
    if (course_id) {
      dispatch(getCoursePipelineStatus(course_id));
    }
  }, [course_id]);

  useEffect(() => {
    if (coursePercentage) {
      setPercent(coursePercentage?.courseCompletePercentage);
    }
  }, [coursePercentage?.courseCompletePercentage]);

  useEffect(() => {
    // Set the active tab based on the current URL
    const pathSegments = (location.pathname + location.hash).split("/");
    const tabSegmentIndex = pathSegments.findIndex(
      (segment) =>
        segment.toLowerCase() === "content" ||
        segment.toLowerCase() === "course"
    );
    if (tabSegmentIndex !== -1) {
      const tabValue = pathSegments[tabSegmentIndex].toLowerCase();
      setActiveTab(tabValue);
    }
  }, []);

  useEffect(() => {
    if (course !== null) {
      setIsLoading(false);
    }
  }, [course]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="courseEnrolledPage-container">
          {isPreview && groupsArr?.includes(constants.PORTAL_ADMIN) ? (
            <PreviewModeRejectOrPublish data={course} />
          ) : null}
          <div className="courseEnrolledPage-header">
            <div className="header-left">
              {/* <Breadcrumbs /> */}
              {isPreview ? null : (
                <>{course !== null ? <Breadcrumbs /> : null}</>
              )}
              <div className="course-title">
                {course && course[0].course?.course_name}
              </div>
              <CourseTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            {isPreview ? null : (
              <div className="header-right">
                <ProgressBar per={percent} />
              </div>
            )}
          </div>
          <TabScreen
            activeTab={activeTab}
            lab_required={course[0]?.course?.lab_required}
          />
        </div>
      )}
    </>
  );
}

export default CourseEnrolledPage;
