/* eslint-disable no-unused-vars */
import { USER_LIST_ID } from "../../constants";
import {
  CLEAR_FILTER_VALUES,
  REMOVE_ISARCHIVE_VALUES,
  REMOVE_ROLE_VALUES,
  REMOVE_SEARCH_VALUES,
  SET_PAGINATION_LIMIT,
  SET_PAGINATION_OFFSET,
  SET_SEARCH_VALUES,
} from "../actiontypes/index";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PAGINATION_LIMIT: {
      // eslint-disable-next-line no-case-declarations
      const { id, limit, offset } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          limit,
          offset,
        },
      };
    }
    case SET_PAGINATION_OFFSET: {
      const { id, offset } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          offset: offset,
        },
      };
    }
    case SET_SEARCH_VALUES: {
      const { id, ...params } = payload;
      return {
        ...state,
        [id]: {
          limit: state[id].limit,
          offset: state[id].offset,
          ...params,
        },
      };
    }
    case REMOVE_SEARCH_VALUES: {
      const { id, role, isArchive, search } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          id: id,
          role: role,
          isArchive: isArchive,
          search: search,
        },
      };
    }
    case REMOVE_ROLE_VALUES: {
      const { id, role } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          role: role,
        },
      };
    }
    case REMOVE_ISARCHIVE_VALUES: {
      const { id, isArchive } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isArchive: isArchive,
        },
      };
    }
    case CLEAR_FILTER_VALUES: {
      const { id } = payload;
      return {
        ...state,
        [id]: {},
      };
    }
    default:
      return state;
  }
}
