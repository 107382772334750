/* eslint-disable no-unused-vars */
import {
  CLEAR_AUTHORED_COURSES,
  CLEAR_COURSES,
  CLEAR_COURSES_LIST,
  CLEAR_DRAFT_COURSES,
  CLEAR_ENROLLED_COURSE,
  CLEAR_IN_REVIEW_COURSES,
  CLEAR_OWNED_COURSES,
  CLEAR_SELECTED_COURSES,
  CURRENT_MCQ_INDEX,
  CURRENT_MODULE,
  ENROLLED_COURSE,
  GET_PIPELINE_STATUS,
  LOAD_AUTHORED_COURSES,
  LOAD_AVG_COMPLETION_TIME,
  LOAD_CANDIDATES_ENROLLED,
  LOAD_COURSES,
  LOAD_DRAFT_COURSES,
  LOAD_IN_REVIEW_COURSES,
  LOAD_MY_COURSES,
  LOAD_OWNED_COURSES,
  LOAD_SELECTED_COURSES,
  SET_PUBLIC_MODULE_INDEX,
  SET_SELECTED_QUIZ_INDEX,
  START_TEST,
  SUBMIT_ANSWER,
} from "../actiontypes/index";

const initialState = {
  myCourses: null,
  courses: null,
  selectedCourse: null,
  selectedCourseStatusPercentage: 0,
  currentModule: null,
  publicModuleIndex: 0,
  selectedQuizIndex: 0,
  enrolledCourse: null,
  inReviewCoursess: null,
  draftCourses: null,
  ownedCourses: null,
  authoredCourses: null,
  topFiveCandidates: null,
  candidatesEnrolled: null,
  avgCompletionTime: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_COURSES:
      return { ...state, courses: payload };
    case CLEAR_ENROLLED_COURSE:
      return { ...state, enrolledCourse: null };
    case LOAD_MY_COURSES:
      return { ...state, myCourses: payload };
    case LOAD_SELECTED_COURSES:
      return { ...state, selectedCourse: payload };
    case CLEAR_SELECTED_COURSES:
      return { ...state, selectedCourse: null };
    case GET_PIPELINE_STATUS:
      return { ...state, selectedCourseStatusPercentage: payload };
    case CLEAR_COURSES:
      return { ...state, myCourses: null, courses: null };
    case CURRENT_MODULE:
      return { ...state, currentModule: payload };
    case SET_PUBLIC_MODULE_INDEX:
      return { ...state, publicModuleIndex: payload };
    case SET_SELECTED_QUIZ_INDEX:
      return { ...state, selectedQuizIndex: payload };
    case ENROLLED_COURSE:
      return { ...state, enrolledCourse: payload };
    case CLEAR_COURSES_LIST:
      return { ...state, courses: null };
    case LOAD_IN_REVIEW_COURSES:
      return { ...state, inReviewCoursess: payload };
    case CLEAR_IN_REVIEW_COURSES:
      return { ...state, inReviewCoursess: null };
    case LOAD_DRAFT_COURSES:
      return { ...state, draftCourses: payload };
    case CLEAR_DRAFT_COURSES:
      return { ...state, draftCourses: null };
    case LOAD_OWNED_COURSES:
      return { ...state, ownedCourses: payload };
    case CLEAR_OWNED_COURSES:
      return { ...state, ownedCourses: null };
    case LOAD_AUTHORED_COURSES:
      return { ...state, authoredCourses: payload };
    case CLEAR_AUTHORED_COURSES:
      return { ...state, authoredCourses: null };
    case LOAD_CANDIDATES_ENROLLED:
      return { ...state, candidatesEnrolled: payload };
    case LOAD_AVG_COMPLETION_TIME:
      return { ...state, avgCompletionTime: payload };
    case START_TEST:
      return { ...state, giving_test: payload };
    case CURRENT_MCQ_INDEX:
      return { ...state, current_mcq_index: payload };
    case SUBMIT_ANSWER:
      // eslint-disable-next-line no-case-declarations
      const { questionIndex, userAnswer } = payload;
      // eslint-disable-next-line no-case-declarations
      const updatedDetails = state.giving_test.details.map((detail, index) => {
        if (index === questionIndex) {
          return { ...detail, user_answer: [userAnswer] };
        }
        return detail;
      });
      return {
        ...state,
        giving_test: {
          ...state.giving_test,
          details: updatedDetails,
        },
      };
    default:
      return state;
  }
}
