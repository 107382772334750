/* eslint-disable no-unused-vars */
import {
  CLEAR_GRAPH_VALUES,
  SET_BELL_CURVE_VALUES,
  SET_COURSE_SUMMARY_VALUES,
} from "../actiontypes/index";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_COURSE_SUMMARY_VALUES: {
      const { id, data } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          course_summary_values: data,
        },
      };
    }
    case SET_BELL_CURVE_VALUES: {
      const { id, data } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          bell_curve_values: data,
        },
      };
    }
    case CLEAR_GRAPH_VALUES: {
      const { id } = payload;
      return {
        ...state,
        [id]: {},
      };
    }
    default:
      return state;
  }
}
