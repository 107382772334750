import { useEffect, useState } from "react";
import TestTabScreen from "./TestTabScreen";
import ExpoCourseTabs from "./ExpoCourseTabs";
import Spinner from "../../components/Spinner/Spinner";

function TestEnrolledPage(props) {
  const { course } = props;
  const [activeTab, setActiveTab] = useState("content");

  useEffect(() => {
    const pathSegments = (location.pathname + location.hash).split("/");
    const tabSegmentIndex = pathSegments.findIndex(
      (segment) =>
        segment.toLowerCase() === "content" ||
        segment.toLowerCase() === "course"
    );
    if (tabSegmentIndex !== -1) {
      const tabValue = pathSegments[tabSegmentIndex].toLowerCase();
      setActiveTab(tabValue);
    }
  }, []);

  return (
    <div className="courseEnrolledPage-container">
      {course !== null || course !== undefined ? (
        <>
          <div className="courseEnrolledPage-header">
            <div className="header-left">
              <div className="public-course-title">
                {course && course[0].course?.course_name}
              </div>{" "}
              <ExpoCourseTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
          <TestTabScreen activeTab={activeTab} course={course} />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default TestEnrolledPage;
