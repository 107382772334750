import "./ModuleDisplay.scss";
import ModuleSection from "../ModuleSection/ModuleSection";

const ModulesDisplay = (props) => {
  const { isEnrolled, isPreview } = props;

  return (
    <div
      className={
        isEnrolled || isPreview ? "modules-section-enrolled" : "modules-section"
      }
    >
      {isEnrolled || isPreview ? null : (
        <h4 className="course-subtittle" data-testid="subtitle">
          Modules
        </h4>
      )}
      <div className="modules-display">
        {/* <ModuleButtons {...props} /> */}
        <ModuleSection {...props} />
      </div>
    </div>
  );
};

export default ModulesDisplay;
