const wizardSelector = (state) => {
  return state.wizard;
};

export const wizardCurrentStepSelector = (state) => {
  return wizardSelector(state).currentStep;
};
export const isPreviewSelector = (state) => {
  return wizardSelector(state).isPreview;
};
export const wizardStepsSelector = (state) => {
  return wizardSelector(state).steps;
};
export const isCheckedSelector = (state) => {
  return wizardSelector(state).isChecked;
};
export const wizardLoaderSelector = (state) => {
  return wizardSelector(state).loading;
};

export const wizardIsUpdatedSelector = (state) => {
  return wizardSelector(state).isUpdated;
};

export const createRepoLoaderSelector = (state) => {
  return wizardSelector(state).isRepoCreateLoading;
};

export const currentEditingTestDetails = (state) => {
  return wizardSelector(state).currentEditingTestDetails;
};
export const currentEditingQuestions = (state) => {
  return wizardSelector(state).currentEditingQuestions;
};
export const currentEditingQuestion = (state) => {
  return wizardSelector(state).currentEditingQuestion;
};
