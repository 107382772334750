/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMcqs } from "../../../../store/actions/wizardActions";
import {
  currentEditingQuestions,
  currentEditingTestDetails,
} from "../../../../store/selectors/wizardSelectors";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import QuestionsList from "./QuestionsList";
import QuestionEmptyContainer from "./QuestionEmptyContainer";
import "./QuestionManagerMcq.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import QuestionForm from "./QuestionForm";
import _ from "lodash";
import QuestionReorder from "./QuestionReorder";

const QuestionManagerMcq = () => {
  const navigate = useNavigate();
  const mcqListId = useSelector(currentEditingTestDetails);
  const dispatch = useDispatch();
  const courseId = location.hash.split("/")[2];
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [searchParams] = useSearchParams();
  const [reorderSection, setReorderSection] = useState(false);
  useEffect(() => {
    if (location.hash.includes("id")) {
      setShowQuestionForm(true);
    } else {
      setShowQuestionForm(false);
    }
  }, [location.hash, showQuestionForm]);
  const handleButtonClick = () => {
    navigate({
      pathname: `/courses/${courseId}/test/${mcqListId.id}/manager`,
      search: "?id=new",
    });
  };
  const handlCancel = () => {
    setShowQuestionForm(false);
    navigate(`/courses/${courseId}/test/${mcqListId.id}/manager`);
  };
  useEffect(() => {
    if (mcqListId?.id) {
      dispatch(getMcqs(mcqListId.id));
    }
  }, []);

  const handleReorderSectionStateChange = (updatedState) => {
    setReorderSection(updatedState);
  };

  const currentEditingQuestionsList = useSelector(currentEditingQuestions);
  const buttonProps = {
    label: "Add Question",
    className: "add-question-button",
    onClick: handleButtonClick,
    style: {
      backgroundColor: "#1A2B3C",
      color: "#FFFFFF",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "21px",
      letterSpacing: "0em",
      textAlign: "center",
    },
  };
  return (
    <>
      {showQuestionForm && <QuestionForm handlCancel={handlCancel} />}
      {!showQuestionForm && (
        <>
          {reorderSection ? (
            <QuestionReorder
              reorderSectionStateChange={handleReorderSectionStateChange}
            />
          ) : (
            <>
              {isEmpty(currentEditingQuestionsList?.results) &&
              !searchParams.toString() ? (
                <QuestionEmptyContainer buttonProps={buttonProps} />
              ) : (
                <QuestionsList
                  reorderSectionStateChange={handleReorderSectionStateChange}
                  questions={currentEditingQuestionsList}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default QuestionManagerMcq;
