import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { useSelector } from "react-redux";

import { currentEditingTestDetails } from "../../../../store/selectors/wizardSelectors";
import { useNavigate } from "react-router-dom";

export const QuestionListItemHeader = ({ header, onDelete }) => {
  const mcqId = useSelector(currentEditingTestDetails);
  const navigate = useNavigate();
  const items = [
    {
      label: (
        <span className="question-icon-div">
          <EditOutlined className="question-icons" />
          Edit
        </span>
      ),
      key: "Edit",
    },
    {
      label: (
        <span className="question-icon-div">
          <DeleteOutlined className="question-icons" />
          Delete
        </span>
      ),
      key: "Delete",
    },
  ];
  const onEditHandleClick = () => {
    navigate(
      `/courses/${location.hash.split("/")[2]}/test/${mcqId.id}/manager?id=${
        header.question_id
      }`
    );
  };
  const menuProps = {
    items,
    onClick: (e) => {
      if (e.key === "Delete") onDelete([header.question_id]);
      if (e.key === "Edit") onEditHandleClick();
    },
  };
  return (
    <div className="questionListitem-header">
      <div>
        <span className="question-number">Q {header.order_no}</span>
      </div>
      <div>
        <span className="question-type-text">Type</span>
        <span className="question-type">
          {header.question_type}{" "}
          {header.question_type !== "Subjective" && "Choice"}
        </span>
        <span className="question-list-item-seperator">|</span>
        <span className="question-type-text">Points</span>
        <span className="question-type">{header.points}</span>
        <span>
          {" "}
          <Dropdown
            placement="bottomRight"
            menu={menuProps}
            trigger={["click"]}
            overlayStyle={{ width: 168 }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <MoreOutlined
              style={{
                fontSize: "20px",
                color: "#696969",
                transform: "rotate(90deg)",
                paddingLeft: "10px",
              }}
            />
          </Dropdown>
        </span>
      </div>
    </div>
  );
};
