export const avatar = (userName) => {
  const initial = userName ? userName.charAt(0).toUpperCase() : "";

  // Generate a background color based on a hash of the user's name
  const colors = [
    "#f56a00",
    "#7265e6",
    "#ffbf00",
    "#00a2ae",
    "#ff5733",
    "#33c4ff",
    "#ffc733",
    "#34a853",
  ];

  const hash = userName
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const colorIndex = hash % colors.length;
  const backgroundColor = colors[colorIndex];

  return { initial, backgroundColor };
};
