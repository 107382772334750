import { userSelector } from "../selectors/authSelectors";
import { actionFactory } from "../../libs/actionFactory";
import {
  CLEAR_AUTHORED_COURSES,
  CLEAR_COURSES_LIST,
  CLEAR_DRAFT_COURSES,
  CLEAR_IN_REVIEW_COURSES,
  CLEAR_OWNED_COURSES,
  CLEAR_SELECTED_COURSES,
  CREATE_BRANCHES_BY_COURSE,
  CURRENT_MCQ_INDEX,
  ENROLLED_COURSE,
  GET_PIPELINE_STATUS,
  LOAD_AUTHORED_COURSES,
  LOAD_AVG_COMPLETION_TIME,
  LOAD_CANDIDATES_ENROLLED,
  LOAD_COURSES,
  LOAD_DRAFT_COURSES,
  LOAD_IN_REVIEW_COURSES,
  LOAD_MY_COURSES,
  LOAD_OWNED_COURSES,
  LOAD_SELECTED_COURSES,
  SET_PUBLIC_MODULE_INDEX,
  SET_SELECTED_QUIZ_INDEX,
  START_TEST,
  UNENROLL_COURSE,
  WIZARD_STEP_FOUR,
  WIZARD_STEP_THREE,
  WIZRAD_STEP_ONE,
  WIZRAD_STEP_TWO,
} from "../actiontypes/index";
import {
  reloadDraftCourseDetails,
  setWizardCurrentStep,
  wizardLoading,
} from "./wizardActions";

export const loadMyCourses = (id) => {
  return actionFactory({
    api: "get/api/courses/enroll/" + id,
    failureToast: true,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch({ type: LOAD_MY_COURSES, payload: resp["data"].data });
        })
        .catch((_) => {});
    },
  });
};
export const updateCourseMarkAsCompleted = (course_id, enroll_id) => {
  return actionFactory({
    api: `post/api/courses/enroll/${enroll_id}/mark_as_complete_course/`,
    failureToast: true,
    successToast: "Module marked as Completed",
    actionBody: {
      body: { module_id: course_id },
    },
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: LOAD_MY_COURSES,
            payload: resp["data"].data,
          });

          dispatch(
            getCoursePipelineStatus(
              resp["data"]?.data[0]?.enroll_details?.course?.id
            )
          );

          // callback();
        })
        .catch((_) => {
          // callback();
        });
    },
  });
};

export const loadEnrolledCourses = (
  userid,
  params = {},
  callback = () => {}
) => {
  return actionFactory({
    api: `get/api/courses/enroll/?user_id=${userid}`,
    actionBody: {
      params: { ...params },
    },
    failureToast: true,

    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch({ type: ENROLLED_COURSE, payload: resp["data"].data });
          callback();
        })
        .catch((_) => {
          callback();
        });
    },
  });
};

export const loadCourses = (
  params = { status: "PUBLISHED", type: "PRIVATE" },
  setLoadingStatus = null
) => {
  return actionFactory({
    api: "get/api/courses/",
    actionBody: {
      params: { ...params },
    },
    failureToast: true,
    actionBase: LOAD_COURSES,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (setLoadingStatus) setLoadingStatus(false);
          dispatch({ type: LOAD_COURSES, payload: resp["data"].data });
        })
        .catch((_) => {});
    },
  });
};

export const loadInReviewCourses = (params = {}, setLoadingStatus = null) => {
  return actionFactory({
    api: "get/api/courses/?status=IN_REVIEW",
    actionBody: {
      params: { ...params },
    },
    failureToast: true,
    actionBase: LOAD_IN_REVIEW_COURSES,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (setLoadingStatus) setLoadingStatus(false);
          dispatch({
            type: LOAD_IN_REVIEW_COURSES,
            payload: resp["data"].data.results,
          });
        })
        .catch((_) => {});
    },
  });
};

export const loadOwnedCoursesList = (userId, params = {}, callback) => {
  return actionFactory({
    api: "get/api/courses/",
    actionBody: {
      params: { ...params, author: userId },
    },
    callback: (respPromise, dispatch) => callback(respPromise, dispatch),
  });
};

export const ownedCallback = (respPromise, dispatch) => {
  respPromise
    .then((resp) => {
      dispatch({
        type: LOAD_OWNED_COURSES,
        payload: resp["data"].data.results,
      });
    })
    .catch((_) => {});
};

export const draftCallback = (respPromise, dispatch) => {
  respPromise
    .then((resp) => {
      dispatch({
        type: LOAD_DRAFT_COURSES,
        payload: resp["data"].data.results,
      });
    })
    .catch((_) => {});
};

export const loadAuthoredCoursesList = (userId, params = {}) => {
  return actionFactory({
    api: "get/api/courses/",
    //By Default Published Courses to be displayed
    actionBody: {
      params: { ...params, author: userId, status: "PUBLISHED" },
    },
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: LOAD_AUTHORED_COURSES,
            payload: resp["data"].data,
          });
        })
        .catch((_) => {});
    },
  });
};

export const clearAuthoredCourseList = () => {
  return { type: CLEAR_AUTHORED_COURSES };
};

export const updateCourseStatus = (
  id,
  status,
  course_name,
  handleClose,
  isPreviewRedirection
) => {
  return actionFactory({
    api: `patch/api/courses/${id}/`,
    actionBody: {
      body: {
        status: status,
      },
    },
    failureToast: true,
    successToast: `${course_name} ${status} `,
    actionBase: LOAD_IN_REVIEW_COURSES,
    callback: (respPromise, dispatch) => {
      respPromise.then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          dispatch(loadInReviewCourses());
          handleClose();
          isPreviewRedirection();
        }
      });
    },
  });
};
export const clearCourseList = () => {
  return { type: CLEAR_COURSES_LIST };
};

export const clearInreviewCourses = () => {
  return { type: CLEAR_IN_REVIEW_COURSES };
};

export const clearDraftCourseList = () => {
  return { type: CLEAR_DRAFT_COURSES };
};

export const clearOwnedCourseList = () => {
  return { type: CLEAR_OWNED_COURSES };
};

export const loadSelectedCourse = (id) => {
  return actionFactory({
    api: `get/api/courses/${id}`,
    failureToast: true,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch({ type: LOAD_SELECTED_COURSES, payload: resp["data"].data });
        })
        .catch((_) => {});
    },
  });
};

export const loadCandidatesEnrolled = (id, paramsValue, successCallback) => {
  return actionFactory({
    api: `get/api/courses/${id}/enroll/candidates_enrolled_graph`,
    actionBody: {
      params: { timeline: paramsValue },
    },
    actionBase: LOAD_CANDIDATES_ENROLLED,
    failureToast: true,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: LOAD_CANDIDATES_ENROLLED,
              payload: resp["data"].data,
            });
            successCallback();
          }
        })
        .catch((_) => {});
    },
  });
};

export const loadAvgCompletionTime = (id, paramsValue, successCallback) => {
  return actionFactory({
    api: `get/api/courses/${id}/enroll/candidates_avg_time_completion_graph`,
    actionBody: {
      params: { timeline: paramsValue },
    },
    failureToast: true,
    actionBase: LOAD_AVG_COMPLETION_TIME,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: LOAD_AVG_COMPLETION_TIME,
              payload: resp["data"].data,
            });
            successCallback();
          }
        })
        .catch((_) => {});
    },
  });
};

export const getCoursePipelineStatus = (id) => {
  return actionFactory({
    api: `get/api/courses/${id}/status`,
    failureToast: true,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch({ type: GET_PIPELINE_STATUS, payload: resp["data"].data });
        })
        .catch((_) => {});
    },
  });
};

export const clearSelectedCourse = () => {
  return { type: CLEAR_SELECTED_COURSES };
};

export const enrollIntoCourse = (enrollDetails, callback) => {
  return actionFactory({
    api: "post/api/courses/enroll/",
    failureToast: true,
    successToast: "Enrollment successful",
    actionBody: {
      body: enrollDetails,
    },
    callback: (respPromise, dispatch, getState) =>
      callback(respPromise, dispatch, getState),
  });
};
export const unEnrollIntoCourse = (enroll_id, callback) => {
  return actionFactory({
    api: `delete/api/courses/enroll/${enroll_id}/`,
    failureToast: true,
    successToast: "Un-Enrollment successful",
    actionBase: UNENROLL_COURSE,
    callback: (respPromise, dispatch, getState) =>
      callback(respPromise, dispatch, getState),
  });
};

export const createBranchesbyCourse = (courseId, user_id, callback) => {
  return actionFactory({
    api: `post/api/courses/${courseId}/create_branch/`,
    failureToast: true,
    successToast: "Branches created",
    actionBody: {
      id: courseId,
      body: {
        emp_id: user_id,
      },
    },
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: CREATE_BRANCHES_BY_COURSE,
            });
            callback();
          }
        })
        .catch(() => {
          callback();
        });
    },
  });
};

export const loadDraftCourseDetails = (u_id, c_id) => {
  return actionFactory({
    api: "get/api/create/",
    actionBody: {
      params: {
        user_id: u_id,
        course_id: c_id,
      },
    },
    // actionBase: CREATE_COURSE,
    failureToast: true,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            // dispatch({ type: WIZRAD_STEP_TWO, payload: resp["data"].data });
            // dispatch({
            //   type: WIZARD_NEXT,
            // });
            const wizard = loadDataToWizard(resp["data"].data[0]);
            dispatch(setWizardCurrentStep(wizard.currentStep));
            dispatch({ type: WIZRAD_STEP_ONE, payload: wizard.steps.stepOne });
            dispatch({ type: WIZRAD_STEP_TWO, payload: wizard.steps.stepTwo });
            dispatch({
              type: WIZARD_STEP_THREE,
              payload: wizard.steps.stepThree,
            });
            dispatch({
              type: WIZARD_STEP_FOUR,
              payload: wizard.steps.stepFour,
            });
            dispatch(wizardLoading(false));
          }
        })
        .catch((_) => {});
    },
  });
};

const loadDataToWizard = (data) => {
  const { step_no, course, modules, repositories, mcq_details } = data;
  const currentStep = step_no - 1;
  var wizard = {
    currentStep,
    steps: {},
  };
  wizard.steps.stepOne = { ...course };
  wizard.steps.stepTwo = modules;
  wizard.steps.stepThree = repositories;
  wizard.steps.stepFour = mcq_details;

  return wizard;
};

export const deleteCourseDraft = (c_id, handleClose) => {
  return actionFactory({
    api: "delete/api/create/" + c_id + "/",
    // actionBase: CREATE_COURSE,
    failureToast: true,
    successToast: "Draft Deleted Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            const id = userSelector(getState()).id;
            reloadDraftCourseDetails(dispatch, id);
            handleClose();
          }
        })
        .catch((_) => {});
    },
  });
};

export const getCandidateTestResult = (
  testId,
  setTestResultData,
  setIsResultLoading
) => {
  return actionFactory({
    api: `get/api/tests/${testId}/result`,
    failureToast: true,
    actionBody: {},
    // successToast: "Test Deleted Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            setTestResultData(resp["data"].data);
            setIsResultLoading(false);
          }
        })
        .catch((_) => {});
    },
  });
};

export const startCandidateTest = (testId, enrollId) => {
  return actionFactory({
    api: `post/api/tests/${testId}/start/`,
    failureToast: true,
    actionBody: {
      body: {
        enroll: enrollId,
      },
    },
    // successToast: "Test Deleted Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: START_TEST,
              payload: resp["data"].data,
            });
            dispatch({ type: CURRENT_MCQ_INDEX, payload: 0 });
          }
        })
        .catch((_) => {});
    },
  });
};

export const submitCandidateTest = (
  testId,
  enrollId,
  details,
  attemptDuration,
  callbackSubmit
) => {
  return actionFactory({
    api: `post/api/tests/${testId}/submit/`,
    failureToast: true,
    actionBody: {
      body: {
        details: details,
        enroll: enrollId,
        attempt_duration: attemptDuration,
      },
    },
    successToast: "Test Submitted Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            window.close();
          }
        })
        .catch((_) => {
          callbackSubmit();
        });
    },
  });
};

export const submitCandidateTestPublic = (
  testId,
  details,
  attemptDuration,
  callbackSubmit,
  email = "",
  name = "",
  setsubmitLoader,
  setisColor
) => {
  return actionFactory({
    api: `post/api/tests/${testId}/submit/public/`,
    failureToast: true,
    actionBody: {
      body: {
        details: details,
        attempt_duration: attemptDuration,
        email: email,
        name: name,
      },
    },
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch(getPublicTestResult(resp["data"].data.id));
          }
        })
        .catch((_) => {
          setisColor(false);
          callbackSubmit();
        });
    },
  });
};

export const getPublicTestResult = (testId) => {
  return actionFactory({
    api: `get/api/polling-jobs/${testId}/status/public/`,
    failureToast: true,
    actionBody: {},
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          if (resp && resp["data"]?.status?.status_type === "SUCCESS") {
            sessionStorage.setItem("isLoading", true);
            if (resp["data"]?.data?.task_status !== "Finished") {
              sessionStorage.removeItem("isFailed");
              if (resp["data"]?.data?.task_status !== "Failed") {
                setTimeout(() => {
                  dispatch(getPublicTestResult(testId));
                }, 2500);
              } else {
                sessionStorage.setItem("isFailed", resp["data"]?.data?.id);
              }
            } else {
              sessionStorage.removeItem("isFailed");
              const PREV_MARKS = sessionStorage.getItem("PREV_MARKS");
              const NEW_MARKS = sessionStorage.getItem("NEW_MARKS");
              const newData = JSON.stringify(resp["data"]?.data);
              if (PREV_MARKS !== null && NEW_MARKS !== null) {
                sessionStorage.setItem("PREV_MARKS", NEW_MARKS);
                sessionStorage.setItem("NEW_MARKS", newData);
              } else if (PREV_MARKS === null) {
                sessionStorage.setItem("PREV_MARKS", newData);
              } else {
                sessionStorage.setItem("NEW_MARKS", newData);
              }
              sessionStorage.setItem("isLoading", false);
            }
          }
        })
        .catch((_) => {});
    },
  });
};

export const retryPublicTest = (testId) => {
  return actionFactory({
    api: `patch/api/polling-jobs/${testId}/retry/public/`,
    failureToast: true,
    actionBody: { id: testId },
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          if (resp && resp["data"]?.status?.status_type === "SUCCESS") {
            dispatch(getPublicTestResult(testId));
          }
        })
        .catch((_) => {});
    },
  });
};

export const getPublicTestEmail = (
  testId,
  emailId,
  setOpen,
  setLoading,
  text_email,
  contact_us
) => {
  return actionFactory({
    api: `post/api/polling-jobs/${testId}/email/`,
    failureToast: true,
    actionBody: {
      body: { email: emailId, content: text_email, contact_us: contact_us },
    },
    successToast: "Email Sent successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise) => {
      respPromise
        .then((resp) => {
          if (resp["data"]?.status?.status_type === "SUCCESS") {
            setOpen(false);
            setLoading(false);
          }
        })
        .catch((_) => {});
    },
  });
};

export const startCandidateTestPublic = (testId) => {
  return actionFactory({
    api: `post/api/tests/${testId}/start/public/`,
    failureToast: true,
    actionBody: {
      body: {
        testId: testId,
      },
    },
    // successToast: "Test Deleted Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: START_TEST,
              payload: resp["data"].data,
            });
            dispatch({ type: CURRENT_MCQ_INDEX, payload: 0 });
          }
        })
        .catch((_) => {});
    },
  });
};

export const setPublicModuleIndex = (index) => ({
  type: SET_PUBLIC_MODULE_INDEX,
  payload: index,
});

export const setSelectedQuizIndex = (index) => ({
  type: SET_SELECTED_QUIZ_INDEX,
  payload: index,
});
