import { Button } from "antd";
import { ReactComponent as PreviewMode } from "../../assets/Preview.svg";
import { ReactComponent as Approve } from "../../assets/approve.svg";
import "./PreviewModeRejectOrPublish.scss";
import { useState } from "react";
import PublishedCourseCourseConfirmModal from "../../features/CourseListFeature/CoursesTabLayout/TabScreen/ApprovalCourseTabScreen/PublishedCourseConfirmModal";
import RejectCourseConfirmModal from "../../features/CourseListFeature/CoursesTabLayout/TabScreen/ApprovalCourseTabScreen/RejectCourseConfirmModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isPreviewSelector } from "../../store/selectors/wizardSelectors";
function PreviewModeRejectOrPublish({ data }) {
  const navigate = useNavigate();
  const [isRejectedModalOpen, setIsRejectedCourseModalOpen] = useState(false);
  const [isPublishedModalOpen, setIsPublishedCourseModalOpen] = useState(false);
  const isPreview = useSelector(isPreviewSelector);
  const handleRejectCourse = () => {
    openRejectCourseConfirmModal();
  };
  const handlePublishedCourse = () => {
    openPublishedCourseConfirmModal();
  };
  const openRejectCourseConfirmModal = () => {
    setIsRejectedCourseModalOpen(true);
  };
  const openPublishedCourseConfirmModal = () => {
    setIsPublishedCourseModalOpen(true);
  };

  const isPreviewRedirection = () => {
    navigate("/courses/all");
  };
  const closeRejectCourseConfirmModal = () => {
    setIsRejectedCourseModalOpen(false);
  };
  const closePublishedCourseConfirmModal = () => {
    setIsPublishedCourseModalOpen(false);
  };
  return (
    <div className="preview-mode-header" title="preview-mode-header">
      {" "}
      <PublishedCourseCourseConfirmModal
        open={isPublishedModalOpen}
        handleClose={closePublishedCourseConfirmModal}
        data={[data[0].course]}
        isPreviewRedirection={isPreview ? isPreviewRedirection : null}
      />
      <RejectCourseConfirmModal
        open={isRejectedModalOpen}
        handleClose={closeRejectCourseConfirmModal}
        data={[data[0].course]}
        isPreviewRedirection={isPreview ? isPreviewRedirection : null}
      />
      <div className="preview-mode-text">
        {" "}
        <PreviewMode title="PreviewMode" />
        Preview Mode
      </div>
      <div>
        <Button
          className="preview-mode-button"
          icon={<Approve title="Approve" />}
          onClick={handlePublishedCourse}
        >
          <span style={{ marginLeft: "7px" }}>Publish</span>
        </Button>
        <Button
          title="Reject"
          className="preview-mode-button"
          style={{ color: "#696969", border: "1px solid #696969" }}
          icon={<FontAwesomeIcon icon={faXmark} style={{ color: "#696969" }} />}
          onClick={handleRejectCourse}
        >
          <span style={{ marginLeft: "7px" }}> Reject</span>
        </Button>
      </div>
    </div>
  );
}
export default PreviewModeRejectOrPublish;
