import "./ApprovalCourseTabScreen.scss";
import PendingCourseListTable from "./PendingCourseListTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearInreviewCourses,
  loadInReviewCourses,
} from "../../../../../store/actions/courseAction";
import { inReviewCoursesSelector } from "../../../../../store/selectors/coursesSelectors";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import Spinner from "../../../../../components/Spinner/Spinner";

function ApprovalCourseTabScreen() {
  // eslint-disable-next-line no-unused-vars
  const coursesList = useSelector(inReviewCoursesSelector);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const loadCoursesList = () => {
    const filters = {};

    for (const [key, value] of searchParams.entries()) {
      filters[key] = value;
    }
    if (Object.keys(filters).length === 0) {
      dispatch(loadInReviewCourses());
    }
  };

  useEffect(() => {
    loadCoursesList();
    return () => {
      dispatch(clearInreviewCourses());
    };
  }, []);

  useEffect(() => {
    if (coursesList !== null) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [coursesList]);

  return (
    <div className="approvalCourseTabScreen-container">
      {isLoading ? (
        <div style={{ height: "450px", alignItems: "center" }}>
          <Spinner />
        </div>
      ) : (
        <PendingCourseListTable key={coursesList} data={coursesList} />
      )}
    </div>
  );
}

export default ApprovalCourseTabScreen;
