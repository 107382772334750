import { useSelector } from "react-redux";
import SearchBar from "../../../../../components/SearchBar/SearchBar";
import EnrolledTabScreenTable from "./EnrollTabScreenTable";
import {
  updateLimit,
  updateOffset,
} from "../../../../../store/actions/listParamsAction";
import "./EnrolledTabScreen.scss";
import { enrolledCourse } from "../../../../../store/selectors/coursesSelectors";
import { useDispatch } from "react-redux";
import { loadEnrolledCourses } from "../../../../../store/actions/courseAction";
import {
  getListLimit,
  getListOffset,
} from "../../../../../store/selectors/listParamsSelectors";
import { useEffect, useState } from "react";
import { CLEAR_ENROLLED_COURSE } from "../../../../../store/actiontypes";
import Spinner from "../../../../../components/Spinner/Spinner";
import CreateGraphSummaryModal from "./CreateGraphSummaryModal/CreateGraphSummaryModal";
import { searchPropsForProfileEnrolledTab } from "../../../../../constants/SearchBarProps";
import Pagination from "../../../../../components/Pagination/Pagination";
import { ENROLL_LIST_ID } from "../../../../../constants";
import { useSearchParams } from "react-router-dom";
import UserUnenrollmentConfirmModal from "../../../../AdminUserList/UserUnenrollmentConfirmModal";

const EnrolledTabScreen = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [enrollId, setEnrollId] = useState(false);
  const urlId = location.hash.split("/")[2];
  const searchListParams = useSelector(
    (state) => state.listParams[ENROLL_LIST_ID]
  );
  const enrolledCourses = useSelector(enrolledCourse);
  // Getting query parameters from the URL
  const [searchParams] = useSearchParams();

  const totalCount = enrolledCourses?.count;

  const defaultValue = useSelector((state) =>
    getListLimit(state, ENROLL_LIST_ID)
  );
  const offSetValue = useSelector((state) =>
    getListOffset(state, ENROLL_LIST_ID)
  );
  // Function to update the 'defaultValue' when limit is changed
  const changeDefaultValue = (value) => {
    dispatch(
      updateLimit({
        id: ENROLL_LIST_ID,
        limit: value[0].label,
        offset: 0,
      })
    );
  };
  // Function to update the 'offSetValue' when offset is changed
  const changeOffsetValue = (value) => {
    dispatch(
      updateOffset({
        id: ENROLL_LIST_ID,
        offset: value,
      })
    );
    dispatch(
      loadEnrolledCourses(urlId, {
        ...searchListParams,
        limit: defaultValue,
        offset: offSetValue,
      })
    );
  };

  // Function to handle search
  const handleSearch = (params) => {
    if (defaultValue) {
      dispatch(
        updateOffset({
          id: ENROLL_LIST_ID,
          offset: 0,
        })
      );
      searchPropsForProfileEnrolledTab.onSearch(
        {
          ...params,
          limit: defaultValue,
        },
        urlId,
        dispatch
      );
      setIsLoading(true);
    }
  };

  // Function to load courses based on search parameters
  const loadEnrolledCourse = () => {
    const filters = {};

    for (const [key, value] of searchParams.entries()) {
      filters[key] = value;
    }

    if (Object.keys(filters).length === 0) {
      dispatch(
        loadEnrolledCourses(urlId, {
          ...searchListParams,
          limit: defaultValue,
          offset: offSetValue,
        })
      );
    } else {
      handleSearch(filters);
    }
  };

  // Initial setup when the component mounts
  useEffect(() => {
    dispatch(
      updateLimit({
        id: ENROLL_LIST_ID,
        limit: 10,
        offset: 0,
      })
    );
  }, []);
  // Effect to load courses when 'defaultValue' changes
  useEffect(() => {
    if (defaultValue !== undefined && offSetValue !== undefined) {
      loadEnrolledCourse();
    }
    return () => {
      if (location.href.includes("users")) {
        dispatch({ type: CLEAR_ENROLLED_COURSE });
      }
    };
  }, [defaultValue]);

  // Effect to handle loading indicator
  useEffect(() => {
    if (enrolledCourses !== null) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [enrolledCourses]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const unEnrollCourseCallback = (respPromise, dispatch, getState) => {
    setLoading(true);
    respPromise
      .then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          loadEnrolledCourse();
          setIsModalOpen(false);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const [data, setData] = useState();
  const unEnroll = (enroll_id, user_name, course__name) => {
    setData({
      enroll_id: enroll_id,
      user_name: user_name,
      course_name: course__name,
    });
    setIsModalOpen(true);
  };
  const showGraphModal = (id) => {
    setEnrollId(id);
    setIsOpen(true);
  };
  const closeGraphModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <UserUnenrollmentConfirmModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        data={data}
        onsuccesscallback={unEnrollCourseCallback}
        loading={loading}
        setLoading={setLoading}
        setIsModalOpen={setIsModalOpen}
      />
      <CreateGraphSummaryModal
        isOpen={isOpen}
        enrollId={enrollId}
        closeGraphModal={closeGraphModal}
      />
      <div className="table-enroll">
        <div className="enrollTab-searchBar-container">
          <SearchBar
            filterValues={searchPropsForProfileEnrolledTab.filterValues}
            onSearch={(params, _) => {
              handleSearch(params);
            }}
          />
        </div>
        {isLoading ? (
          <div className="spinner-enrollpage">
            <Spinner />
          </div>
        ) : (
          <>
            <EnrolledTabScreenTable
              showGraphModal={showGraphModal}
              closeGraphModal={closeGraphModal}
              data={enrolledCourses?.results}
              unEnrollClickhandler={unEnroll}
            />
            <Pagination
              defaultValue={defaultValue}
              totalCount={totalCount}
              onClickHandlerDefaultValue={changeDefaultValue}
              offSetValue={offSetValue}
              onClickHandlerOffSetValue={changeOffsetValue}
              userList={enrolledCourses}
            />
          </>
        )}
      </div>
    </>
  );
};

export default EnrolledTabScreen;
