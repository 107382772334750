import { Tabs } from "antd";
import "./McqTabsDisplay.scss";
import { TesttabConfigurations } from "../../../../constants/McqTest";
import { useNavigate } from "react-router-dom";
const { TabPane } = Tabs;

const UserProfileTabLayout = ({
  activeTab,
  setActiveTab,
  createdMcqTestId,
}) => {
  // function to change the tab
  const course_id = location.hash.split("/")[2];
  const navigate = useNavigate();

  const handleTabChange = (key) => {
    setActiveTab(key);
    navigate(`/courses/${course_id}/test/${createdMcqTestId}/${key}/`);
  };

  return (
    <div className="McqTabLayout-container">
      <Tabs
        defaultActiveKey="details"
        activeKey={activeTab}
        className="tabs-container"
        onChange={handleTabChange}
      >
        {TesttabConfigurations.map((tab) => {
          if (tab.shouldRender) {
            return (
              <TabPane
                key={tab.key}
                tab={
                  <div className="container1">
                    <span>
                      {tab.icon}
                      {tab.label}
                    </span>
                  </div>
                }
                className={
                  activeTab === tab.key ? "active-tab" : "inactive-tab"
                }
                forceRender={true}
                disabled={tab.key === "manager" && !createdMcqTestId}
              >
                {activeTab === tab.key && tab.content}
              </TabPane>
            );
          } else {
            return null;
          }
        })}
      </Tabs>
    </div>
  );
};

export default UserProfileTabLayout;
