import { Button } from "antd";
import { ReactComponent as Link } from "../../assets/link.svg";
import { CURRENT_MODULE } from "../../store/actiontypes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { publicModuleIndexSelector } from "../../store/selectors/coursesSelectors";
import { setPublicModuleIndex } from "../../store/actions/courseAction";

function AboutModuleTest(props) {
  const { module, course } = props;
  const dispatch = useDispatch();
  const publicModuleIndex = useSelector(publicModuleIndexSelector);

  const combinedDetails = [
    ...(course[0]?.module_details || []),
    ...(course[0]?.mcq_details || []),
  ];

  const handleModuleChange = (direction) => {
    const newIndex = publicModuleIndex + direction;

    if (newIndex >= 0 && newIndex < combinedDetails.length) {
      dispatch(setPublicModuleIndex(newIndex));
      dispatch({
        type: CURRENT_MODULE,
        payload: combinedDetails[newIndex],
      });
    }
  };

  return (
    <>
      <div className="about-module-header" data-testid="about-module">
        <div
          className="about-module-module-name"
          data-testid="about-module-name"
        >
          {module?.module?.module_name}
        </div>
      </div>
      <div
        className="about-module-subtitle"
        data-testid="about-module-subtitle"
      >
        {module?.module?.module_description}
      </div>
      <div className="module-study-material-header">
        Study Material{" "}
        <div>
          <a
            href={module?.module?.module_content}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="module-content"
          >
            <Link title="contentLink" />
          </a>
        </div>
      </div>

      <div className="aboutModule-buttons">
        <Button
          className={`aboutModule-button ${
            publicModuleIndex === 0 && "disabled"
          }`}
          onClick={() => handleModuleChange(-1)}
          disabled={publicModuleIndex === 0}
        >
          Previous
        </Button>
        <Button
          className={`aboutModule-button ${
            publicModuleIndex === combinedDetails.length - 1 && "disabled"
          }`}
          onClick={() => handleModuleChange(1)}
          disabled={publicModuleIndex === combinedDetails.length - 1}
        >
          Next
        </Button>
      </div>
    </>
  );
}

export default AboutModuleTest;
