/* eslint-disable quotes */
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rate } from "antd";
import moment from "moment";

import "./UserReviewCard.scss";

const UserReviewCard = ({ rating, bordered }) => {
  return (
    <div
      className={`individual-div ${bordered ? "bordered" : ""}`}
      data-testid="user-review-card"
    >
      <div className="top">
        <div className="name-icon" data-testid="user-review-card-name">
          <div className="icon-review">
            <FontAwesomeIcon size="sm" icon={faUser} color="#121212" />
          </div>
          {rating.user.user_name}
        </div>
        <Rate value={rating.rating} disabled className="rating-stars-review" />
      </div>
      <div className="middle" data-testid="user-review-card-comment">
        {rating.comment}
      </div>
      <div className="bottom" data-testid="user-review-card-updated_at">
        {moment(rating.updated_at).format("MMMM DD, YYYY")}
        <div className={`${bordered ? "" : "line"}`}></div>
      </div>
    </div>
  );
};

export default UserReviewCard;
