import "./PageNotFound.scss";

const PageNotFoundComp = () => {
  return (
    <div className="Pagenotfound-container">
      <h1>Page Not Found </h1>
    </div>
  );
};

export default PageNotFoundComp;
