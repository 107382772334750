const adminSelector = (state) => {
  return state.admin;
};

export const AllUserSelector = (state) => {
  return adminSelector(state).users;
};

export const SelectedUserSelector = (state) => {
  return adminSelector(state).selectedUser;
};
