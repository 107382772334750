import { useEffect, useState } from "react";
import MenuDropDown from "../../../../../components/MenuDropDown/MenuDropDown";
import "./Statistics.scss";
import StatisticsTabGraph from "./StatisticsTabGraph";
import {
  loadAvgCompletionTime,
  loadCandidatesEnrolled,
} from "../../../../../store/actions/courseAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  avgCompletionTimeSelector,
  candidatesEnrolledSelector,
  selectedCourseSelector,
} from "../../../../../store/selectors/coursesSelectors";
import Spinner from "../../../../../components/Spinner/Spinner";

const Statistics = () => {
  const dispatch = useDispatch();
  const candidatesEnrolledData = useSelector(candidatesEnrolledSelector);
  const avgCompletionTimeData = useSelector(avgCompletionTimeSelector);
  const [isLoading, setIsLoading] = useState(true);
  const [timeline, setTimeLine] = useState("MONTHLY");
  const selectedCourseDetails = useSelector(selectedCourseSelector);
  const { id } = selectedCourseDetails[0]?.course || {};

  const { total_candidates_enrolled, enrollments } =
    candidatesEnrolledData || {};

  const { total_avg_completion_time, avg_time_taken } =
    avgCompletionTimeData || {};

  const loadCandidatesEnrolledData = (params) => {
    dispatch(loadCandidatesEnrolled(id, params));
  };

  const loadAvgCompletionTimeData = (params) => {
    dispatch(loadAvgCompletionTime(id, params));
  };

  useEffect(() => {
    if (enrollments && avg_time_taken) {
      setIsLoading(false);
    }
  }, [candidatesEnrolledData, avgCompletionTimeData]);

  const onClickHandler = (selectedValue) => {
    const timeline = selectedValue[0]?.value;
    setTimeLine(timeline);
  };

  useEffect(() => {
    loadAvgCompletionTimeData(timeline);
    loadCandidatesEnrolledData(timeline);
  }, [timeline]);

  const option = [
    {
      label: "Monthly",
      value: "MONTHLY",
    },
    {
      label: "Weekly",
      value: "WEEKLY",
    },
    {
      label: "Yearly",
      value: "YEARLY",
    },
  ];

  const getDefaultValue = () => {
    let selected = option.filter((o) => o.value === "MONTHLY")[0];
    return selected;
  };
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className="courseStatisticsTabScreen-container">
        <div className="statistics-info-container">
          <div className="statistics-heading">Course Statistics</div>
          <div className="statistics-details-container">
            <div className="statistics-detail-items">
              <div className="statistics-detail-item">
                <div>CANDIDATES ENROLLED</div>
                <div>{total_candidates_enrolled} enrolled</div>
              </div>
              <div className="statistics-detail-item">
                <div>AVERAGE COMPLETION TIME</div>
                <div>{total_avg_completion_time} weeks</div>
              </div>
            </div>
            <MenuDropDown
              options={option}
              defaultSelected={() => getDefaultValue()}
              onClickHandle={(selectedValue) => onClickHandler(selectedValue)}
            />
          </div>
        </div>
        {enrollments.length >= 5 || avg_time_taken.length >= 5 ? (
          <StatisticsTabGraph
            candidatesEnrolledData={enrollments}
            avgCompletionTimeData={avg_time_taken}
          />
        ) : (
          <div className="noData-container">Not enough data to display</div>
        )}
      </div>
    </>
  );
};

export default Statistics;
