/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { actionFactory } from "../../libs/actionFactory";
import { ALL_USERS, LOGIN_FAIL, SELECTED_USER } from "../actiontypes/index";

export const getAllUserDetails = (params = {}, callback) => {
  return actionFactory({
    api: "get/api/users/",
    actionBody: {
      params: { ...params },
    },
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: ALL_USERS,
              payload: resp["data"].data,
            });
          }
          if (callback) {
            callback();
          }
        })
        .catch((err) => {
          dispatch({
            type: LOGIN_FAIL,
          });
        });
    },
  });
};

export const updateUser = (id, user, successCallback) => {
  return actionFactory({
    api: `patch/api/users/${id}/`,
    actionBody: {
      body: { ...user },
    },
    failureToast: true,
    successToast: `${user.user_name} has been successfully updated.`,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch(getAllUserDetails());
            successCallback();
          }
        })
        .catch((_err) => {});
    },
  });
};

export const archiveUser = (id, user, successCallback) => {
  return actionFactory({
    api: `delete/api/users/${id}/`,
    actionBody: {
      body: user,
    },
    failureToast: true,
    successToast: `User ${user.user_name} has been successfully archived.`,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch(getAllUserDetails());
            successCallback();
          }
        })
        .catch((_err) => {});
    },
  });
};

export const updateEmployeeId = (id, userData, successCallback) => {
  return actionFactory({
    api: `patch/api/users/${id}/`,
    actionBody: {
      body: userData,
    },
    failureToast: true,
    successToast: `Details successfully updated.`,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            successCallback();
          }
        })
        .catch((_err) => {});
    },
  });
};

export const selectedUserDetails = (id) => {
  return actionFactory({
    api: `get/api/users/${id}`,
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({ type: SELECTED_USER, payload: resp["data"].data });
          }
        })
        .catch((_err) => {});
    },
  });
};
export const loadAuthoredEnrolledCount = (id, setData) => {
  return actionFactory({
    api: `get/api/users/${id}/authored_enrolled_count/`,
    actionBody: {},
    failureToast: true,
    actionBase: {},
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          setData(resp["data"].data);
        })
        .catch((_) => {});
    },
  });
};

export const exportData = (onsuccesscallback) => {
  return actionFactory({
    api: "post/api/tests/export/",
    actionBody: {},
    failureToast: true,
    successToast: "Export Successfull",
    blobResponseType: true,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp?.status === 200) {
            const file = new Blob([resp.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            // Create a URL for the Blob object
            const fileUrl = window.URL.createObjectURL(file);
            // Create an anchor element to trigger the download
            const downloadLink = document.createElement("a");
            downloadLink.href = fileUrl;
            downloadLink.setAttribute("download", "Mcq_Details_Sheet.xlsx"); // Set the filename here
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            onsuccesscallback();
          }
        })
        .catch((_) => {
          onsuccesscallback();
        });
    },
  });
};
