import { actionFactory } from "../../libs/actionFactory";

export const loadSearchFilterValues = (context, filter, callback) => {
  return actionFactory({
    api: "get/api/search/",
    actionBody: {
      params: {
        context: context,
        filter: filter,
      },
    },
    failureToast: true,
    callback: (respPromise, _dispatch, _getState) => {
      callback(respPromise);
    },
  });
};
