import "./CandidateTable.scss";
import { MoreOutlined } from "@ant-design/icons";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "antd";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CreateGraphSummaryModal from "../../features/UserProfileFeature/UserProfileTabLayout/TabScreen/EnrolledTabScreen/CreateGraphSummaryModal/CreateGraphSummaryModal";
import { Tooltip } from "antd";

function CandidateListItem({ data, unEnrollClickhandler }) {
  const {
    user_name,
    completion_percentage,
    total_module_count,
    created_at,
    completed,
    time_taken,
    enroll_id,
    user_id,
    course_name,
    email,
    best_score,
  } = data;
  const items = [
    {
      label: (
        <div
          className={
            completion_percentage > 0
              ? "candidate-disabled-items"
              : "dropdown-items"
          }
        >
          Unenroll
        </div>
      ),
      key: "UnEnroll",
      disabled: completion_percentage > 0 ? true : false,
    },
  ];
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  function viewCandidate() {
    navigate(`/users/${user_id}/authored`);
  }
  const handleMenuClick = (e) => {
    if (e.key === "UnEnroll") {
      unEnrollClickhandler(enroll_id, user_name, course_name);
    }
  };
  let progressBarColor = "#FFB951";
  if (completion_percentage < 100) {
    progressBarColor = "#FFB951";
  } else {
    progressBarColor = "#61D800";
  }
  const menuProps = {
    items,
    onClick: (e) => {
      return handleMenuClick(e);
    },
  };
  const showGraphModal = () => {
    setIsOpen(true);
  };
  const closeGraphModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="statistics_page">
      <CreateGraphSummaryModal
        isOpen={isOpen}
        enrollId={enroll_id}
        closeGraphModal={closeGraphModal}
      />
      <div className="userList-containers" data-testid="userList-container">
        <div className="statisticscourseList-title">
          <Tooltip title={email} overlayClassName="custom-tooltip">
            <p onClick={() => viewCandidate()}>{user_name}</p>
          </Tooltip>
        </div>
        <div className="statisticscourseList-title">
          <div className="status">
            <div className="status-details">
              <div>
                {" "}
                {completion_percentage === 100 ? (
                  "Completed"
                ) : (
                  <div className="module_details">
                    <div className="completed_modules">
                      {Math.round(
                        total_module_count * (completion_percentage / 100)
                      )}
                    </div>
                    <div className="total_modules">
                      /{total_module_count} modules
                    </div>
                  </div>
                )}
              </div>

              <div className="course-progress">{completion_percentage}%</div>
            </div>
            <div className="progressBar">
              <div
                className="progressBarValue"
                data-testid="progressBarValue"
                style={{
                  maxWidth: `${completion_percentage}%`,
                  backgroundColor: `${progressBarColor}`,
                }}
              ></div>
              {/* <div
                className="progressBarExpected"
                style={{ width: `${80}%` }}
              ></div> */}
            </div>
          </div>
        </div>
        {/* New Best Score Column */}
        <div className="statisticscourseList-title">
          {best_score === 0 ? "-" : best_score}
        </div>
        <div className="statisticscourseList-title">
          {moment(created_at).format("MMM D, YYYY, h:mm:ss A")}
        </div>
        <div className="statisticscourseList-title">
          {completed ? time_taken + " Weeks" : "-"}
        </div>
        <div className="statisticscourseList-title">
          <div className="actions-div">
            <FontAwesomeIcon
              icon={faChartArea}
              onClick={showGraphModal}
              data-testid="chart-icon"
            />
            <Dropdown
              menu={menuProps}
              trigger={["click"]}
              placement="bottomRight"
            >
              <MoreOutlined
                style={{
                  fontSize: "16px",
                  color: "#000",
                }}
              />
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CandidateListItem;
