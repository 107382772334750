import { useSelector } from "react-redux";
import {
  wizardLoaderSelector,
  wizardStepsSelector,
} from "../../../../store/selectors/wizardSelectors";
import Spinner from "../../../../components/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./McqStep.scss";
import { useState } from "react";
import DisplayInfoMessage from "../../../../components/DisplayInfoMessage/DisplayInfoMessage";
import McqEditSection from "./McqEditSection";

const McqStep = () => {
  const isLoading = useSelector(wizardLoaderSelector);
  const [showMessage, setShowMessage] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const wizardSteps = useSelector(wizardStepsSelector);

  const handleAlertOpen = () => {
    setShowMessage(true);
  };
  const handleAlertClose = () => {
    setShowMessage(false);
  };
  const handleAddTest = () => {
    setIsCreating(true);
    window.open(`#/courses/${wizardSteps.stepOne.id}/create-mcq`, "_blank");
  };
  return isLoading ? (
    <div className="mcqCreationStep-Loader">
      <Spinner color={"white"} text={"Creating Mcq"} />
    </div>
  ) : (
    <div className="mcqCreationStep-container">
      <div className="mcqCreationStep-header">
        <h3>MCQ Test</h3>
        <div className="">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            size="lg"
            onClick={handleAlertOpen}
          />
        </div>
      </div>
      {showMessage && (
        <DisplayInfoMessage
          msg="Click Create Test Button to get started, you will be redirected to test creation page."
          type="info"
          onClick={handleAlertClose}
          style={{
            padding: "16px 22px",
            fontSize: "14px",
            marginTop: "12px",
          }}
        />
      )}
      {!wizardSteps?.stepFour ||
      Object.keys(wizardSteps).length == 0 ||
      wizardSteps?.stepFour?.length == 0 ? (
        <>
          {!isCreating && (
            <div className="mcqCreationStep-message">No Tests added!</div>
          )}
          <div className="mcqCreationStep-Footer">
            <button className="mcqCreationStep-Cancel" onClick={handleAddTest}>
              Create Test
            </button>
          </div>
        </>
      ) : (
        wizardSteps?.stepFour?.map((elem) => {
          return (
            <McqEditSection
              key={elem.id}
              elem={elem}
              course={wizardSteps?.stepOne}
            />
          );
        })
      )}
    </div>
  );
};

export default McqStep;
