// import React from "react";
import { Checkbox, List } from "antd";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import "./QuestionList.scss";
import QuestionListHeader from "./QuestionListHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { QuestionListItem } from "./QuestionListItem";
import Pagination from "../../../../components/Pagination/Pagination";
import { useSelector } from "react-redux";
import { currentEditingTestDetails } from "../../../../store/selectors/wizardSelectors";
import { useSearchParams } from "react-router-dom";
import { QUESTION_LIST_ID } from "../../../../constants";
import {
  getListLimit,
  getListOffset,
} from "../../../../store/selectors/listParamsSelectors";
import { useDispatch } from "react-redux";
import {
  updateLimit,
  updateOffset,
} from "../../../../store/actions/listParamsAction";
import { getMcqs } from "../../../../store/actions/wizardActions";
import { useEffect, useState } from "react";
import { QuestionListItemHeader } from "./QuestionListItemHeader";
import Spinner from "../../../../components/Spinner/Spinner";
import QuestionDeleteConfirmModal from "./QuestionDeleteConfirmModal";

const CustomEmptyContent = () => (
  <div className="questionList-container-empty">No results found</div>
);
const QuestionsList = ({ questions, reorderSectionStateChange }) => {
  const [searchParams] = useSearchParams();
  const mcqListId = useSelector(currentEditingTestDetails);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();
  const searchListParams = useSelector(
    (state) => state.listParams[QUESTION_LIST_ID]
  );
  const [checkedArray, setCheckedArray] = useState([]);
  const defaultValue = useSelector((state) =>
    getListLimit(state, QUESTION_LIST_ID)
  );
  const offSetValue = useSelector((state) =>
    getListOffset(state, QUESTION_LIST_ID)
  );
  useEffect(() => {
    if (questions !== null) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setIsLoading(false);
  }, [questions]);
  const totalCount = questions?.count;
  const changeDefaultValue = (value) => {
    dispatch(
      updateLimit({
        id: QUESTION_LIST_ID,
        limit: value[0].label,
        offset: 0,
      })
    );
  };
  const changeOffsetValue = (value) => {
    dispatch(
      updateOffset({
        id: QUESTION_LIST_ID,
        offset: value,
      })
    );
    dispatch(
      getMcqs(mcqListId.id, {
        ...searchListParams,
        limit: defaultValue,
        offset: offSetValue,
      })
    );
  };
  const handleSearch = (params) => {
    if (defaultValue) {
      dispatch(
        updateOffset({
          id: QUESTION_LIST_ID,
          offset: 0,
        })
      );
      dispatch(
        getMcqs(mcqListId.id, {
          ...params,
        })
      );
    }
  };
  const onDelete = (question_id) => {
    setIsModalOpen(true);
    setData({
      test: mcqListId.id,
      questions: question_id,
    });
  };
  const loadAllQuestions = () => {
    const filters = {};
    for (const [key, value] of searchParams.entries()) {
      filters[key] = value;
    }
    if (Object.keys(filters).length === 0) {
      dispatch(
        getMcqs(mcqListId.id, {
          ...searchListParams,
          limit: defaultValue,
          offset: offSetValue,
        })
      );
    } else {
      handleSearch(filters);
    }
  };
  useEffect(() => {
    if (defaultValue !== undefined && offSetValue !== undefined) {
      loadAllQuestions();
    }
  }, [defaultValue]);
  useEffect(() => {
    dispatch(
      updateLimit({
        id: QUESTION_LIST_ID,
        limit: 10,
        offset: 0,
      })
    );
  }, []);
  // eslint-disable-next-line no-unused-vars
  const successCallback = (respPromise, dispatch, getState) => {
    setIsLoading(true);
    respPromise
      .then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          dispatch(getMcqs(mcqListId.id), setIsLoading);
          const updatedCheckedArray = checkedArray.filter(
            (id) => !data.questions.includes(id)
          );
          setCheckedArray(updatedCheckedArray);

          setIsModalOpen(false);
          ("");
        }
      })
      .catch(() => {
        setIsModalOpen(false);
        setIsLoading(false);
      });
  };
  const onChange = (e) => {
    e.target.checked
      ? setCheckedArray([...checkedArray, e.target.value])
      : setCheckedArray(checkedArray.filter((item) => item !== e.target.value));
  };
  const [checkAll, setCheckAll] = useState(false);
  useEffect(() => {
    setCheckAll(checkedArray.length === questions?.total_count);
  }, [checkedArray]);
  const onCheckAllChange = (e) => {
    setCheckedArray(
      e.target.checked ? questions.results.map((item) => item.question_id) : []
    );

    setCheckAll(e.target.checked);
  };

  return !isLoading ? (
    <>
      <QuestionDeleteConfirmModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        data={data}
        onsuccesscallback={successCallback}
        loading={isLoading}
        setLoading={setIsLoading}
        setIsModalOpen={setIsModalOpen}
      />
      <div className="questionList-container">
        <QuestionListHeader onStateChange={reorderSectionStateChange} />
        <div className="questionList-searchBar">
          <div className="questionlist-checkbox">
            <Checkbox
              className="question-list-checkbox"
              onChange={onCheckAllChange}
              checked={checkAll}
            />
            <span className="question-list-checkbox-text">
              {checkedArray.length === 0
                ? `Questions (${questions.results.length}) `
                : `${checkedArray.length} Selected`}
            </span>
            {checkedArray.length === 0 ? null : (
              <>
                {" "}
                <span className="question-list-checkbox-separator">|</span>
                <span
                  className="question-list-delete-button"
                  onClick={() => onDelete(checkedArray)}
                >
                  <FontAwesomeIcon icon={faTrashCan} color="black" />
                  <span className="question-list-delete-button-text">
                    Delete
                  </span>
                </span>
              </>
            )}
          </div>
          <div className="questionList-searchbar-container">
            <SearchBar
              onSearch={(params, _) => {
                handleSearch(params);
              }}
            />
          </div>
        </div>

        <List
          itemLayout="horizontal"
          dataSource={questions.results}
          className="questionList-items"
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Checkbox
                    value={item.question_id}
                    onChange={(e) => onChange(e)}
                    checked={checkedArray.includes(item.question_id)}
                  />
                }
                title={
                  <QuestionListItemHeader
                    header={item}
                    index={index}
                    onDelete={onDelete}
                  />
                }
                description={
                  <QuestionListItem
                    index={index}
                    question={item}
                    options={item.options}
                    disabled={true}
                    givingTest={false}
                  />
                }
              />
            </List.Item>
          )}
          locale={{ emptyText: <CustomEmptyContent /> }}
        />
        <div className="questionList-pagination">
          <Pagination
            totalCount={totalCount}
            userList={questions}
            defaultValue={defaultValue}
            offSetValue={offSetValue}
            onClickHandlerDefaultValue={changeDefaultValue}
            onClickHandlerOffSetValue={changeOffsetValue}
          />
        </div>
      </div>
    </>
  ) : (
    <Spinner />
  );
};

export default QuestionsList;
