import { Field, Form, Formik } from "formik";
import { validateTokenAction } from "../../store/actions/authActions";
import { useDispatch } from "react-redux";
import { StyledInputField } from "../../components/StyledFields/StyledInputField";
import "./LdapContainer.scss";
import { Button } from "antd";
import { useState } from "react";

const LdapContainer = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const handleError = () => setSubmitting(false);
  return (
    <Formik
      initialValues={{ username: "", password: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.username) {
          errors.username = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
        ) {
          errors.username = "Invalid Email Address";
        } else if (!values.password) {
          errors.password = "Password is required";
        }
        return errors;
      }}
      onSubmit={(values) => {
        dispatch(validateTokenAction(values, handleError));
        setSubmitting(true);
      }}
    >
      {({ setFieldValue, setFieldTouched, isValid, dirty }) => (
        <Form className="ldap-form">
          <Field
            label="Email Id"
            type="email"
            name="username"
            data-testid="email"
            component={StyledInputField}
            placeholder="Enter Your Email Id"
            onChange={(e) => {
              setFieldTouched("username", true, true);
              setFieldValue("username", e.target.value);
            }}
          />
          <Field
            label="Password"
            type="password"
            name="password"
            data-testid="password"
            component={StyledInputField}
            placeholder="Enter Your Password"
            onChange={(e) => {
              setFieldTouched("password", true, true);
              setFieldValue("password", e.target.value);
            }}
          />
          <Button
            type="primary"
            size="large"
            block="true"
            loading={isSubmitting}
            htmlType="submit"
            disabled={!isValid || !dirty}
            className="sign-in-button"
            data-testid="sign-in-button"
          >
            Sign In
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LdapContainer;
