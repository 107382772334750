import { useState } from "react";
import StyledModal from "../../../../components/StyledModal/StyledModal";
import Spinner from "../../../../components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { deleteTestDraftFromWindow } from "../../../../store/actions/wizardActions";

function DeleteConfirmTest({ open, handleClose, data }) {
  const dispatch = useDispatch();
  const handleOk = () => {
    setIsLoading(true);
    dispatch(deleteTestDraftFromWindow(data.id, handleClose));
  };
  const [isLoading, setIsLoading] = useState(false);
  return (
    <StyledModal
      className={"draft-delete-modal"}
      centred
      title={"Delete Test"}
      visible={open}
      onCancel={handleClose}
      onOk={handleOk}
      closable={true}
      footer={isLoading ? null : undefined}
    >
      <div
        style={{
          marginTop: "30px",
          marginBottom: isLoading ? "20px" : "",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        {isLoading ? (
          <Spinner text={"Deleting Test..."} />
        ) : (
          `Do you want to delete "${data?.test_name}"?`
        )}
      </div>
    </StyledModal>
  );
}

export default DeleteConfirmTest;
