import { MoreOutlined } from "@ant-design/icons";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function EnrolledTabScreenTableListItem({
  data,
  unEnrollClickhandler,
  showGraphModal,
}) {
  const { enroll_details, courseProgress, time_taken, completed, bestScore } =
    data;
  const items = [
    {
      label: (
        <div
          data-testid="UnEnroll"
          className={
            courseProgress > 0 ? "enrolled-disabled-items" : "dropdown-items"
          }
        >
          Unenroll
        </div>
      ),
      key: "UnEnroll",
      disabled: courseProgress > 0 ? true : false,
    },
  ];
  const navigate = useNavigate();
  let progressBarColor = "#FFB951"; // Default color
  const handleMenuClick = (e) => {
    if (e.key === "UnEnroll")
      unEnrollClickhandler(
        enroll_details[0].id,
        enroll_details[0].user.user_name,
        enroll_details[0].course.course_name
      );
  };
  const viewCourse = () => {
    const id = enroll_details[0].course.id;
    navigate(`/courses/${id}/content/`);
  };
  if (courseProgress < 100) {
    progressBarColor = "#FFB951";
  } else {
    progressBarColor = "#61D800";
  }
  const menuProps = {
    items,
    onClick: (e) => {
      return handleMenuClick(e);
    },
  };
  return (
    <>
      <div className="userList-containers">
        <div className="courseList-title">
          <p onClick={() => viewCourse()}>
            {enroll_details[0].course.course_name}
          </p>
        </div>
        <div className="courseList-title">
          <div className="status">
            <div className="status-details">
              <div>
                {" "}
                {courseProgress === 100 ? (
                  "Completed"
                ) : (
                  <div className="module_details">
                    <div className="completed_modules">
                      {Math.round(
                        (enroll_details[0]?.modules?.length +
                          enroll_details[0]?.mcqs?.length) *
                          (courseProgress / 100)
                      )}
                    </div>
                    <div className="total_modules">
                      /
                      {enroll_details[0]?.modules?.length +
                        enroll_details[0]?.mcqs?.length}{" "}
                      sections
                    </div>
                  </div>
                )}
              </div>

              <div className="course-progress">{courseProgress}%</div>
            </div>
            <div className="progressBar">
              <div
                className="progressBarValue"
                style={{
                  maxWidth: `${courseProgress}%`,
                  backgroundColor: `${progressBarColor}`,
                }}
              ></div>
              {/* <div
                className="progressBarExpected"
                style={{ width: `${80}%` }}
              ></div> */}
            </div>
          </div>
        </div>
        <div className="best-score"> {bestScore === 0 ? "-" : bestScore}</div>{" "}
        {/* Updated class name */}
        <div className="courseList-title">
          {moment(enroll_details[0].created_at).format(
            "MMM D, YYYY, h:mm:ss A"
          )}
        </div>
        <div className="courseList-title">
          {completed ? time_taken + " weeks" : "-"}
        </div>
        <div className="courseList-title">
          <div className="actions-div">
            <FontAwesomeIcon
              icon={faChartArea}
              data-testid="chart-area"
              onClick={() => {
                showGraphModal(enroll_details[0].id);
              }}
            />
            <Dropdown
              menu={menuProps}
              trigger={["click"]}
              placement="bottomRight"
            >
              <MoreOutlined
                style={{
                  fontSize: "16px",
                  color: "#000",
                }}
              />
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}
export default EnrolledTabScreenTableListItem;
