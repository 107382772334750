/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import StyledModal from "../../components/StyledModal/StyledModal";
import { WarningFilled } from "@ant-design/icons";
import "./BrowserChangeModal.scss";
const BrowserChangeModal = ({ open, setOpen, countTabChange }) => {
  // eslint-disable-next-line no-unused-vars
  const handleSubmit = () => {
    setOpen(false);
  };

  return (
    <StyledModal
      title={"Warning!"}
      centered
      open={open}
      onOk={() => handleSubmit()}
      closable={false}
      className="browser-change-modal"
      okText={"Continue with test"}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <div className="browsermodaldiv">
        <div className="browsermodal-header ">
          A browser tab change was detected.
        </div>
        <div className="browsermodaltext">
          You cannot change tabs for the duration of this test.
        </div>
        <div className="browsermodal">
          <WarningFilled className="warning-icon" />
          You have {3 - countTabChange} chances left, after which your test will
          be terminated.
        </div>
      </div>
    </StyledModal>
  );
};

export default BrowserChangeModal;
