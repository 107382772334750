/* eslint-disable no-unused-vars */
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./QuestionManager.scss";
import { Breadcrumb, Button, Checkbox, Radio } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { StyledInputField } from "../../../../components/StyledFields/StyledInputField";
import arrowDown from "../../../../assets/arrow-down.svg";
import { useSelector } from "react-redux";
import { ReactComponent as Arrow } from "../../../../assets/arrow.svg";
import {
  questionFormOptions as options,
  quillFormats,
  quillModules,
} from "../../../../libs/constants";
import {
  currentEditingQuestion,
  currentEditingQuestions,
  currentEditingTestDetails,
} from "../../../../store/selectors/wizardSelectors";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  createQuestion,
  getMcqs,
  getQuestion,
} from "../../../../store/actions/wizardActions";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import { useEffect } from "react";
import { isEqual } from "lodash";
const QuestionForm = ({ handlCancel }) => {
  const [labelText, setLabelText] = useState("Single choice");
  const location = useLocation();
  const currentEditingTestList = useSelector(currentEditingTestDetails);
  const currentEditingQuestionList = useSelector(currentEditingQuestions);
  const currentEditQuestion = useSelector(currentEditingQuestion);
  const dispatch = useDispatch();
  const courseId = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const [SaveButtonLoader, setSaveButtonLoader] = useState(false);
  const testId = location.pathname.split("/")[4];
  const [initialValues, setinitialValues] = useState({
    question: "",
    questionType: "Single",
    answers: ["", ""],
    points: 1,
    correctAnswer: [],
  });

  const question_details = useSelector(currentEditingQuestion);
  useEffect(() => {
    if (!location.search.includes("id=new")) {
      dispatch(getQuestion(location.search.split("=")[1]));
    }
  }, []);
  useEffect(() => {
    if (!location.search.includes("id=new")) {
      if (question_details) {
        const options = question_details[0].options;
        const answers = [];
        const correct_answer = [];

        options.forEach((option, index) => {
          answers.push(option.option_text);
          if (option.is_answer) {
            correct_answer.push(index);
          }
        });
        const test_data = {
          question: question_details[0].question_text,
          questionType: question_details[0].question_type,
          answers: answers,
          points: question_details[0].points,
          correctAnswer: correct_answer,
        };
        setinitialValues(test_data);
      }
    }
  }, [question_details]);
  return (
    <div className="question-manager-container" style={{ width: "100%" }}>
      <Breadcrumb separator={<Arrow />}>
        <BreadcrumbItem>
          <Link
            to={`/courses/${courseId}/test/${currentEditingTestList.id}/manager`}
          >
            Question Manager
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          {!location.search.includes("id=new") && question_details
            ? "Q" + question_details[0].order_no
            : "New Question"}
        </BreadcrumbItem>
      </Breadcrumb>
      <p className="heading-test-question">
        {!location.search.includes("id=new") && question_details
          ? "Q" + question_details[0].order_no
          : "New Question"}
      </p>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};
          if (
            !values.question ||
            values.question.replace(/<(.|\n)*?>/g, "").trim().length === 0 ||
            values.question.trim() === " "
          ) {
            errors.question = "Field should not be empty";
          }
          if (values.answers.length < 1) {
            errors.answers = "Minimum allowed number of answers is 1 ";
          }
          if (
            values?.correctAnswer.length === 0 &&
            values.questionType === "Single"
          ) {
            errors.correctAnswer = "Please select only one correct answer";
          }
          if (
            values?.correctAnswer.length === 0 &&
            values.questionType === "Multiple"
          ) {
            errors.correctAnswer = "Please select at least one correct answer";
          }
          if (
            values.answers.includes("") &&
            values.questionType !== "Subjective"
          ) {
            errors.answers = "Options should not be empty";
          }
          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          const data = {
            test: currentEditingTestList.id,
            order_no: !location.search.includes("id=new")
              ? currentEditQuestion[0].order_no
              : currentEditingQuestionList.count + 1,
            questions: {
              id: !location.search.includes("id=new")
                ? question_details[0].question_id
                : null,
              question_text: values.question,
              question_type: values.questionType,
              question_weightage: values.points,
            },
            options: values.answers.map((answer, index) => ({
              option_text: answer,
              is_answer: values?.correctAnswer.includes(index),
            })),
          };
          dispatch(createQuestion(data, setSaveButtonLoader));
          dispatch(getMcqs(testId));
          if (values.buttonClicked === "saveAndNext") {
            resetForm();
          }
          if (values.buttonClicked === "save") {
            navigate(`/courses/${courseId}/test/${testId}/manager`);
          }
          setSaveButtonLoader(true);
          setinitialValues({
            question: "",
            questionType: "Single",
            answers: ["", ""],
            points: 1,
            correctAnswer: [],
          });
        }}
      >
        {({ values, setFieldValue, setFieldTouched, errors }) => (
          <Form>
            <div className="form-group">
              <h3 className="question-title">Question</h3>
              <Field name="question">
                {({ field }) => (
                  <ReactQuill
                    {...field}
                    name="question"
                    placeholder="Enter your question here."
                    style={{ height: "233px" }}
                    // eslint-disable-next-line no-unused-vars
                    onChange={(content, delta, source, editor) => {
                      setFieldValue("question", content);
                    }}
                    onBlur={() => setFieldTouched("question", true)}
                    value={values.question}
                    modules={quillModules}
                    formats={quillFormats}
                  />
                )}
              </Field>
            </div>
            <ErrorMessage
              name="question"
              component="div"
              className="error-message"
            />

            <div className="form-group">
              <label htmlFor="questionType" className="question-title">
                Question Type
              </label>
              <Field
                id="questionType"
                name="questionType"
                type="select"
                component={StyledInputField}
                defaultValue={values.questionType}
                onChange={(e) => {
                  const selectedValue = e;
                  selectedValue === "Subjective"
                    ? setinitialValues({
                        ...values,
                        answers: [""],
                        questionType: selectedValue,
                        correctAnswer: [0],
                      })
                    : setinitialValues({
                        question: values.question,
                        ...values,
                        // answers: ["", ""], Please Provide a refernce answer for evaluation
                        questionType: selectedValue,
                      });
                  setFieldValue("questionType", selectedValue);
                  setLabelText(selectedValue);
                }}
                options={options}
                suffixIcon={<img src={arrowDown} alt="arrow-down" />}
              />
            </div>

            <div className="form-group">
              <label>Answers</label>
              {values.questionType !== "Subjective" ? (
                <p className="question-descriptio">
                  After providing possible answers, select the correct one.
                </p>
              ) : (
                <p className="question-descriptio">
                  Please provide a refernce answer for evaluation.
                </p>
              )}

              <ErrorMessage
                name="answers"
                component="div"
                className="error-message"
              />
              {errors.correctAnswer ? (
                <div className="error-message">{errors.correctAnswer}</div>
              ) : null}

              <FieldArray name="answers">
                {({ remove, push, insert }) =>
                  values.questionType !== "Subjective" ? (
                    <div className="answers-array">
                      {values.answers.map((answer, index) => (
                        <div key={index} className="answer-item">
                          {values.questionType === "Single" ? (
                            <Radio.Group
                              onChange={(e) => {
                                const checkedValue = e.target.value;
                                const updatedCorrectAnswer = [checkedValue];
                                setFieldValue(
                                  "correctAnswer",
                                  updatedCorrectAnswer
                                );
                              }}
                              value={values?.correctAnswer[0]}
                            >
                              <Radio value={index}></Radio>
                            </Radio.Group>
                          ) : (
                            <Checkbox
                              className="square-checkbox"
                              value={index}
                              checked={values?.correctAnswer.includes(index)}
                              onChange={(e) => {
                                const checkedValue = e.target.value;
                                if (e.target.checked) {
                                  const updatedCorrectAnswer = [
                                    ...values.correctAnswer,
                                    checkedValue,
                                  ];
                                  setFieldValue(
                                    "correctAnswer",
                                    updatedCorrectAnswer
                                  );
                                } else {
                                  const updatedCorrectAnswer =
                                    values?.correctAnswer.filter(
                                      (value) => value !== checkedValue
                                    );
                                  setFieldValue(
                                    "correctAnswer",
                                    updatedCorrectAnswer
                                  );
                                }
                              }}
                            ></Checkbox>
                          )}
                          <Field
                            as="textarea"
                            name={`answers.${index}`}
                            className="form-control"
                            placeholder="Enter your option here."
                          />
                          <Button
                            type="button"
                            onClick={() => remove(index)}
                            className="remove-answer-btn"
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="delete-icon"
                            />
                          </Button>
                        </div>
                      ))}
                      <Button
                        type="button"
                        onClick={() => push("")}
                        className="add-answer-btn"
                      >
                        Add Answer
                      </Button>
                    </div>
                  ) : (
                    <div className="answers-array">
                      {values.answers.slice(0, 1).map((answer, index) => (
                        <div key={index} className="answer-item">
                          <Field
                            as="textarea"
                            type="textarea"
                            name={`answers.${index}`}
                            className="form-control"
                            showCount={true}
                            maxLength={5000}
                            rows={4}
                            autoSize={{
                              minRows: 5,
                              maxRows: 5,
                            }}
                            placeholder="Enter your answer here."
                          />
                        </div>
                      ))}
                    </div>
                  )
                }
              </FieldArray>
            </div>
            <div>
              <h3>Score Settings</h3>
              <p className="question-description">
                Define the score for a correct answer.
              </p>
            </div>
            <div className="form-group">
              <label htmlFor="points">Points for correct Answer</label>
              <Field name="points" type="number" className="form-control" />
            </div>
            <div className="score-settings">
              <Button
                type="primary"
                onClick={handlCancel}
                className="cancel-btn"
              >
                Cancel
              </Button>
              {location.search.includes("id=new") ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                  disabled={
                    isEqual(values, initialValues) ||
                    Object.keys(errors).length !== 0
                  }
                  loading={SaveButtonLoader}
                  onClick={() => {
                    setFieldValue("buttonClicked", "saveAndNext");
                  }}
                >
                  Save and add next
                </Button>
              ) : null}
              <Button
                type="primary"
                htmlType="submit"
                className="submit-btn"
                disabled={
                  isEqual(values, initialValues) ||
                  Object.keys(errors).length !== 0
                }
                onClick={() => {
                  setFieldValue("buttonClicked", "save");
                }}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default QuestionForm;
