import { useDispatch } from "react-redux";
import StyledModal from "../StyledModal/StyledModal";
import Spinner from "../Spinner/Spinner";
import "../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionDeleteConfirmModel.scss";
import { exportData } from "../../store/actions/adminActions";

function NavConfirmModal({
  open,
  onCancel,
  onsuccesscallback,
  loading,
  setLoading,
}) {
  const dispatch = useDispatch();
  const handleOk = () => {
    setLoading(true);
    dispatch(exportData(onsuccesscallback));
  };
  return (
    <StyledModal
      className={"draft-delete-modal"}
      centred
      title={"Export Data"}
      visible={open}
      onCancel={() => {
        setLoading(false);
        onCancel();
      }}
      onOk={handleOk}
      closable={true}
    >
      <div className="question-delete-modal">
        {loading ? (
          <Spinner text={"Please wait, Export in Progress..."} />
        ) : (
          "All The Course details will be exported into an Excel Sheet. Are you sure you want to proceed?"
        )}
      </div>
    </StyledModal>
  );
}

export default NavConfirmModal;
