import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReviewsSection from "./ReviewsSection";
import ModulesDisplay from "../../../../../components/ModuleDisplay/ModulesDisplay";
import { selectedCourseSelector } from "../../../../../store/selectors/coursesSelectors";
import CourseInfo from "../../../../../components/CourseInfo/CourseInfo";
import "./Content.scss";
import Spinner from "../../../../../components/Spinner/Spinner";
const ContentTab = ({ fromScreen }) => {
  const course = useSelector(selectedCourseSelector);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [courseList, setCourseList] = useState([]);
  const { id } = useParams();
  const settingCourseList = () => {
    let moduleslist = [];
    course.forEach((element) => {
      moduleslist.push(element.module);
    });
    let obj = [
      {
        course: course[0].course,
        modules: moduleslist,
        created_by: course[0].created_by,
      },
    ];
    setCourseList(obj);
  };
  useEffect(() => {
    if (course !== null) {
      settingCourseList();
      // checkIfEnrolled();
      setIsLoading(false);
      setSelectedCourse(course[0].course.id);
    }
    return () => {};
  }, [course, id]);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {courseList.map((values) => {
            return (
              <div
                data-testId="content-tab"
                className="content-tab"
                key={values.course.id}
              >
                <div
                  className={`course-module-section ${
                    fromScreen !== "onlyCandidate" ? "flex-grow-unenrolled" : ""
                  }`}
                >
                  <div className="course-header-section">
                    <CourseInfo
                      course={values.course}
                      createdBy={
                        values.created_by !== null &&
                        values.created_by !== undefined
                          ? values.created_by?.user_name
                          : null
                      }
                    />
                  </div>
                  <ModulesDisplay
                    modules={values.modules}
                    course={values.course}
                    isEnrolled={false}
                  />
                </div>
                {fromScreen !== "onlyCandidate" && (
                  <ReviewsSection courseId={selectedCourse} />
                )}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default ContentTab;
