import Statistics from "./Statistics/Statistics";
import Candidates from "./Candidates/Candidates";
import ContentTab from "./Content/ContentTab";

const tabScreen = {
  statistics: <Statistics />,
  content: <ContentTab />,
  candidate: <Candidates />,
};

const UnEnrolledTabDisplay = ({ activeTab }) => {
  return tabScreen[activeTab];
};

export default UnEnrolledTabDisplay;
