import { actionFactory } from "../../libs/actionFactory";
import { transformCourseSummaryData } from "../../libs/utils";
import {
  CLEAR_GRAPH_VALUES,
  SET_BELL_CURVE_VALUES,
  SET_COURSE_SUMMARY_VALUES,
} from "../actiontypes";

export const loadCourseGraphDataForUser = (id, graphId) => {
  return actionFactory({
    api: `get/api/courses/enroll/${id}/graph`,
    callback: (respPromise, dispatch, _getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            const graphData = transformCourseSummaryData(
              resp["data"].data.time_taken
            );
            dispatch({
              type: SET_COURSE_SUMMARY_VALUES,
              payload: {
                id: graphId,
                data: graphData,
              },
            });
          }
        })
        .catch((_) => {});
    },
  });
};

export const loadBellCurveDataForUser = (id, graphId) => {
  return actionFactory({
    api: `get/api/courses/enroll/${id}/graph_compare`,
    callback: (respPromise, dispatch, _getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: SET_BELL_CURVE_VALUES,
              payload: {
                id: graphId,
                data: resp["data"].data,
              },
            });
          }
        })
        .catch((_) => {});
    },
  });
};

export const clearGraphValues = (graphId) => {
  return {
    type: CLEAR_GRAPH_VALUES,
    payload: {
      id: graphId,
    },
  };
};
