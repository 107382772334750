/* eslint-disable quotes */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CourseTabs.scss";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import {
  faCircleQuestion,
  faFileLines,
} from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";
import { isPreviewSelector } from "../../../store/selectors/wizardSelectors";
import { useNavigate } from "react-router-dom";

function CourseTabs(props) {
  const { activeTab, setActiveTab } = props;
  const isPreview = useSelector(isPreviewSelector);
  const urlId = location.hash.split("/")[2];
  const navigate = useNavigate();
  const onItemClickHandle = (id) => {
    setActiveTab(id);
    navigate(`/courses/${urlId}/${id}/`);
  };

  return (
    <div className="course-tab">
      <div className="courseTabs-container">
        <div
          data-testid="tabItem"
          className={`tabItem ${activeTab === "content" ? "active" : ""}`}
          onClick={() => {
            onItemClickHandle("content");
          }}
        >
          <FontAwesomeIcon className="table-icon" icon={faTableList} />
          Content
        </div>
        <div
          data-testid="tabItem"
          className={`tabItem ${activeTab === "course" ? "active" : ""}`}
          onClick={() => {
            onItemClickHandle("course");
          }}
        >
          <FontAwesomeIcon className="table-icon" icon={faFileLines} />
          Course
        </div>
        {isPreview ? null : (
          <div
            data-testid="tabItem"
            className={`tabItem disabled ${
              activeTab === "faq" ? "active" : ""
            }`}
            // onClick={() => {
            //   onItemClickHandle("faq");
            // }}
          >
            <FontAwesomeIcon className="table-icon" icon={faCircleQuestion} />{" "}
            FAQ's
          </div>
        )}
      </div>
    </div>
  );
}

export default CourseTabs;
