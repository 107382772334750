import { Chart } from "chart.js/auto";
import { useEffect, useRef } from "react";
import "./CourseProgressGraph.scss";

const GraphLabel = ({ color, labelText }) => {
  return (
    <div className="label-container">
      <div className="label-color" style={{ backgroundColor: color }}></div>
      <div className="label-text">{labelText}</div>
    </div>
  );
};

function CourseProgressGraph({ data }) {
  const chartRef = useRef(null);
  const { labels, estimated_time, completed_time, avg_time } = data;
  useEffect(() => {
    const canvas = chartRef.current;
    const ctx = canvas.getContext("2d");

    // Data for your two lines
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Estimated Time",
          // borderWidth: 2,
          // borderColor: "#3AA738",
          backgroundColor: "#3AA738",
          data: estimated_time,
          // tension: 1, // Make the line monotone
          cubicInterpolationMode: "monotone",
          pointStyle: false, // Hide data points
          // borderCapStyle: "round",
          borderRadius: 5,
          barPercentage: 0.8,
          categoryPercentage: 0.4,
        },
        {
          label: "Completed In",
          // borderWidth: 2,
          // borderColor: "#FFB951",
          backgroundColor: "#FFB951",
          data: completed_time,
          cubicInterpolationMode: "monotone",
          pointStyle: false, // Hide data points
          // borderCapStyle: "round",
          borderRadius: 5,
          barPercentage: 0.8,
          categoryPercentage: 0.4,
        },
        {
          label: "Average Completion Time",
          // borderWidth: 2,
          // borderColor: "#FFB951",
          backgroundColor: "#FF0000",
          data: avg_time,
          cubicInterpolationMode: "monotone",
          pointStyle: false, // Hide data points
          // borderCapStyle: "round",
          borderRadius: 5,
          barPercentage: 0.8,
          categoryPercentage: 0.4,
        },
      ],
    };

    const chartOptions = {
      scales: {
        x: {
          border: {
            color: "rgba(184, 196, 206, 0.1)",
          },
          grid: {
            color: "rgba(184, 196, 206, 0.2)",
          },
          title: {
            display: true,
            text: "Modules",
            color: "#696969",
          },
          ticks: {
            color: "#B8C4CE",
            callback: function (value, _index, _values) {
              const maxLabelLength = 20; // Adjust as needed

              // Use getLabelForValue to retrieve the label
              const label = this.getLabelForValue(value);

              if (label.length > maxLabelLength) {
                // Truncate the label and add ellipsis
                return label.substring(0, maxLabelLength) + "…";
              } else {
                return label;
              }
            },
          },
        },
        y: {
          border: {
            color: "rgba(184, 196, 206, 0.1)",
          },
          suggestedMin: 0, // Ensure y-axis starts at zero
          suggestedMax: 5, // Show one more available value than required (e.g., max value is 4, show till 5)
          title: {
            display: true,
            text: "Time Taken ( Weeks )",
            color: "#696969",
          },
          ticks: {
            stepSize: 1, // Set the step size to 1 for whole numbers
            color: "#B8C4CE",
          },
          grid: {
            color: "rgba(184, 196, 206, 0.2)",
          },
        },
      },
      plugins: {
        legend: {
          display: false, // Remove legends
        },
      },
    };

    new Chart(ctx, {
      type: "bar",
      data: data,
      options: chartOptions,
    });
  }, []);

  return (
    <div
      className="courseProgress-container"
      data-testId="courseProgress-container"
    >
      <div className="title" data-testId="title">
        Course Summary
      </div>
      <div className="subtitle" data-testId="subtitle">
        Comparing Expected vs. Actual course completion times
      </div>
      <div className="chart-container" data-testId="chart-container">
        <canvas ref={chartRef} height={300} width={822}></canvas>
      </div>
      <div className="labels-container" data-testId="labels-container">
        <GraphLabel color={"#3AA738"} labelText={"Expected Progress"} />
        <GraphLabel color={"#FFB951"} labelText={"Actual Progress"} />
        <GraphLabel color={"#FF0000"} labelText={"Average Completion Time"} />
      </div>
    </div>
  );
}

export default CourseProgressGraph;
