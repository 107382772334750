import PropTypes from "prop-types";
import { get } from "lodash";

import "./StyledFields.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import { Rate } from "antd";

export function StyledRatingInput({ field, form, ...props }) {
  const { errors, touched, values, setFieldTouched, handleBlur, handleChange } =
    form;
  const { name } = field;
  const fieldError = get(errors, name);
  const fieldTouched = get(touched, name);
  return (
    <div className="styled-input-field">
      <div data-testid="ratecomponent">
        <Rate
          {...props}
          {...field}
          onChange={(value) => {
            handleChange({
              target: {
                name: "rating",
                value: value,
              },
            });
          }}
          onBlur={(e) => {
            if (!fieldError && fieldTouched) {
              setFieldTouched(name, false, true);
            } else if (!values[name] && !fieldTouched) {
              handleBlur(e);
            }
          }}
          value={values[name]}
          allowClear={false}
          className="rate-submit"
        />
        {fieldError && fieldTouched ? (
          <div className="ratecomponentWarning">
            <InfoCircleFilled className="error-info-icon" />{" "}
            <span>{"Rating is mandatory"}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

StyledRatingInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};
