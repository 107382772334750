import "./GlobalEmptyContainer.scss";
import PropTypes from "prop-types";

/**
 * @component GlobalEmptyContainer
 *
 * @props
 * @param {Object} props - The props of the component.
 * @param {node} props.imgContent - ImgContent is in node Format, to show image on screen, not required
 * @param {content} props.content - content displays content on the screen, it is node format, it is required
 * @param {object} props.actionButtons - An object which has necessary properties of the button, not required
 *
 * @example
 * const buttonProps = {
    label: "Get Started",
    className: "get-started-button",
    onClick: handleClick,
  };
 *   // Add more steps here...
 * ];
 *
 * function ExampleComponent() {
 *
 *   return (
 *     <div>
 *       <GlobalEmptyContainer
          imgContent={<img src={EmptyContainerImage} alt="Empty container" />}
          content={
            <>
              <p className="title">Course Creation Center</p>
              <p className="subtitle">
                Empower Your Organization by Designing Customized Learning
                Experiences
              </p>
            </>
          }
          actionButton={buttonProps}
        />
 *     </div>
 *   );
 * }
 */
const GlobalEmptyContainer = (props) => {
  const { imgContent, content, actionButton } = props;
  return (
    <div className="empty-container">
      {imgContent}
      <div className="text-container">
        {content}
        {actionButton && (
          <button {...actionButton}> {actionButton.label}</button>
        )}
      </div>
    </div>
  );
};

GlobalEmptyContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  imgContent: PropTypes.node,
  content: PropTypes.node.isRequired,
  // eslint-disable-next-line react/require-default-props
  actionButton: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
      className: PropTypes.string,
      onclick: PropTypes.func,
    })
  ),
};

export default GlobalEmptyContainer;
