import { useEffect, useState } from "react";
import "./ProfileTabScreen.scss";
import Spinner from "../../../../../components/Spinner/Spinner";
import { ReactComponent as Link } from "../../../../../assets/link.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "antd";

const ProfileTabScreen = ({ selectedUser }) => {
  const { emp_id, email, groups, gitlab_username, gitlab_workspace } =
    selectedUser || {};
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedUser) {
      setIsLoading(false);
    }
  }, [selectedUser]);
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(email);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div
        className="profileTabScreen-container"
        data-testid="profileTabScreen"
      >
        <div className="Info-container" data-testid="Info-container">
          <div className="InfoDetail-container">
            <div className="Info-Items">
              <div>ID</div>
              <div className="Info-Items-value">{emp_id}</div>
            </div>
            <div className="Info-Items">
              <div>EMAIL</div>
              <div className="Info-Items-value">
                {email}
                {copied ? (
                  <Tooltip title="Copied" open={true}>
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faPaste}
                      color="#666"
                      data-testid="Copied"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Click to copy" destroyTooltipOnHide={true}>
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleCopyClick}
                      icon={faPaste}
                      color="#666"
                      data-testid="Click to copy"
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="Info-Items">
              <div>ROLE</div>
              <div className="Info-Items-value">
                {groups &&
                  groups.map((group) => (
                    <button key={group?.id}>
                      {group?.name.charAt(0).toUpperCase() +
                        group?.name.toLowerCase().slice(1)}
                    </button>
                  ))}
              </div>
            </div>
            <div className="Info-Items">
              <div>GITLAB USERNAME</div>
              <div className="Info-Items-value">{gitlab_username}</div>
            </div>
            <div className="Info-Items">
              <div>GITLAB WORKSPACE</div>
              <div className="Info-Items-value">
                {gitlab_workspace}{" "}
                <a
                  href={`https://gitlab.corp.coriolis.in/${gitlab_workspace}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Link />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileTabScreen;
