import { formatDate } from "../../../../../libs/utils";
import "./AuthoredTable.scss";
import { useLocation } from "react-router-dom";

const AuthoredTable = ({ authoredCoursesList }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return (
    <div className="authored-table">
      <div className="header">
        <div className="column">Course Name</div>
        <div className="column">Modules</div>
        <div className="column">Total Enrollment</div>
        <div className="column">Published On</div>
      </div>
      <div className="content">
        {authoredCoursesList?.length === 0 ? (
          <div className="authored-table-empty-container">
            {searchParams.size === 0
              ? "No records to show!"
              : "No results found"}
          </div>
        ) : (
          authoredCoursesList?.map((authoredListItem, _) => (
            <div className="row" key={authoredListItem?.id}>
              <div className="column">{authoredListItem?.course_name}</div>
              <div className="column">{authoredListItem?.modules.length}</div>
              <div className="column">{authoredListItem?.no_of_enrolls}</div>
              <div className="column">
                {formatDate(authoredListItem?.created_by.created_at)}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AuthoredTable;
