import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import DisplayInfoMessage from "../../../../components/DisplayInfoMessage/DisplayInfoMessage";
import ModulesList from "./ModulesList";

import "./ModuleCreationStep.scss";
import ModuleTile from "./ModuleTile";
import { useSelector } from "react-redux";
import { wizardLoaderSelector } from "../../../../store/selectors/wizardSelectors";
import Spinner from "../../../../components/Spinner/Spinner";

function ModuleCreationStep() {
  const [showMessage, setShowMessage] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const isLoading = useSelector(wizardLoaderSelector);
  const handleIconClick = () => {
    setShowMessage(true);
  };

  const handleAlertClose = () => {
    setShowMessage(false);
  };

  const handleAddClick = () => {
    setIsCreating(true);
  };

  const closeModuleCreation = () => {
    setIsCreating(false);
  };

  return (
    <div className="moduleCreationStep-container">
      {isLoading && (
        <div className="moduleCreationStep-loader">
          <Spinner color={"white"} text={"Creating Modules"} />{" "}
        </div>
      )}
      <div className="moduleCreationStep-header">
        <p className="title">Modules</p>
        <div onClick={handleIconClick} data-testid="circleIconModuleInfo">
          <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
        </div>
      </div>
      {showMessage && (
        <DisplayInfoMessage
          length="100%"
          top="10px"
          msg="Here's where you add course content—like modules. Click Add Module Button to get started."
          type="info"
          closable={true}
          onClick={handleAlertClose}
          style={{
            padding: "16px 22px",
            fontSize: "14px",
            marginTop: "12px",
          }}
        />
      )}
      <ModulesList isCreating={isCreating} />
      <div className="moduleCreationStep-footer">
        <div className="createModule-tile">
          {isCreating && (
            <ModuleTile mode={"create"} closeModule={closeModuleCreation} />
          )}
        </div>
        <button className="moduleCreation-cancel" onClick={handleAddClick}>
          Add Module
        </button>
      </div>
    </div>
  );
}

export default ModuleCreationStep;
