import {
  createHashRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import constants from "../constants";
import LoginPage from "../pages/LoginPage/LoginPage";
import PrivateRoute from "./PrivateRoute";
import { getUserRoutes } from "./userRoutes";
import PublicRoute from "./PublicRoute";
import Layouts from "../components/Layouts/Layouts";
import PublicResultDisplay from "../expo/PublicTestPage/PublicResultDisplay";
import PrivacyPolicy from "../expo/PublicTestPage/PrivacyPolicy";

export const appRoutes = (groups) => {
  return createHashRouter(
    createRoutesFromElements(
      <>
        <Route
          exact
          path={constants.LOGIN_PAGE_URL}
          element={<LoginPage />}
        ></Route>
        {/* <Route
          exact
          path={constants.PUBLIC_CONTENT_PAGE}
          element={<PublicCourseDetailPage />}
        ></Route> */}
        <Route
          exact
          path={constants.PUBLIC_RESULT_PAGE}
          element={<PublicResultDisplay />}
        ></Route>
        <Route
          exact
          path={constants.PRIVACY_URL}
          element={<PrivacyPolicy />}
        ></Route>
        {/* <Route
          exact
          path={constants.PUBLIC_COURSE_PAGE}
          element={<AboutCourse />}
        ></Route> */}
        {/* <Route
          exact
          path={constants.PUBLIC_COURSE}
          element={<CandidateTestContainer />}
        ></Route> */}

        <Route path="/" element={<Layouts groups={groups} />}>
          {/* Private route */}
          <Route exact path="/" element={<PrivateRoute />}>
            {getUserRoutes(groups)}
          </Route>

          {/* Public route */}
          <Route path="/*" element={<PublicRoute />} />
        </Route>
      </>
    )
  );
};
