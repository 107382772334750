import CourseCard from "../../../../../components/CourseCard/CourseCard";
import "./PublishedCourses.scss";

function PublishedCourses({ publishedCoursesList }) {
  return (
    <div className="publishedCourses-container">
      <div className="publishedCourses-title">Published </div>
      {publishedCoursesList?.length === 0 ? (
        <div className="publishedCourses-empty">No courses found</div>
      ) : (
        <div className="publishedCourses-list">
          {publishedCoursesList?.map((course) => {
            return <CourseCard key={course.id} course={course} />;
          })}
        </div>
      )}
    </div>
  );
}

export default PublishedCourses;
