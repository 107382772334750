import { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";
import PropTypes from "prop-types";
import "./GlobalBellCurve.scss";

const BELL_CURVE_CONFIG = {
  label: "Line",
  type: "line", // Use 'line' type for the line dataset
  borderWidth: 2, // Set the width of the line
  fill: true, // Fill the area under the line
  tension: 0.38,
  pointStyle: false,
};

const USER_LINE_CONFIG = {
  label: "Line",
  type: "line", // Use 'line' type for the line dataset
  borderColor: "#000000", // Set the color of the line
  borderWidth: 1, // Set the width of the line
  borderDash: [10, 4, 2, 8],
  fill: false, // Don't fill the area under the line
  tension: 0,
  pointStyle: false,
};

/**
 * @component GlobalBellCurve
 * This component renders a bell curve chart and a user line on it.
 *
 * @param {Array} datasets - An array of datasets for the bell curve chart.
 * Each dataset contains data points and styling information.
 * @example
 * datasets: [
 *   {
 *     datapoints: {
 *       current_user: {
 *         x: 14,
 *         y: 0.005815823307022584,
 *       },
 *       mean: 54.666666666666664,
 *       standard_deviation: 35.21836642056717,
 *       normal_distributions: [
 *         { x: 9, y: 0.005886957867963366 },
 *         // ... (other data points)
 *       ],
 *     },
 *     backgroundColor: "#EFEFEF",
 *     lineColor: "#FF0000",
 *   },
 *   // ... (other datasets)
 * ]
 */

function GlobalBellCurve({ datasets }) {
  if (datasets[0]?.datapoints === null) {
    return (
      <div className="bellCurve-container" data-testid="bellCurve-container">
        <div className="bellCurve-title">Comparison with others</div>
        <div className="bellCurve-subtitle">
          Insufficient data to plot curve
        </div>
      </div>
    );
  }

  //Format data to create bellcurve datasets
  const bellCurveDataset = datasets.map((data) => {
    // Extract data properties from the 'data' object
    const { normal_distributions } = data?.datapoints || {};
    return {
      ...BELL_CURVE_CONFIG,
      data: normal_distributions,
      backgroundColor: data.backgroundColor,
      borderColor: data.lineColor,
    };
  });

  //Format data to create line datapoints
  const userLineDatapoint = datasets?.map((data) => {
    const { current_user, normal_distributions } = data?.datapoints || {};
    return {
      ...USER_LINE_CONFIG,
      data: [
        { x: current_user.x, y: 0 },
        {
          x: current_user.x,
          y: findAndRoundHighestY(normal_distributions),
        },
      ],
    };
  });

  // Reference to the canvas element for the chart
  const chartRef = useRef(null);

  // Function to find and round the highest 'y' value
  function findAndRoundHighestY(data) {
    let max = -Infinity;

    for (const point of data) {
      if (point.y > max) {
        max = point.y;
      }
    }

    // Round the highest value to the nearest step size
    const stepSize = 0.002;
    const roundedMax = Math.round(max / stepSize) * stepSize;

    return roundedMax;
  }

  useEffect(() => {
    const canvas = chartRef.current;
    const ctx = canvas.getContext("2d");

    // Data for your scatter plot
    const chartData = {
      datasets: [...bellCurveDataset, ...userLineDatapoint],
    };

    const chartOptions = {
      scales: {
        x: {
          border: {
            color: datasets?.length > 1 ? "#dddddd" : "#FFB951",
            width: 2,
          },
          grid: {
            color: "rgba(184, 196, 206, 0.1)",
          },
          title: {
            display: true,
            text: "Time (Days)",
            color: "#696969",
          },
          ticks: {
            color: "#B8C4CE",
            stepSize: 1,
          },
        },
        y: {
          border: {
            color: "rgba(184, 196, 206, 0.05)",
          },
          grid: {
            color: "rgba(184, 196, 206, 0.1)",
          },
          suggestedMin: 0,
          title: {
            display: true,
            text: "Frequency (%)",
            color: "#696969",
          },
          ticks: {
            stepSize: 0.002,
            color: "#B8C4CE",
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    new Chart(ctx, {
      type: "scatter", // Use 'scatter' type for a scatter plot
      data: chartData,
      options: chartOptions,
    });
  }, []);

  // Rendering the Bell Curve component
  return (
    <div className="bellCurve-container" data-testid="bellCurve-container">
      <div className="bellCurve-title">Comparison with others</div>
      <div className="bellChart-container">
        <canvas ref={chartRef} height={400} width={822}></canvas>
      </div>
    </div>
  );
}

GlobalBellCurve.prototypes = {
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      datapoints: PropTypes.shape({
        current_user: PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired,
        }).isRequired,
        mean: PropTypes.number.isRequired,
        standard_deviation: PropTypes.number.isRequired,
        normal_distributions: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
          })
        ).isRequired,
      }),
      backgroundColor: PropTypes.string.isRequired,
      lineColor: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default GlobalBellCurve;
