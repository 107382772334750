import { Navigate, Outlet } from "react-router-dom";
import PublicCourseDetailPage from "../expo/PublicCourseDetailPage/PublicCourseDetailPage";
import CandidateTestContainerPublic from "../expo/PublicTestPage/CandidateTestContainerPublic";
import PublicResultDisplay from "../expo/PublicTestPage/PublicResultDisplay";

const PrivateRoute = () => {
  const hash = window.location.hash;
  return localStorage.getItem("token") === null ? (
    hash.includes("/public") ? (
      hash.includes("/tests") ? (
        hash.includes("/results") ? (
          <PublicResultDisplay />
        ) : (
          <CandidateTestContainerPublic />
        )
      ) : (
        <PublicCourseDetailPage />
      )
    ) : (
      <Navigate to="/login" />
    )
  ) : (
    <Outlet />
  );
};

export default PrivateRoute;
