import { Button, Form, Radio, Switch, TimePicker } from "antd";
import { Field, Formik } from "formik";
import { StyledInputField } from "../../../../components/StyledFields/StyledInputField";
import { currentEditingTestDetails } from "../../../../store/selectors/wizardSelectors";
import { useSelector } from "react-redux";
import {
  checkIfStepSaveButtonValid,
  handleChange,
  stepFourValidateForm,
} from "./DetailsMcqFormUtils";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  stepFour,
  wizardGetParticularTest,
} from "../../../../store/actions/wizardActions";
import { userSelector } from "../../../../store/selectors/authSelectors";
import "./McqTestCreation.scss";
import moment from "moment/moment";
import { isNull } from "lodash";
const DetailsMcqForm = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const courseId = window.location.hash.split("/")[2];
  //If testId is undefined then it is New Test or else Edit Mode
  const testId =
    window.location.hash.split("/").length > 4
      ? window.location.hash.split("/")[4]
      : undefined;
  const creatorId = useSelector(userSelector);
  const [McqSettingsClicked, setMcqSettingsClicked] = useState(false);
  const [dataForReset, setDataForReset] = useState();
  const [totalTestTime, setTotalTestTime] = useState(null);
  const [quesTime, setQuesTime] = useState(null);
  const [SaveButtonValid, setIfSaveButtonValid] = useState(false);
  const [selectDuration, setSelectDuration] = useState(
    "total_time_limit_of_test"
  );
  const hasApiCallCompleted = useRef(false);
  const [SaveButtonLoader, setSaveButtonLoader] = useState(false);
  const handlerMcqSettings = () => {
    setMcqSettingsClicked(!McqSettingsClicked);
  };
  const editingtestDetails = useSelector(currentEditingTestDetails);
  const callbackGetParticularTest = (data) => {
    setDataForReset(data);
    //Setting Cause the both the datePciker will be disabled
    setSelectDuration(
      data?.time_limit_for_each_question
        ? "time_limit_for_each_question"
        : data?.total_time_limit_of_test
        ? "total_time_limit_of_test"
        : null
    );
    setTotalTestTime(data?.total_time_limit_of_test);
    setQuesTime(data?.time_limit_for_each_question);
  };

  useEffect(() => {
    if (testId) {
      dispatch(wizardGetParticularTest(testId, callbackGetParticularTest));
    }
  }, []);

  useEffect(() => {
    if (!hasApiCallCompleted.current && editingtestDetails) {
      setIfSaveButtonValid(
        checkIfStepSaveButtonValid(editingtestDetails, dataForReset)
      );
    } else {
      hasApiCallCompleted.current = false;
    }
  }, [editingtestDetails, dataForReset]);
  // Initial values of the form
  const INITIAL_FORM_VALUES = {
    id: editingtestDetails?.id || null,
    test_name: editingtestDetails?.test_name || "",
    test_description: editingtestDetails?.test_description || "",
    time_limit_for_each_question:
      editingtestDetails?.time_limit_for_each_question || null,
    total_time_limit_of_test:
      editingtestDetails?.total_time_limit_of_test || null,
    allow_question_back_n_forth:
      editingtestDetails?.allow_question_back_n_forth || false,
    question_order: editingtestDetails?.question_order || "Ordered",
    passing_score: editingtestDetails?.passing_score || "",
    max_number_of_attempts:
      editingtestDetails?.max_number_of_attempts === null
        ? null
        : editingtestDetails?.max_number_of_attempts || 2,
    attempt_limit: isNull(editingtestDetails?.max_number_of_attempts),
  };
  // onclick handler of Save Button
  const handleOnSave = async () => {
    // delete INITIAL_FORM_VALUES.attempt_limit;
    dispatch(
      stepFour(
        {
          INITIAL_FORM_VALUES: INITIAL_FORM_VALUES,
          creator: creatorId?.id,
          course: courseId,
        },
        setDataForReset,
        setSaveButtonLoader,
        setIfSaveButtonValid
      )
    );
    setSaveButtonLoader(true);
    hasApiCallCompleted.current = true;
  };
  const handleTimeChange = (time, format) => {
    // Format the time using moment library
    if (time) {
      // Calculate total minutes
      if (format === "HH:mm") {
        setTotalTestTime(time.format("HH:mm:ss"));
        setQuesTime(null);
      } else {
        setTotalTestTime(null);
        setQuesTime(time.format("HH:mm:ss"));
      }
    } else {
      setTotalTestTime(null);
      setQuesTime(null);
    }
  };
  // onclick handler of Reset Button
  const handleOnReset = () => {
    // Set form values to empty
    ref.current.setValues({
      id: dataForReset ? dataForReset.id : "",
      test_name: dataForReset ? dataForReset.test_name : "",
      test_description: dataForReset ? dataForReset.test_description : "",
      time_limit_for_each_question: dataForReset
        ? dataForReset.time_limit_for_each_question
        : null,
      total_time_limit_of_test: dataForReset
        ? dataForReset.total_time_limit_of_test
        : null,
      allow_question_back_n_forth: dataForReset
        ? dataForReset.allow_question_back_n_forth
        : false,
      question_order: dataForReset ? dataForReset.question_order : "Ordered",
      passing_score: dataForReset ? dataForReset.passing_score : "",
      max_number_of_attempts: dataForReset
        ? dataForReset.max_number_of_attempts
        : 2,
      attempt_limit:
        dataForReset?.max_number_of_attempts === null ? true : false,
    });
    setSelectDuration(
      dataForReset?.time_limit_for_each_question
        ? "time_limit_for_each_question"
        : dataForReset?.total_time_limit_of_test
        ? "total_time_limit_of_test"
        : "total_time_limit_of_test"
    );
    setTotalTestTime(dataForReset?.total_time_limit_of_test);
    setQuesTime(dataForReset?.time_limit_for_each_question);
    setIfSaveButtonValid(false);
  };
  return (
    <Formik
      fluid
      innerRef={ref}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={stepFourValidateForm}
      initialValues={INITIAL_FORM_VALUES}
      enableReinitialize
      isInitialValid={false}
    >
      {({ setFieldValue }) => (
        <Form className="mcqform" data-testid="form-div">
          <h3 className="formHeading">Basic Details</h3>
          <Field
            label="Test Title"
            id="test_name"
            name="test_name"
            type="textarea"
            onChange={(event) => {
              handleChange(event, setFieldValue, "test_name", ref, dispatch);
            }}
            data-testid="course-tittle-input"
            showCount
            maxLength={150}
            autoSize={{
              minRows: 1,
              maxRows: 1,
            }}
            component={StyledInputField}
            placeholder="Test title"
          />
          <Field
            label="Test Description"
            id="test_description"
            name="test_description"
            className="text-area"
            type="textarea"
            onChange={(event) => {
              handleChange(
                event,
                setFieldValue,
                "test_description",
                ref,
                dispatch
              );
            }}
            data-testid="test-desc-input"
            component={StyledInputField}
            placeholder="It's ok if you can't think of a good description now. You can change it later."
            showCount
            maxLength={255}
            autoSize={{
              minRows: 4,
              maxRows: 5,
            }}
          />
          <div className="settingsConatiner">
            <div className="McqTestCreation-settings">
              <span className="settingHeading">Settings</span>
              <Button className="expand-button" onClick={handlerMcqSettings}>
                {" "}
                {McqSettingsClicked ? "Collapse" : "Expand"}
              </Button>
            </div>
            {McqSettingsClicked && (
              <div className="settings-expanded">
                <div>
                  <h3 className="settings-heading">TEST DURATION</h3>
                  <Switch
                    size="small"
                    id="switchIDTestDuration"
                    checked={ref?.current?.values?.allow_question_back_n_forth}
                    defaultValue={
                      ref?.current?.values?.allow_question_back_n_forth
                    }
                    onClick={(event) => {
                      handleChange(
                        event,
                        setFieldValue,
                        "allow_question_back_n_forth",
                        ref,
                        dispatch
                      );
                      if (event === true) {
                        setSelectDuration("total_time_limit_of_test");
                      }
                    }}
                  />
                  <label className="settingsspan">
                    Allow answering questions in any order and moving back or
                    skipping questions
                  </label>
                </div>
                <div className="durationContent">
                  <label className="settingsspan">
                    Select test duration method:
                  </label>
                  <Radio.Group
                    id="test_duration"
                    name="test_duration"
                    value={
                      selectDuration === "time_limit_for_each_question"
                        ? "time_limit_for_each_question"
                        : "total_time_limit_of_test"
                    }
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      setSelectDuration(selectedValue);
                      handleChange(
                        selectedValue === "total_time_limit_of_test"
                          ? totalTestTime
                          : quesTime,
                        setFieldValue,
                        selectedValue,
                        ref,
                        dispatch
                      );
                    }}
                    buttonStyle="solid"
                    className="radio-button"
                  >
                    <Radio
                      value={"total_time_limit_of_test"}
                      checked={selectDuration === "total_time_limit_of_test"}
                    >
                      <div className="label-time">
                        <label className="settingsspan">
                          Time to complete the test (hh:mm){" "}
                        </label>
                        <TimePicker
                          picker="time"
                          format="HH:mm"
                          disabled={
                            selectDuration !== "total_time_limit_of_test"
                          }
                          defaultValue={
                            INITIAL_FORM_VALUES?.total_time_limit_of_test
                          }
                          value={
                            totalTestTime
                              ? moment(totalTestTime, "HH:mm")
                              : null
                          }
                          placeholder="01:30"
                          showNow={false}
                          onOk={(time) => {
                            handleTimeChange(time, "HH:mm");
                            handleChange(
                              time ? time.format("HH:mm:ss") : null,
                              setFieldValue,
                              "total_time_limit_of_test",
                              ref,
                              dispatch
                            );
                          }}
                        />
                      </div>
                    </Radio>
                    <Radio
                      disabled={
                        ref?.current?.values?.allow_question_back_n_forth
                      }
                      value={"time_limit_for_each_question"}
                      checked={
                        selectDuration === "time_limit_for_each_question"
                      }
                    >
                      <div className="label-time">
                        <label className="settingsspan">
                          Time for each test question (mm:ss)
                        </label>
                        <TimePicker
                          picker="time"
                          format="mm:ss"
                          disabled={
                            selectDuration !== "time_limit_for_each_question"
                          }
                          defaultValue={
                            INITIAL_FORM_VALUES?.time_limit_for_each_question
                          }
                          value={quesTime ? moment(quesTime, "HH:mm:ss") : null}
                          placeholder="01:00"
                          showNow={false}
                          onOk={(time) => {
                            handleTimeChange(time, "mm:ss");
                            handleChange(
                              time ? time.format("HH:mm:ss") : null,
                              setFieldValue,
                              "time_limit_for_each_question",
                              ref,
                              dispatch
                            );
                          }}
                        />
                      </div>
                    </Radio>
                  </Radio.Group>
                </div>
                <div>
                  <div className="questionOrderContent">
                    <h3 className="settings-heading">QUESTION ORDER</h3>
                    <label className="settingsspan">
                      Select one of the following
                    </label>
                    <Radio.Group
                      id="test_duration"
                      name="test_duration"
                      value={ref?.current?.values?.question_order}
                      onChange={(event) => {
                        handleChange(
                          event,
                          setFieldValue,
                          "question_order",
                          ref,
                          dispatch
                        );
                      }}
                      buttonStyle="solid"
                      className="radio-button"
                    >
                      <Radio
                        value={"Ordered"}
                        checked={ref?.current?.values?.question_order}
                      >
                        <label className="settingsspan">
                          Fixed questions and answers order as defined in
                          Questions manager
                        </label>
                      </Radio>
                      <Radio
                        value={"Random"}
                        checked={ref?.current?.values?.question_order}
                      >
                        <label className="settingsspan">
                          Random questions and answers order
                        </label>
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="grading">
                  <h3 className="settings-heading">GRADING CRITERIA</h3>
                  <div className="GradingContents">
                    <label className="passingScoreLabel">Passing score:</label>
                    <div className="settingsGradintCriteria">
                      <Field
                        type="number"
                        id="passing_score"
                        name="passing_score"
                        placeholder="80"
                        defaultValue={0}
                        component={StyledInputField}
                        controls={false}
                        onChange={(e) => {
                          handleChange(
                            e,
                            setFieldValue,
                            "passing_score",
                            ref,
                            dispatch
                          );
                        }}
                      />
                    </div>
                    <label className="settingsspan" id="gradingLast">
                      in percentage(%)
                    </label>
                  </div>
                </div>
                <div className="AttemptsContent">
                  <h3 className="settings-heading">TEST ACCESS</h3>
                  <Switch
                    size="small"
                    id="switchIDAttempts"
                    checked={isNull(
                      ref?.current?.values?.max_number_of_attempts
                    )}
                    onClick={(event) => {
                      handleChange(
                        event,
                        setFieldValue,
                        "attempt_limit",
                        ref,
                        dispatch
                      );
                    }}
                  />
                  <label className="settingsspan">No Limits</label>
                  <div className="AttemptsContents">
                    <label
                      className="numberOfAttemptLabel"
                      id="numberOfAttemptLabel"
                    >
                      Number of times a respondent can take test
                    </label>
                    <div className="settingsTestAccess">
                      <Field
                        type="number"
                        id="no_attempts"
                        name="max_number_of_attempts"
                        placeholder="2"
                        defaultValue={2}
                        disabled={
                          isNull(
                            ref?.current?.values?.max_number_of_attempts
                          ) &&
                          (ref.current.touched.max_number_of_attempts ||
                            ref?.current?.values?.attempt_limit)
                        }
                        component={StyledInputField}
                        controls={false}
                        onChange={(e) => {
                          handleChange(
                            e,
                            setFieldValue,
                            "max_number_of_attempts",
                            ref,
                            dispatch
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mcqformFooter">
            <Button className="mcqFormResetButtons" onClick={handleOnReset}>
              Reset
            </Button>
            <Button
              className="mcqFormSaveButton"
              disabled={!SaveButtonValid}
              onClick={handleOnSave}
              loading={SaveButtonLoader}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DetailsMcqForm;
