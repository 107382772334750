import "./DraftCourses.scss";
import DraftTile from "./DraftTile";

const DraftCourses = (props) => {
  const { draftcourseList, setIsWizardOpen } = props;
  return (
    <div className="drafts-container">
      <h1 className="drafts-subtitle">Drafts</h1>
      {draftcourseList?.length === 0 ? (
        <div className="draftCourses-empty">No Draft Courses</div>
      ) : (
        draftcourseList?.map((draftCourse, index) => (
          <DraftTile
            testId={`draftTile-${index}`}
            key={draftCourse?.id}
            data={draftCourse}
            setIsWizardOpen={setIsWizardOpen}
          />
        ))
      )}
    </div>
  );
};
export default DraftCourses;
