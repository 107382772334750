import { Button } from "antd";
import Breadcrumbs from "../../../../components/BreadCrumb/BreadCrumbs";
import "./CandidateScreen.scss";
import ContentTab from "../TabScreen/Content/ContentTab";
import ReviewsSection from "../TabScreen/Content/ReviewsSection";
const CandidateScreen = ({ courseList, setIsModalOpen }) => {
  return (
    <>
      {courseList.map((values) => {
        return (
          <div
            data-testId="candidate-screen"
            className="candidate-screen"
            key={values.course.id}
          >
            <div className="content">
              <div className="header-left">
                <Breadcrumbs />
                <div className="tittle-with-button">
                  <div className="course-title">
                    {values.course.course_name}
                  </div>
                  <div className="header-right">
                    <Button
                      onClick={() => setIsModalOpen(true)}
                      type="primary"
                      size="large"
                      block="true"
                      style={{ background: "#1A2B3C" }}
                      data-testid="enroll-button"
                    >
                      ENROLL
                    </Button>
                  </div>
                </div>
              </div>
              <ContentTab fromScreen="onlyCandidate" />
            </div>
            <ReviewsSection courseId={values.course.id} />
          </div>
        );
      })}
    </>
  );
};

export default CandidateScreen;
