import eigenEval from "../../assets/eigenEval.png";
import logo from "../../assets/eigenlearn-logo-dark-large.svg";
import "./NavBar.scss";
import { useEffect } from "react";

function NavBar() {
  useEffect(() => {
    if (process.env.REACT_APP_PROJECT_TYPE === "eigenlearn") {
      document.title = "EigenLearn";
    } else {
      document.title = "EigenEval";
    }
  }, []);

  let navclass = "navbar-main-container";
  if (window.location.href.includes("/start/public")) {
    navclass = "testnav";
  }
  return (
    <div className={navclass}>
      <div className="inner-container">
        {process.env.REACT_APP_PROJECT_TYPE === "eigeneval" ? (
          <a
            className="navbar-image-link"
            href="https://eigeneval.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="navbar-image"
              src={eigenEval}
              alt="EigenEval's logo"
            />
          </a>
        ) : (
          <img
            className="navbar-image"
            src={logo}
            alt="Default EigenEval's logo"
          />
        )}

        {process.env.REACT_APP_PROJECT_TYPE === "eigeneval" && (
          <a
            className="navbar-link"
            href="https://eigeneval.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>www.eigeneval.com</span>
          </a>
        )}
      </div>
    </div>
  );
}

export default NavBar;
