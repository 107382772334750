import { actionFactory } from "../../libs/actionFactory";
import {
  ACCEPT_IS_CHECKED,
  CHANGE_CURRENT_STEP,
  CREATE_COURSE,
  CREATE_MCQ,
  CREATE_QUESTION,
  CREATE_REPO,
  CREATE_REPO_LOADING,
  CURRENT_EDITING_TEST_DETAILS,
  DELETE_MCQ,
  DELETE_QUESTIONS,
  GET_MCQ,
  GET_QUESTION,
  ISPREVIEW,
  REORDER_MCQ,
  RESPOSITORY_STEP_ERROR_BANNER,
  SHOW_BANNER_ERROR,
  WIZARD_CLEAR,
  WIZARD_CURRENT_STEP,
  WIZARD_LOADER_FALSE,
  WIZARD_LOADER_TRUE,
  WIZARD_NEXT,
  WIZARD_PREV,
  WIZARD_STEP_FOUR,
  WIZARD_STEP_MCQ_DELETE,
  WIZARD_STEP_THREE,
  WIZARD_STEP_TWO_DELETE,
  WIZARD_STEP_TWO_EDIT,
  WIZARD_STEP_TWO_REARRANGE,
  WIZRAD_STEP_ONE,
  WIZRAD_STEP_TWO,
  WIZRAD_STEP_TWO_ADD,
} from "../actiontypes";
import {
  clearDraftCourseList,
  draftCallback,
  loadOwnedCoursesList,
} from "./courseAction";

export const wizardNext = (dispatch, stepData) => {
  if (stepData.currentStep < stepData.limit)
    dispatch({
      type: WIZARD_NEXT,
    });
};
export const stepOne = (wizardSteps, status) => {
  wizardSteps["status"] = status;
  return actionFactory({
    api: "post/api/create/course/",
    actionBody: {
      body: wizardSteps,
    },
    actionBase: CREATE_COURSE,
    failureToast: true,
    successToast: "The Course Has Been Saved Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({ type: WIZRAD_STEP_ONE, payload: resp["data"].data });
            dispatch({
              type: WIZARD_NEXT,
            });
            dispatch({ type: WIZARD_LOADER_FALSE });
          }
        })
        .catch((_) => {});
    },
  });
};
export const generalInfoStepAction = (dispatch, wizardSteps) => {
  dispatch(stepOne(wizardSteps.stepOne, "DRAFT"));
  dispatch({ type: WIZARD_LOADER_TRUE });
};

export const wizardPrev = (dispatch, stepData) => {
  if (stepData.currentStep > 0)
    dispatch({
      type: WIZARD_PREV,
    });
};
export const wizardCancel = (_dispatch, stepData) => {
  stepData.handleWizardClose();
};

export const isPreviewConstant = (payload) => ({
  type: ISPREVIEW,
  payload: payload,
});
export const isCheckedConstant = (payload) => ({
  type: ACCEPT_IS_CHECKED,
  payload: payload,
});
export const changeCurrentStep = (payload) => ({
  type: CHANGE_CURRENT_STEP,
  payload: payload,
});
export const wizardCreateModule = (dispatch, fieldValues, closeModule) => {
  const moduleData = {
    key: Date.now(),
    status: "ACTIVE",
    ...fieldValues,
  };
  dispatch({
    type: WIZRAD_STEP_TWO_ADD,
    payload: moduleData,
  });
  closeModule();
};

export const wizardEditModule = (dispatch, fieldValues, handleModeChange) => {
  dispatch({
    type: WIZARD_STEP_TWO_EDIT, // Replace with the appropriate action type for editing step two
    payload: fieldValues,
  });
  handleModeChange("closed");
};

export const modulesCreationStepAction = (dispatch, wizardSteps) => {
  // eslint-disable-next-line no-unused-vars
  const modules = wizardSteps?.stepTwo?.map(({ key, ...rest }) => rest);
  if (modules !== undefined || modules?.length === 0) {
    dispatch({ type: WIZARD_LOADER_TRUE });
    dispatch(stepTwo(modules, wizardSteps.stepOne.id));
  }
};

export const stepTwo = (modules, id) => {
  return actionFactory({
    api: "post/api/create/modules/?course_id=" + id,
    actionBody: {
      body: modules,
    },
    // actionBase: CREATE_COURSE,
    failureToast: true,
    successToast: "The modules have been created successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({ type: WIZRAD_STEP_TWO, payload: resp["data"].data });
            dispatch({
              type: WIZARD_NEXT,
            });
            dispatch({ type: WIZARD_LOADER_FALSE });
          }
        })
        .catch((_) => {});
    },
  });
};
export const createRepo = (repoDetails, setisLoading, setIsError) => {
  return actionFactory({
    api: "post/api/create/repositories/",
    actionBody: {
      body: repoDetails,
    },
    actionBase: CREATE_REPO,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({ type: WIZARD_STEP_THREE, payload: resp["data"].data });
            setisLoading(false);
            dispatch({ type: CREATE_REPO_LOADING, payload: false });
          }
        })
        .catch((err) => {
          setIsError(true);
          dispatch({
            type: SHOW_BANNER_ERROR, //depending upon what type you want error| warning | info
            payload: {
              id: RESPOSITORY_STEP_ERROR_BANNER,
              message: err.response.data.status.status_message,
            }, //Id for each component/modal keep it unique
          });
          dispatch({ type: CREATE_REPO_LOADING, payload: false });
        });
    },
  });
};
export const stepTwoDeleteModule = (id, fieldValues, setisLoading) => {
  return actionFactory({
    api: "delete/api/create/delete_module/?id=" + id,
    failureToast: true,
    successToast: "The module has been deleted successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: WIZARD_STEP_TWO_DELETE,
              payload: fieldValues,
            });
            setisLoading(false);
          }
        })
        .catch((_) => {});
    },
  });
};

export const wizardDeleteModule = (dispatch, fieldValues, setisLoading) => {
  if ("key" in fieldValues) {
    dispatch({
      type: WIZARD_STEP_TWO_DELETE,
      payload: fieldValues,
    });
    setisLoading(false);
  } else {
    dispatch(stepTwoDeleteModule(fieldValues.id, fieldValues, setisLoading));
  }
};

export const clearWizard = () => {
  return { type: WIZARD_CLEAR };
};

export const wizardLoading = (val) => {
  if (val) {
    return { type: WIZARD_LOADER_TRUE };
  }
  return { type: WIZARD_LOADER_FALSE };
};

export const setWizardCurrentStep = (step) => {
  return {
    type: WIZARD_CURRENT_STEP,
    payload: step,
  };
};

export const reloadDraftCourseDetails = (dispatch, id) => {
  dispatch(clearDraftCourseList());
  dispatch(
    loadOwnedCoursesList(
      id,
      { status: "DRAFT,REJECTED,IN_REVIEW" },
      draftCallback
    )
  );
};

//dispatch()

export const wizardCreateCourse = (wizardSteps) => {
  return actionFactory({
    api: `patch/api/courses/${wizardSteps.stepOne.id}/`,
    actionBody: {
      body: {
        status: "IN_REVIEW",
      },
    },
    failureToast: true,
    successToast: "Course submitted for review",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            wizardSteps.handleWizardClose();
          }
        })
        .catch((_err) => {});
    },
  });
};

export const rearrangeWizardStepTwo = (payload) => ({
  type: WIZARD_STEP_TWO_REARRANGE,
  payload,
});

export const stepFour = (
  { INITIAL_FORM_VALUES, creator, course },
  setDataForReset,
  setSaveButtonLoader,
  setIfSaveButtonValid
) => {
  // wizardSteps["status"] = status;
  return actionFactory({
    api: "post/api/create/test/",
    actionBody: {
      body: {
        ...INITIAL_FORM_VALUES,
        creator,
        course,
        module: null, //when we will implement modular level then we need to send not null
      },
    },
    actionBase: CREATE_MCQ,
    failureToast: true,
    successToast: "The MCQ detail Has Been Saved Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({ type: WIZARD_STEP_FOUR, payload: resp["data"].data });
            dispatch({
              type: CURRENT_EDITING_TEST_DETAILS,
              payload: resp["data"].data,
            });
            setDataForReset(resp["data"].data);
            setSaveButtonLoader(false);
            setIfSaveButtonValid(false);
          }
        })
        .catch((_) => {});
    },
  });
};

export const wizardDeleteTest = (data, setisLoading) => {
  return actionFactory({
    api: "delete/api/create/delete_test/",
    failureToast: true,
    successToast: "The Test has been deleted successfully",
    actionBody: {
      body: data,
    },
    actionBase: DELETE_MCQ,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: WIZARD_STEP_MCQ_DELETE,
              payload: data,
            });
            setisLoading(false);
          }
        })
        .catch((_) => {});
    },
  });
};

export const wizardGetParticularTest = (
  id,
  setDataForReset = () => {},
  setIsLoading
) => {
  return actionFactory({
    api: "get/api/tests/" + id,
    failureToast: true,
    actionBase: GET_MCQ,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: CURRENT_EDITING_TEST_DETAILS,
              payload: resp["data"].data,
            });
            setDataForReset(resp["data"].data);
          }
        })
        .catch((_) => {
          setIsLoading(false);
        });
    },
  });
};
export const getMcqs = (id, params = {}, setLoading = () => {}) => {
  // wizardSteps["status"] = status;
  return actionFactory({
    api: "get/api/create/get_mcq/?test_id=" + id,
    actionBody: {
      params: { ...params },
    },
    actionBase: GET_MCQ,
    failureToast: true,
    // successToast: "The MCQ detail Has Been Saved Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: GET_MCQ,
              payload: resp["data"].data,
            });
          } else {
            setLoading(false);
          }
        })
        .catch((_) => {});
    },
  });
};

export const createQuestion = (data, setSaveButtonLoader) => {
  return actionFactory({
    api: "post/api/create/mcq/",
    failureToast: true,
    actionBase: CREATE_QUESTION,
    successToast: "Question added successfully",
    actionBody: {
      body: data,
    },
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise.then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          setSaveButtonLoader(false);
          dispatch(getMcqs(resp["data"].data.test));
        }
      });
    },
  });
};

export const mcqReorder = (testId, requestData) => {
  return actionFactory({
    api: "patch/api/create/reorder_mcq/",
    actionBody: {
      body: requestData,
    },
    actionBase: REORDER_MCQ,
    failureToast: true,
    successToast: "MCQ reordering successful",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch(getMcqs(testId));
          }
        })
        .catch((_err) => {});
    },
  });
};

export const deleteTestDraftFromWindow = (id, handleClose) => {
  return actionFactory({
    api: "delete/api/create/delete_test/",
    failureToast: true,
    actionBody: {
      body: {
        id: id,
      },
    },
    // successToast: "Test Deleted Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            handleClose(); // closes pop up
            window.close(); // used for closing browser tab
          }
        })
        .catch((_) => {});
    },
  });
};
export const deleteQuestions = (data, callback) => {
  return actionFactory({
    api: "delete/api/create/delete_mcq/",
    actionBody: {
      body: data,
    },
    actionBase: DELETE_QUESTIONS,
    failureToast: true,
    successToast: "Question deleted successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) =>
      callback(respPromise, dispatch, getState),
  });
};
export const getQuestion = (id) => {
  return actionFactory({
    api: `get/api/create/get_question/?question_id=${id}`,
    actionBody: {},
    actionBase: GET_QUESTION,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise.then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          dispatch({
            type: GET_QUESTION,
            payload: resp["data"].data,
          });
        }
      });
    },
  });
};
