/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import Spinner from "../Spinner/Spinner";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./SearchBar.scss";
import { mapOperatorValuesToText } from "../../libs/utils";
import { useDispatch } from "react-redux";
import constants from "../../constants";

/**
 * SearchBar component.
 *
 * The SearchBar component provides a search input with filter options to search and filter data.
 * It allows users to enter a search query and select filters to refine their search.
 * The component supports dynamically populated filter options based on the provided filterValues prop.
 * When the user performs a search, the onSearch prop is called with the selected filters, search text, and a setLoadingStatus callback.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {FilterValue[]} props.filterValues - An array of filter objects.
 *   Each filter object should have the following properties:
 *   - filter: The name of the filter.
 *   - values: An array of values for the filter.
 *   - operators: An array of operators for the filter.
 *   - fetchValuesFromApi: A boolean indicating whether to fetch values from an API.
 *   - fetchValuesFunction: A callback function for fetching values from an API.
 * @param {Function} props.onSearch - A callback function to handle the search action.
 *   The function should accept three parameters:
 *   - params: An object containing the selected filters and search text.
 *   - setLoadingStatus: A callback function to update the loading status.
 *   - setLoadingStatusCallback: A callback function to handle loading status updates.
 * @returns {JSX.Element} SearchBar component JSX.
 *
 * @example
 *
 * // Example usage of the SearchBar component
 * const filterValues = [
 *   {
 *     filter: "Category",
 *     values: ["Horror", "Sci-fi", "Fiction"],
 *     operators: ["="],
 *     fetchValuesFromApi: false, // Set to true if values are fetched from an API
 *     fetchValuesFunction: undefined, // Provide a callback for fetching values from an API
 *   },
 *   {
 *     filter: "Author",
 *     operators: ["="],
 *     fetchValuesFromApi: true,
 *     fetchValuesFunction: (dispatch, callback) => {
 *      // return a promise to callback
 *     },
 *   },
 *   // Add more filter options as needed
 * ];
 *
 * function handleSearch(params, setLoadingStatus, setLoadingStatusCallback) {
 *   // Perform search logic with the provided params
 *   // Set loading status using setLoadingStatus callback if needed
 *   // Call setLoadingStatusCallback to handle loading status updates
 * }
 *
 * function App() {
 *   return (
 *     <div>
 *       <SearchBar filterValues={filterValues} onSearch={handleSearch} />
 *     </div>
 *   );
 * }
 *
 */

function SearchBar({ filterValues, onSearch, onClear }) {
  // State Variables
  const [searchText, setSearchText] = useState(""); // Holds the value of the search input
  const [selectedFilters, setSelectedFilters] = useState({}); // Holds the selected filters
  const [showDropdown, setShowDropdown] = useState(false); // Controls the visibility of the filter dropdown
  const [dropdownContentKey, setDropdownContentKey] = useState("filter"); // Represents the currently displayed content in the dropdown
  const [loadingStatus, setLoadingStatus] = useState(false); // Indicates the loading state of the search
  const [loadingValuesStatus, setLoadingValuesStatus] = useState(false); // Indicates the loading state of the dropdown values
  const [valuesFromApi, setValuesFromApi] = useState([]);
  // Reference Variable
  const dropdownRef = useRef(null); // Reference to the filter dropdown DOM element
  // Hooks
  const navigate = useNavigate(); // React Router navigate function
  const location = useLocation();
  const [searchParams] = useSearchParams(); // Obtain Query Params
  // Dispatch Instance
  const dispatch = useDispatch();
  // Converts selected filters to query parameters
  function filterToParams(selectedFilters) {
    const params = {};

    const operatorMapping = {
      "<": "lt",
      ">": "gt",
      "<=": "lte",
      ">=": "gte",
      "=": "",
      "!=": "not ",
    };

    for (const filter in selectedFilters) {
      // eslint-disable-next-line no-prototype-builtins
      if (selectedFilters.hasOwnProperty(filter)) {
        const { operator, values } = selectedFilters[filter];
        const mappedOperator = operatorMapping[operator];
        const mappedValue = values.toLowerCase();
        params[filter] = `${mappedOperator}${mappedValue}`;
      }
    }

    return params;
  }

  // Handles the search functionality
  const handleSearch = () => {
    // Check if there are filters selected and if there is search text
    const hasFilters = Object.keys(selectedFilters).length > 0;
    const hasSearchText = searchText !== "";

    // Create an empty object to store the parameters
    let params = {};

    // If filters are selected, convert them to params
    if (hasFilters) {
      params = filterToParams(selectedFilters);
    }
    // If there is search text, add it to the params
    if (hasSearchText) {
      params.search = searchText;
    }

    // Set loading status and trigger onSearch with the params
    // setLoadingStatus(true);
    onSearch(params, setLoadingStatus);
    // Generate query string from the params
    const paramsQuery = new URLSearchParams(params).toString();

    // Update the URL with the query string
    navigate(location.pathname + "?" + paramsQuery);
  };

  // Handles input change in the search bar
  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  // Clears the search input and selected filters
  const handleClearInput = () => {
    setSearchText("");
    setSelectedFilters({});
    setDropdownContentKey("filter");
    if (onClear) {
      onClear("", setLoadingStatus);
    }
  };

  // Toggles the visibility of the filter dropdown
  const handleToggleDropdown = () => {
    if (
      dropdownContentKey === "filter" &&
      Object.keys(selectedFilters).length === filterValues.length
    ) {
      // All filters have been selected, do not show the dropdown
      return;
    }
    setShowDropdown(!showDropdown);
  };

  // Selects a filter from the dropdown
  const handleSelectFilter = (filter) => {
    if (selectedFilters[filter]) {
      // Filter is already selected, do not add it again
      return;
    }
    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      [filter]: {
        operator: "",
        values: "",
      },
    }));
    setDropdownContentKey("operator"); // Change dropdown content to "operator"
    // setShowDropdown(false);
  };

  // Selects an operator for a filter
  const handleSelectOperator = (operator) => {
    const lastSelectedFilter = Object.keys(selectedFilters).pop();
    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      [lastSelectedFilter]: {
        ...prevSelectedFilters[lastSelectedFilter],
        operator: operator,
      },
    }));
    setDropdownContentKey("values"); // Change dropdown content to "values"
    // setShowDropdown(false);
  };

  // Selects a value for a filter
  const handleSelectValue = (value) => {
    const lastSelectedFilter = Object.keys(selectedFilters).pop();
    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      [lastSelectedFilter]: {
        ...prevSelectedFilters[lastSelectedFilter],
        values: value,
      },
    }));
    setDropdownContentKey("filter"); // Change dropdown content back to "filter"
    // setShowDropdown(false);
  };

  // Handles click outside the dropdown to close it
  const handleClickOutside = (e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      !e.target.classList.contains("dropdownItem")
    ) {
      setShowDropdown(false);
    }
  };

  // Removes a selected filter
  const handleRemoveFilter = (filter) => {
    setSelectedFilters((prevSelectedFilters) => {
      const updatedFilters = { ...prevSelectedFilters };
      delete updatedFilters[filter];
      return updatedFilters;
    });
    setDropdownContentKey("filter");
    if (onClear) {
      onClear(filter, setLoadingStatus);
    }
  };

  // Handles key press on Search Bar
  const handleKeyDown = (event) => {
    // Hide visibility of dropdown on Escape press
    if (event.key === "Escape") {
      setShowDropdown(false);
    }
    // Show dropdown on Ctrl (or Command key on Mac) + Space key press
    if ((event.ctrlKey || event.metaKey) && event.key === " ") {
      setShowDropdown(true);
    }
    // Handle search on Enter press
    if (event.key === "Enter" || event.key === "Return") {
      if (showDropdown) {
        const dropdownItemsInitial = document.querySelectorAll(".dropdownItem");
        let hasFocusClass = false; // Initialize a variable to track if any element has the "focus" class
        dropdownItemsInitial.forEach((item) => {
          if (item.classList.contains("focus")) {
            hasFocusClass = true;
          }
        });
        if (!hasFocusClass) {
          handleSearch();
        }
      } else {
        handleSearch();
      }
    }
    // Delete selected filters on Backspace press
    if (
      (event.key === "Backspace" || event.keyCode === 8) &&
      event.target.value === ""
    ) {
      const lastSelectedFilter = Object.keys(selectedFilters).pop();
      if (lastSelectedFilter !== undefined) {
        if (
          dropdownContentKey === "filter" &&
          Object.keys(selectedFilters).length > 0
        ) {
          setDropdownContentKey("values");
          setSelectedFilters((prevSelectedFilters) => {
            const updatedFilters = { ...prevSelectedFilters };
            updatedFilters[lastSelectedFilter].values = ""; // Clear the value field of the last selected filter
            return updatedFilters;
          });
        } else {
          setSelectedFilters((prevSelectedFilters) => {
            const updatedFilters = { ...prevSelectedFilters };
            delete updatedFilters[lastSelectedFilter]; // Delete the filter
            return updatedFilters;
          });
          setDropdownContentKey("filter");
        }
      }
    }
  };

  // Handle Arrow key press
  const handleArrowKeys = (event) => {
    // Navigate through drodown items using arrow key up or down
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault(); // Prevents the default behavior of arrow keys scrolling the page

      // Get all the dropdown items
      const dropdownItems = document.querySelectorAll(".dropdownItem");
      if (dropdownItems.length > 0) {
        const currentIndex = Array.from(dropdownItems).findIndex((item) =>
          item.classList.contains("focus")
        );
        // Remove the focus class from the currently focused item
        if (currentIndex >= 0) {
          dropdownItems[currentIndex].classList.remove("focus");
        }

        let newIndex;

        // Calculate the new index based on the arrow key pressed
        if (event.key === "ArrowDown") {
          newIndex = (currentIndex + 1) % dropdownItems.length;
        } else if (event.key === "ArrowUp") {
          newIndex =
            (currentIndex - 1 + dropdownItems.length) % dropdownItems.length;
        }

        // Add the focus class to the new index item
        dropdownItems[newIndex].classList.add("focus");
      }
    }
    if (event.key === "Enter" || event.key === "Return") {
      const dropdownItems = document.querySelectorAll(".dropdownItem");
      if (dropdownItems.length > 0) {
        const item = Array.from(dropdownItems).find((item) =>
          item.classList.contains("focus")
        );
        if (item) {
          switch (dropdownContentKey) {
            case "filter":
              handleSelectFilter(item?.textContent);
              break;
            case "operator":
              // eslint-disable-next-line no-case-declarations
              const textItemDiv =
                document.querySelectorAll(".dropdownItem-text")[0];
              handleSelectOperator(textItemDiv?.textContent);
              break;
            case "values":
              if (item?.textContent !== constants.EMPTY_DATA)
                handleSelectValue(item?.textContent);
              else handleClearInput();
              break;
          }
        }
      }
    }
  };

  // Function to render filter options based on selected filters
  const renderFilterOptions = () => {
    return filterValues.map((filter, index) => {
      if (!selectedFilters[filter.filter]) {
        return (
          <DropdownItem
            key={index}
            index={index}
            label={filter.filter}
            handleClick={() => handleSelectFilter(filter.filter)}
          />
        );
      }
      return null;
    });
  };

  // Function to render operator options based on the last selected filter
  const renderOperatorOptions = () => {
    const lastSelectedFilter = Object.keys(selectedFilters).pop();
    const operator = filterValues.find(
      (filter) => filter.filter === lastSelectedFilter
    )?.operators;

    if (operator) {
      return operator.map((operator, index) => {
        return (
          <DropdownItem
            key={index}
            index={index}
            label={operator}
            labelText={true}
            handleClick={() => handleSelectOperator(operator)}
          />
        );
      });
    }
    return null;
  };

  // Function to render values options based on the last selected filter
  const renderValuesOptions = () => {
    const lastSelectedFilter = Object.keys(selectedFilters).pop();
    const filter = filterValues.find(
      (filter) => filter.filter === lastSelectedFilter
    );

    if (filter) {
      if (filter.values) {
        // Render the predefined values as dropdown options
        return filter.values.map((value, index) => (
          <DropdownItem
            key={index}
            index={index}
            label={value}
            handleClick={() => handleSelectValue(value)}
          />
        ));
      } else {
        return loadingValuesStatus ? (
          <>
            <div className="values-loading-container">
              <Spinner color={"#a1a09e"} small={true} />
            </div>
          </>
        ) : valuesFromApi.length > 0 ? (
          valuesFromApi.map((value, index) => (
            <DropdownItem
              key={index}
              index={index}
              label={value}
              handleClick={() => handleSelectValue(value)}
            />
          ))
        ) : (
          <DropdownItem
            label={constants.EMPTY_DATA}
            handleClick={handleClearInput}
          />
        );
      }
    }
    return null;
  };

  // Callback function that executes after the API call to fetch values has been made. It recieves a promise
  const valuesCallback = (respPromise) => {
    respPromise
      .then((resp) => {
        setValuesFromApi(resp["data"].data);
        setLoadingValuesStatus(false);
      })
      .catch((_) => {});
  };

  useEffect(() => {
    if (dropdownContentKey == "values") {
      var lastSelectedFilter = Object.keys(selectedFilters).pop();
      var filter = filterValues.find(
        (filter) => filter.filter === lastSelectedFilter
      );
      if (filter) {
        // Check if the filter has the 'fetchValuesFromApi' property
        if (filter.fetchValuesFromApi) {
          setLoadingValuesStatus(true);
          filter.fetchValuesFunction(dispatch, valuesCallback);
        }
      }
    }
  }, [dropdownContentKey]);

  // Function to render the appropriate dropdown content based on dropdownContentKey
  const renderDropdownContent = () => {
    if (dropdownContentKey === "filter") {
      // Check if all filters have been selected
      const allFiltersSelected =
        Object.keys(selectedFilters).length === filterValues.length;
      if (allFiltersSelected) setShowDropdown(false);
      return renderFilterOptions();
    } else if (dropdownContentKey === "operator") {
      return renderOperatorOptions();
    } else if (dropdownContentKey === "values") {
      return renderValuesOptions();
    }

    return null;
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleArrowKeys);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleArrowKeys);
    };
  }, [dropdownContentKey, renderDropdownContent]);

  useEffect(() => {
    const queryParams = {};

    // Convert searchParams to an object
    for (const [param, value] of searchParams.entries()) {
      const filterExists = filterValues.some(
        (filterValue) => filterValue.filter === param
      );
      if (filterExists || param === "search") queryParams[param] = value;
    }

    const operatorMapping = {
      lte: "<=",
      lt: "<",
      gte: ">=",
      gt: ">",
    };

    const updatedSelectedFilters = {};

    for (const filter in queryParams) {
      // eslint-disable-next-line no-prototype-builtins
      if (queryParams.hasOwnProperty(filter)) {
        let operator = "=";
        let values = queryParams[filter];

        // Check if the value starts with an operator
        for (const key in operatorMapping) {
          if (values.startsWith(key)) {
            operator = operatorMapping[key];
            values = values.slice(key.length); // Remove the operator from the value
            break;
          }
        }

        updatedSelectedFilters[filter] = {
          operator,
          values,
        };
      }
    }

    const { search, ...filterParams } = updatedSelectedFilters;

    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      ...filterParams,
    }));

    if (search) {
      setSearchText(search.values);
    }

    // Call function to update the list through API if queryParams is not empty
    if (Object.keys(queryParams).length > 0) {
      onSearch({
        ...queryParams,
      });
    }
  }, []);

  // Returns dropdown Item
  const DropdownItem = ({ index, label, handleClick, labelText }) => {
    return (
      <div
        data-testid={`dropdownItem-${index}`}
        className="dropdownItem"
        onClick={handleClick}
      >
        <div className="dropdownItem-text">{label}</div>
        {labelText && (
          <div className="labelText">{mapOperatorValuesToText(label)}</div>
        )}
      </div>
    );
  };

  return (
    <div data-testid="searchBar-container" className="searchBar-container">
      <div className="searchBarFilterSelected-Container">
        {/* Display selected filters */}
        {Object.keys(selectedFilters).map((filter, index) => (
          <div
            key={filter}
            data-testid={`selectedFilter-${index}`}
            className="selectedFilter"
          >
            <div className="filter-item filter">{filter}</div>
            {selectedFilters[filter].operator && (
              <div className="filter-item operator">
                {selectedFilters[filter].operator}
              </div>
            )}
            {selectedFilters[filter].values &&
              selectedFilters[filter].values.length > 0 && (
                <div className="filter-item values">
                  {selectedFilters[filter].values}
                  <div
                    data-testid="cancelIcon"
                    className="cancelIcon"
                    onClick={() => handleRemoveFilter(filter)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              )}
          </div>
        ))}
      </div>
      <div className="searchBar-input" ref={dropdownRef}>
        {/* Input for search text */}
        <input
          data-testid="searchBar-input-field"
          type="text"
          placeholder={
            Object.keys(selectedFilters).length > 0
              ? ""
              : "Search or filter results"
          }
          onKeyDown={handleKeyDown}
          value={searchText}
          onChange={handleInputChange}
          onClick={() => {
            if (filterValues) {
              handleToggleDropdown();
            }
          }}
          style={{ fontSize: "14px", color: "#000", marginLeft: "15px" }}
        />
        {/* Clear input icon */}
        {(searchText || Object.keys(selectedFilters).length > 0) && (
          <div
            data-testid={"cancelSearchButton"}
            className="cancelSearchButton"
            onClick={handleClearInput}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        {/* Dropdown menu */}
        {showDropdown && (
          <div data-testid="dropdownMenu" className="dropdownMenu">
            {renderDropdownContent()}
            {/* Display a message when all filters are applied */}
            {dropdownContentKey === "filter" &&
              Object.keys(selectedFilters).length === filterValues.length && (
                <div className="dropdownItem allFiltersApplied">
                  All filters applied
                </div>
              )}
          </div>
        )}
      </div>
      <div
        onClick={handleSearch}
        data-testid="searchBar-search"
        className="searchBar-search"
      >
        {/* Show loading spinner or search icon */}
        {loadingStatus ? (
          <Spinner small={true} /> // Display CircularProgress if loadingStatus is true
        ) : (
          <button data-testid="searchButton" className="searchButton">
            <FontAwesomeIcon icon={faSearch} style={{ fontWeight: "bold" }} />
          </button> // Show search icon if loadingStatus is false
        )}
      </div>
    </div>
  );
}

// PropTypes definition for the SearchBar component
SearchBar.propTypes = {
  filterValues: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.string.isRequired, // The name of the filter
      values: PropTypes.arrayOf(PropTypes.string), // The list of values for the filter
      operators: PropTypes.arrayOf(PropTypes.string).isRequired, // The list of operator for the filter
      fetchValuesFromApi: PropTypes.bool, // Indicates whether to fetch values from API
      fetchValuesFunction: PropTypes.func, // Callback function for fetching values
    })
  ), // The array of filter objects,

  onSearch: PropTypes.func.isRequired, // Callback function to handle search
};
SearchBar.defaultProps = {
  filterValues: [],
};
export default SearchBar;
