import "./UserProfileTabLayout.scss";
import { Tabs } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import AuthoredTabScreen from "./TabScreen/AuthoredTabScreen/AuthoredTabScreen";
import EnrolledTabScreen from "./TabScreen/EnrolledTabScreen/EnrolledTabScreen";
const { TabPane } = Tabs;

const UserProfileTabLayout = ({
  activeTab,
  setActiveTab,
  authoredCount,
  completedCount,
  enrolledCount,
}) => {
  const tabConfigurations = [
    {
      key: "authored",
      icon: <FormOutlined />,
      label: "Authored",
      count: authoredCount,
      content: <AuthoredTabScreen />,
      shouldRender: true,
    },
    {
      key: "enrolled",
      icon: <FormOutlined />,
      label: "Enrolled",
      count: `${completedCount} / ${enrolledCount}`,
      content: <EnrolledTabScreen />,
      shouldRender: true,
    },
  ];
  const urlId = location.hash.split("/")[2];
  const navigate = useNavigate();
  const handleTabChange = (key) => {
    setActiveTab(key);
    navigate(`/users/${urlId}/${key}`);
  };
  return (
    <div className="userProfileTabLayout-container" data-testid="profileTabs">
      <Tabs
        defaultActiveKey="authored"
        activeKey={activeTab}
        className="tabs-container"
        onChange={handleTabChange}
      >
        {tabConfigurations.map((tab) =>
          tab.shouldRender ? (
            <TabPane
              key={tab.key}
              tab={
                <div className="container">
                  <span>
                    {tab.icon}
                    {tab.label}
                  </span>
                  {tab.label !== "Profile" ? (
                    <span className="Counts">{tab.count}</span>
                  ) : null}
                </div>
              }
              className={activeTab === tab.key ? "active-tab" : "inactive-tab"}
              forceRender={true}
            >
              {activeTab === tab.key && tab.content}
            </TabPane>
          ) : null
        )}
      </Tabs>
    </div>
  );
};

export default UserProfileTabLayout;
