import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";

function ExpoCourseTabs(props) {
  const { activeTab, setActiveTab } = props;
  const urlId = location.hash.split("/")[2];
  const navigate = useNavigate();

  const onItemClickHandle = (id) => {
    setActiveTab(id);
    navigate(`/courses/${urlId}/public/${id}/`);
  };

  return (
    <div className="course-tab">
      <div className="courseTabs-container">
        <div
          data-testid="tabItem"
          className={`tabItem ${activeTab === "content" ? "active" : ""}`}
          onClick={() => {
            onItemClickHandle("content");
          }}
        >
          <FontAwesomeIcon className="table-icon" icon={faTableList} />
          Content
        </div>
        <div
          data-testid="tabItem"
          className={`tabItem ${activeTab === "course" ? "active" : ""}`}
          onClick={() => {
            onItemClickHandle("course");
          }}
        >
          <FontAwesomeIcon className="table-icon" icon={faFileLines} />
          Course Info
        </div>
      </div>
    </div>
  );
}

export default ExpoCourseTabs;
