// import React from "react";
import { Button } from "antd";
import { ReactComponent as PreviewMode } from "../../../../assets/Preview.svg";
import "./McqTestCreation.scss";
import McqTabsDisplay from "./McqTabsDisplay";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isEmpty, isNull } from "lodash";
import DeleteConfirmTest from "./DeleteConfirmTest";
import {
  currentEditingQuestions,
  currentEditingTestDetails,
} from "../../../../store/selectors/wizardSelectors";
import { Link, useLocation } from "react-router-dom";
import { getMcqs } from "../../../../store/actions/wizardActions";
import { EyeFilled } from "@ant-design/icons";

const McqTestCreation = () => {
  const dispatch = useDispatch();
  // State used for delete pop screen
  const [isModalOpen, setIsModalOpen] = useState(false);
  const data = useSelector(currentEditingTestDetails);
  const questionData = useSelector(currentEditingQuestions)?.results;
  const location = useLocation();
  // functions to handle pop screen
  const openDeleteConfirmModal = () => {
    setIsModalOpen(true);
  };
  const closeDeleteConfirmModal = () => {
    setIsModalOpen(false);
  };
  // used for tab switching
  const [activeTab, setActiveTab] = useState("details");

  const courseId = location.pathname.split("/")[2];
  const testId = location.pathname.split("/")[4];

  useEffect(() => {
    if (testId) {
      dispatch(getMcqs(testId));
    }
  }, [testId]);

  return (
    <div className="McqTestCreation-container">
      <DeleteConfirmTest
        open={isModalOpen}
        handleClose={closeDeleteConfirmModal}
        data={data}
      />
      <div className="McqTestCreation-header">
        <div className="McqTestCreation-title">New Test</div>
        <div>
          <Button
            className="delete-button"
            onClick={openDeleteConfirmModal}
            disabled={!data?.id}
          >
            Delete
          </Button>
          {!isNull(data?.id) && !isEmpty(questionData) ? (
            <Link
              to={`/courses/${courseId}/test/${testId}/preview`}
              target="_blank"
            >
              <Button
                color="#696969"
                className="preview-button"
                icon={<PreviewMode style={{ color: "#696969" }} />}
              >
                <span style={{ marginLeft: "7px" }}>Preview</span>
              </Button>
            </Link>
          ) : (
            <Button
              color="rgba(0, 0, 0, 0.04)"
              disabled={true}
              icon={<EyeFilled style={{ color: "#696969" }} />}
            >
              <span
                style={{ marginLeft: "7px", color: "rgba(105, 105, 105, 1)" }}
              >
                Preview
              </span>
            </Button>
          )}
        </div>
      </div>
      <div className="McqTestCreation-content">
        <McqTabsDisplay
          createdMcqTestId={data?.id}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </div>
  );
};

export default McqTestCreation;

// preview button icon color not changing
