import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faSwatchbook } from "@fortawesome/free-solid-svg-icons";

import { TeamOutlined } from "@ant-design/icons";
export const sidenavbar = {
  COMMON: [
    {
      to: "/",
      name: "Dashboard",
      key: "candidate_dashboard",
      icon: <FontAwesomeIcon icon={faChartPie} />,
    },
  ],
  CANDIDATE: [
    {
      to: "/courses",
      name: "Courses",
      key: "candidate_course",
      icon: (
        <FontAwesomeIcon icon={faSwatchbook} style={{ color: "#ffffff" }} />
      ),
    },
  ],
  PORTAL_ADMIN: [
    {
      to: "/courses/all",
      name: "Courses",
      key: "candidate_course",
      icon: (
        <FontAwesomeIcon icon={faSwatchbook} style={{ color: "#ffffff" }} />
      ),
    },
    {
      to: "/users",
      name: "Users",
      key: "admin_users",
      icon: <TeamOutlined style={{ fontSize: "21px" }} />,
    },
  ],
  CREATOR: [
    {
      to: "/courses/all",
      name: "Courses",
      key: "candidate_course",
      icon: (
        <FontAwesomeIcon icon={faSwatchbook} style={{ color: "#ffffff" }} />
      ),
    },
  ],
  HR: [
    {
      to: "/courses",
      name: "Courses",
      key: "candidate_course",
      icon: (
        <FontAwesomeIcon icon={faSwatchbook} style={{ color: "#ffffff" }} />
      ),
    },
    {
      to: "/users",
      name: "Users",
      key: "admin_users",
      icon: <TeamOutlined style={{ fontSize: "21px" }} />,
    },
  ],
};

export const CollapsedRoutes = ["/courses/:id"];
