import { Button } from "antd";
import "./EnrolledCoursesEmptyContainer.scss";
import { useNavigate } from "react-router-dom";
const EnrolledCoursesEmptyContainer = () => {
  const navigate = useNavigate();
  const onClickHandle = () => {
    navigate("/courses");
  };
  return (
    <>
      <div
        className="enrolled-courses-empty-container"
        data-testid="enrolled-courses-empty-container"
      >
        <div className="enrolled-courses-header">
          <div className="enrolled-title">Start by enrolling a course</div>
          <Button
            onClick={onClickHandle}
            className="explore-button"
            data-testid="explore-button"
          >
            Explore Courses
          </Button>
        </div>
      </div>
    </>
  );
};
export default EnrolledCoursesEmptyContainer;
