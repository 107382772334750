/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { userSelector } from "../../store/selectors/authSelectors";
import SearchBar from "../../components/SearchBar/SearchBar";
import CoursesTabLayout from "../../features/CourseListFeature/CoursesTabLayout/CoursesTabLayout";
import { searchPropsByTabKey } from "../../constants/SearchBarProps";
import { useLocation } from "react-router";
import AllCourseTabScreen from "../../features/CourseListFeature/CoursesTabLayout/TabScreen/AllCourseTabScreen/AllCourseTabScreen";

import "./CourseListPage.scss";
import CreateCourseWizard from "../../features/CourseListFeature/WizardCourseSteps/CreateCourseWizard";
import {
  clearWizard,
  reloadDraftCourseDetails,
} from "../../store/actions/wizardActions";
import Spinner from "../../components/Spinner/Spinner";
import {
  clearDraftCourseList,
  draftCallback,
  loadOwnedCoursesList,
} from "../../store/actions/courseAction";
import { draftCoursesSelector } from "../../store/selectors/coursesSelectors";
import CourseCreateLimitModal from "../../features/CourseListFeature/CourseCreateLimitModal/CourseCreateLimitModal";
import { wizardIsUpdatedSelector } from "../../store/selectors/wizardSelectors";
import { groupArraySelector } from "../../store/selectors/authSelectors";
import constants from "../../constants";
function CourseListPage() {
  // Get user role, id from Redux store
  const { role, id } = useSelector(userSelector);
  const groupsArr = useSelector(groupArraySelector);
  const isUpdated = useSelector(wizardIsUpdatedSelector);
  const draftCourses =
    groupsArr.indexOf(constants.PORTAL_ADMIN) === -1 &&
    groupsArr.indexOf(constants.CREATORS) === -1
      ? []
      : useSelector(draftCoursesSelector);

  // State Variables
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [searchKey, setSearchKey] = useState(Date.now());
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      groupsArr.includes(constants.PORTAL_ADMIN) ||
      groupsArr.includes(constants.CREATORS) ||
      groupsArr.includes(constants.HR)
    ) {
      dispatch(
        loadOwnedCoursesList(
          id,
          { status: "DRAFT,IN_REVIEW,REJECTED" },
          draftCallback
        )
      );
      dispatch(clearDraftCourseList());
    }
  }, []);

  useEffect(() => {
    // Set the active tab based on the current URL
    const pathSegments = (location.pathname + location.hash).split("/");
    const tabSegmentIndex = pathSegments.findIndex(
      (segment) =>
        segment.toLowerCase() === "all" ||
        segment.toLowerCase() === "owned" ||
        segment.toLowerCase() === "approval"
    );
    if (tabSegmentIndex !== -1) {
      const tabValue = pathSegments[tabSegmentIndex].toLowerCase();
      setActiveTab(tabValue);
    }
  }, []);

  useEffect(() => {
    if (activeTab !== null && draftCourses !== null) {
      setIsLoading(false);
    }
  }, [activeTab, draftCourses]);

  // Search Function
  const handleSearch = (params, _setLoadingStatus) => {
    // Perform search based on the active tab
    searchPropsByTabKey[activeTab].onSearch(params, dispatch, id);
  };

  // Close wizard function
  const handleWizardClose = () => {
    if (isUpdated) {
      reloadDraftCourseDetails(dispatch, id);
    }
    setIsWizardOpen(false);
    dispatch(clearWizard());
  };

  const handleWizardOpen = () => {
    if (draftCourses?.length >= 3) {
      handleLimitModalOpen();
    } else {
      setIsWizardOpen(true);
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  const [isLimitModalOpen, setIsLimitModal] = useState(false);

  const handleLimitModalOpen = () => {
    setIsLimitModal(true);
  };

  const handleLimitModalClose = () => {
    setIsLimitModal(false);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <CourseCreateLimitModal
        open={isLimitModalOpen}
        handleClose={handleLimitModalClose}
      />
      <CreateCourseWizard
        open={isWizardOpen}
        handleWizardClose={handleWizardClose}
      />
      <div className="courseListPage-container">
        <div className="courseListPage-header">
          <div
            className="courseListPage-title"
            data-testid="courseListPage-title"
          >
            Courses
          </div>
          {groupsArr.includes(constants.PORTAL_ADMIN) ||
          groupsArr.includes(constants.CREATORS) ? (
            // Render "Create Course" button for specific roles
            <button
              className="createCourseButton"
              onClick={() => handleWizardOpen()}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="createCourseButton-icon"
              />
              Create
            </button>
          ) : null}
        </div>
        <div className="courseList-searchBar-container">
          <SearchBar
            key={searchKey}
            filterValues={searchPropsByTabKey[activeTab].filterValues}
            onSearch={(params, _) => {
              // Handle search event
              handleSearch(params);
            }}
          />
        </div>
        <div className="courseList-contentContainer">
          {groupsArr.includes(constants.CANDIDATE) && groupsArr.length == 1 ? (
            <div style={{ marginTop: "30px" }}>
              {/* Render AllCourseTabScreen for CANDIDATE role */}
              <AllCourseTabScreen />
            </div>
          ) : (
            <CoursesTabLayout
              role={role}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setSearchKey={setSearchKey}
              setIsWizardOpen={setIsWizardOpen}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default CourseListPage;
