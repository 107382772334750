/* eslint-disable no-unused-vars */
import { Button } from "antd";
import "./QuestionListHeader.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { currentEditingTestDetails } from "../../../../store/selectors/wizardSelectors";
import Vector from "../../../../assets/Vector.svg";

const QuestionListHeader = ({ onStateChange }) => {
  const navigate = useNavigate();
  const mcqListId = useSelector(currentEditingTestDetails);
  const courseId = location.hash.split("/")[2];
  const [isReorderSectionOpen, setIsReorderSectionOpen] = useState(false);
  const handleOnClickAddQuestionButton = () => {
    navigate(`/courses/${courseId}/test/${mcqListId.id}/manager?id=new`);
  };

  const handleChangeQuestionOrder = () => {
    setIsReorderSectionOpen(true);
    onStateChange(true);
  };

  return (
    <div className="questionList-header">
      <div className="questionList-header-title">Questions manager</div>
      <div className="questionList-header-functions">
        <div onClick={handleChangeQuestionOrder} className="change-order">
          <img src={Vector} />
          Change question order
        </div>
        <div>
          <Button
            className="add-question-button"
            onClick={handleOnClickAddQuestionButton}
          >
            Add Question
          </Button>
        </div>
      </div>
    </div>
  );
};
export default QuestionListHeader;
