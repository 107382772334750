import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SelectedUserSelector } from "../../store/selectors/adminSelectors";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../components/Spinner/Spinner";
import { groupArraySelector } from "../../store/selectors/authSelectors";
import UserProfileTabLayout from "../../features/UserProfileFeature/UserProfileTabLayout/UserProfileTabLayout";
import "./UserProfilePage.scss";
import { CLEAR_SELECTED_USER } from "../../store/actiontypes";
import {
  loadAuthoredEnrolledCount,
  selectedUserDetails,
  updateUser,
} from "../../store/actions/adminActions";
import UserEditModal from "../../features/AdminUserList/UserEditModal";
import MenuDropDown from "../../components/MenuDropDown/MenuDropDown";
import Breadcrumbs from "../../components/BreadCrumb/BreadCrumbs";
import ProfileTabScreen from "../../features/UserProfileFeature/UserProfileTabLayout/TabScreen/ProfileTabScreen/ProfileTabScreen";
import { avatar } from "../../libs/avatar";

function UserProfilePage() {
  const user = useSelector(SelectedUserSelector);
  const { user_name, isArchive, id } = user || {};
  const omittedPositions = [0, 3];
  const [isLoading, setIsLoading] = useState(true);
  const [showUserDetails, setShowUserDetails] = useState(false);

  const urlId = window.location.hash.split("/")[2];
  const loadUserDetail = () => {
    dispatch(selectedUserDetails(urlId));
  };
  const [authoredEnrolledCount, setAuthoredEnrolledCount] = useState({
    authored_courses: 0,
    courses_completed: 0,
    enrolled_course: 0,
  });
  const authoredEnrolledCoursesCount = () => {
    dispatch(
      loadAuthoredEnrolledCount(urlId, (apiData) => {
        setAuthoredEnrolledCount(apiData);
      })
    );
  };
  useEffect(() => {
    authoredEnrolledCoursesCount();
  }, [urlId]);

  useEffect(() => {
    loadUserDetail();
    return () => {
      dispatch({ type: CLEAR_SELECTED_USER });
    };
  }, []);

  const groupsArr = useSelector(groupArraySelector);
  const [activeTab, setActiveTab] = useState("authored");
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    // Set the active tab based on the current URL
    const pathSegments = (location.pathname + location.hash).split("/");
    const tabSegmentIndex = pathSegments.findIndex(
      (segment) =>
        segment.toLowerCase() === "authored" ||
        segment.toLowerCase() === "enrolled"
    );
    if (tabSegmentIndex !== -1) {
      const tabValue = pathSegments[tabSegmentIndex].toLowerCase();
      setActiveTab(tabValue);
    }
  }, []);

  useEffect(() => {
    if (activeTab !== null) {
      setIsLoading(false);
    }
  }, [activeTab]);

  const [displayUserEditModal, setDisplayUserEditModal] = useState(false);
  const [open, setOpen] = useState(false);

  const handleUserEditAction = () => {
    setDisplayUserEditModal(true);
    setOpen(true);
  };

  const callbackSuccessUpdate = () => {
    setOpen(false);
    dispatch(selectedUserDetails(id));
  };

  const option = [
    {
      label: "Inactive",
      value: true,
    },
    {
      label: "Active",
      value: false,
    },
  ];

  const getDefaultValue = () => {
    let selected = option.filter((o) => o.value === isArchive)[0];
    return selected;
  };
  const onClickHandler = (selectedValue) => {
    const userData = {
      user_name: user_name,
      isArchive: selectedValue[0]?.value,
    };
    dispatch(updateUser(id, userData, callbackSuccessUpdate));
  };

  const customMenuItem = ({ state }) => {
    return (
      <>
        <FontAwesomeIcon
          className={
            state.values[0].value
              ? "fontAwesome-archived"
              : "fontAwesome-unarchived"
          }
          icon={faCircle}
        />
        {state.values[0].label}
      </>
    );
  };

  const customItemRenderer = ({ item, methods }) => {
    return (
      <div
        className="custom-render-items"
        onClick={() => methods.addItem(item)}
      >
        <FontAwesomeIcon
          className={
            item.label === "Active"
              ? "fontAwesome-active"
              : "fontAwesome-Inactive"
          }
          icon={faCircle}
        />
        {"  "}
        {item.label}
      </div>
    );
  };

  const handleDisplayUserInfo = () => {
    setShowUserDetails(!showUserDetails);
  };
  const { initial, backgroundColor } = user_name
    ? avatar(user_name)
    : { initial: "", backgroundColor: "#ccc" };
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div
        className="userProfilePage-container"
        data-testid="user-profile-page"
      >
        <div className="userProfilePage-breadcrumbs">
          {user && <Breadcrumbs omit={omittedPositions} />}
        </div>
        {displayUserEditModal ? (
          <UserEditModal open={open} setOpen={setOpen} user={user} />
        ) : null}
        <div className="userProfilePage-header">
          <div
            onClick={handleDisplayUserInfo}
            className="userProfilePage-title"
          >
            <div
              className="userList-avatar"
              style={{
                backgroundColor,
              }}
            >
              {initial}
            </div>
            <div>{user_name}</div>
          </div>
          <div className="userProfilePage-header-right">
            <button
              onClick={handleUserEditAction}
              className="userProfilePage-button"
            >
              Edit
            </button>
            <MenuDropDown
              contentRenderer={customMenuItem}
              itemRenderer={customItemRenderer}
              options={option}
              defaultSelected={() => getDefaultValue()}
              onClickHandle={(selectedValue) => onClickHandler(selectedValue)}
            />
          </div>
        </div>
        {showUserDetails && (
          <div className={"userInformation-container"}>
            <ProfileTabScreen selectedUser={user} />
          </div>
        )}

        <div className="userProfilePage-contentContainer">
          {groupsArr.includes("CANDIDATE") && groupsArr.length == 1 ? null : (
            <UserProfileTabLayout
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              authoredCount={authoredEnrolledCount.authored_courses}
              completedCount={authoredEnrolledCount.courses_completed}
              enrolledCount={authoredEnrolledCount.enrolled_course}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default UserProfilePage;
