import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import "./PublicResultDisplay.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
// import PublicTestEmailContainer from "./PublicTestEmailContainer";
import { useNavigate } from "react-router-dom";
import { public_page_constants } from "../../libs/constants";
import ResultCard from "./ResultDetailsCard/ResultDetailsCards";
import eigenEval from "../../assets/eigenEval.png";
import somethingWentWrongLogo from "../../assets/something_went_wrong.webp";
import { retryPublicTest } from "../../store/actions/courseAction";
import { useDispatch } from "react-redux";
import eigenLearn from "../../assets/eigenlearn-logo-dark-large.svg";

const PublicResultDisplay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isFailed, setIsFailed] = useState(true);
  const [open, setOpen] = useState(true);
  // const [prevScore, setprevScore] = useState(null);
  const [newScore, setnewScore] = useState(null);
  const loadingStatus = sessionStorage.getItem("isLoading");
  const projectType = process.env.REACT_APP_PROJECT_TYPE;
  // const [email, setEmails] = useState(false);
  // const [emailText, setEmailText] = useState("");
  // const [contactUs, setcontactUs] = useState(false);
  const [testId, setTestId] = useState("");

  useEffect(() => {
    const pathname = window.location.hash;
    const segments = pathname.split("/");
    const id = segments[2];
    setTestId(id);
  }, []);

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    const fetchData = () => {
      const loadingState = sessionStorage.getItem("isLoading") === "true";
      const failedState = sessionStorage.getItem("isFailed") === null;
      const PREV_MARKS = sessionStorage.getItem("PREV_MARKS");
      const NEW_MARKS = sessionStorage.getItem("NEW_MARKS");

      if (isMounted) {
        setIsLoading(loadingState);
        setIsFailed(failedState);

        if (PREV_MARKS !== null && NEW_MARKS !== null) {
          setnewScore(JSON.parse(NEW_MARKS));
          // setprevScore(JSON.parse(PREV_MARKS));
        } else if (PREV_MARKS !== null) {
          setnewScore(JSON.parse(PREV_MARKS));
        }
      }

      if (isMounted && isLoading) {
        timeoutId = setTimeout(fetchData, 1500);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [sessionStorage.getItem("isLoading")]);

  const handleEnroll = (id) => {
    // navigate(`/courses/${id}/public/content`);
    window.location.assign(id);
  };

  const handleRetake = (id) => {
    navigate(`/tests/${id}/start/public`);
  };

  const handleContact = (mail_to) => {
    window.location.href = `mailto:${mail_to}?subject=Contact%20Us&body=`;
  };
  let markerPosition = "0";
  if (newScore) {
    markerPosition = `${newScore?.result?.response?.user_percentage}%`;
  }
  // const prevScores = () => {
  //   if (prevScore && newScore) {
  //     const a = newScore?.result.response?.user_percentage;
  //     const b = prevScore?.result.response?.user_percentage;
  //     return a >= b * 1.2 && a != 0;
  //   }
  //   return false;
  // };
  // const show_email_field = () => {
  //   setEmails(!email);
  //   if (prevScore && newScore) {
  //     if (prevScores()) {
  //       const text_email = `Congratulations!🎉 Your score has improved from ${prevScore.result.response.user_percentage}%  to ${newScore.result.response.user_percentage}%. Trust you enjoyed the EigenEval experience.`;
  //       setEmailText(text_email);
  //     } else {
  //       setEmailText(
  //         public_page_constants.RESULT_DECREASE(
  //           // newScore?.result?.response?.course_name
  //           "Organizational Security Readiness: A Comprehensive Evaluation"
  //         )
  //       );
  //     }
  //     setcontactUs(true);
  //   } else {
  //     if (newScore?.result?.response?.test_result === "Passed")
  //       setEmailText(
  //         public_page_constants.PASSED_MESSAGE(
  //           // newScore?.result?.response?.course_name
  //           "Organizational Security Readiness: A Comprehensive Evaluation"
  //         )
  //       );
  //     else
  //       setEmailText(
  //         public_page_constants.FAILED_MESSAGE(
  //           // newScore?.result?.response?.course_name
  //           "Organizational Security Readiness: A Comprehensive Evaluation"
  //         )
  //       );
  //     setcontactUs(false);
  //   }
  // };
  const handleRetry = () => {
    const failedId = sessionStorage.getItem("isFailed");
    dispatch(retryPublicTest(failedId));
    setIsFailed(true);
    sessionStorage.removeItem("isFailed");
  };
  const handleBackToTest = () => {
    const currentUrl = window.location.hash;
    const newUrl = currentUrl.replace("/results", "");
    window.location.href = newUrl;
  };

  return (
    <>
      {isLoading || newScore === null ? (
        <>
          {isFailed ? (
            <>
              {loadingStatus || newScore !== null ? (
                <div className="publicResultPage-spinner">
                  <Spinner
                    text={"Please wait, Your result will appear here ..."}
                  />
                </div>
              ) : (
                <FailurePage
                  handleRetry={handleBackToTest}
                  text={"Back to quiz"}
                />
              )}
            </>
          ) : (
            <FailurePage handleRetry={handleRetry} text={"Try again"} />
          )}
        </>
      ) : (
        <>
          {newScore && (
            <>
              {/* <NavBar /> */}
              <div className="Navbar-header">
                <div className="eigeneval-logo">
                  {process.env.REACT_APP_PROJECT_TYPE === "eigeneval" ? (
                    <a
                      className="navbar-image-link"
                      href="http://eigeneval.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="eigeneval-image"
                        src={eigenEval}
                        alt="EigenEval's logo"
                      />
                    </a>
                  ) : (
                    <img
                      className="eigeneval-image"
                      src={eigenLearn}
                      alt="Default EigenEval's logo"
                    />
                  )}
                </div>
                <div className="eigeneval-link">
                  {process.env.REACT_APP_PROJECT_TYPE === "eigeneval" && (
                    <a
                      className="navbar-links"
                      href="http://eigeneval.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>www.eigeneval.com</span>
                    </a>
                  )}
                </div>
              </div>
              <div className="result-display-container">
                <div className="result-display-header">
                  <p className="result-text">Results</p>
                </div>
                <div className="result-summary">
                  <div className="score-section">
                    <div className="score-text">
                      <p className="texts">Your Score</p> &nbsp;
                      <PercentageDisplay
                        percentage={newScore?.result?.response?.user_percentage}
                      />
                    </div>
                    <div className="result-bar">
                      <div className="result-box">
                        <div
                          className="marker"
                          style={{ left: markerPosition }}
                        ></div>
                      </div>
                    </div>

                    <span className="scale">
                      {[0, 25, 50, 75, 100].map((value) => (
                        <span
                          key={value}
                          className="scale-point"
                          style={{ left: `${value}%` }}
                        >
                          {value}%
                        </span>
                      ))}
                    </span>
                    {process.env.REACT_APP_PROJECT_TYPE === "eigeneval" && (
                      <div
                        className="Email"
                        onClick={() => handleRetake(testId)}
                      >
                        Retake Quiz
                      </div>
                    )}

                    {/* {email && (
                      <PublicTestEmailContainer
                        testId={newScore?.id}
                        text_email={emailText}
                        contact_us={contactUs}
                      />
                    )} */}
                  </div>
                  <div className="seperator"></div>
                  <div className="description-summary">
                    <div>
                      {projectType !== "eigeneval" ? (
                        <>
                          <div>
                            <span className="result-summary-line">
                              Trust you enjoyed the EigenLearn experience.
                            </span>
                            <span className="result-summary-line">
                              EigenLearn is a comprehensive learning platform
                              designed to support a wide range of training
                              needs, including technical stack training,
                              security training, organizational training, and
                              more.
                            </span>
                          </div>
                        </>
                      ) : newScore?.result?.response?.test_result ===
                        "Passed" ? (
                        <span className="result-summary-line">
                          <span
                            style={{
                              color: "green",
                              fontWeight: "500",
                            }}
                          >
                            Congratulations!🎉
                          </span>
                          &nbsp;
                          {public_page_constants.PASSED_MESSAGE(
                            newScore?.result?.response?.course_name
                          )}
                        </span>
                      ) : (
                        <span className="result-summary-line">
                          {public_page_constants.FAILED_MESSAGE(
                            newScore?.result?.response?.course_name
                          )}
                        </span>
                      )}
                    </div>
                    <div>
                      {projectType !== "eigeneval" ? (
                        <span>
                          {/* <p className="result-summary-line">
                            {public_page_constants["EIGENEVAL_TEXT"]}
                          </p> */}
                          <button
                            className="WizardFooter-button-secondary"
                            type="secondary"
                            onClick={() =>
                              handleContact(newScore.result.response.mail_to)
                            }
                          >
                            Contact us
                          </button>
                        </span>
                      ) : (
                        <button
                          className="WizardFooter-button-dark"
                          type="primary"
                          onClick={() =>
                            handleEnroll(newScore.result.response.course_link)
                          }
                        >
                          Quick Tour
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="details-header">
                  <div
                    onClick={() => {
                      setOpen(!open);
                    }}
                    className="accordian-header"
                  >
                    <span className="accordian-heading">Details</span>
                    <span className="accordian-icon">
                      <FontAwesomeIcon
                        icon={!open ? faChevronUp : faChevronDown}
                      />
                    </span>
                  </div>
                  <>
                    {!open && (
                      <div className="accordian-content">
                        <p className="texts">Questions</p>
                        {newScore &&
                          newScore?.result?.response?.justifications.map(
                            (value, index) => (
                              <>
                                <ResultCard
                                  index={index}
                                  question={value.question}
                                  score={value.score}
                                  user_answer={value.user_answer}
                                  rating={value.rating || null}
                                  justifications={value.justification}
                                />
                              </>
                            )
                          )}
                      </div>
                    )}
                  </>
                </div>
              </div>
              {/* <Footer /> */}
              <div className="PublicFooter-container">
                <span>
                  Powered by&nbsp;
                  <a
                    className="coriolis-name"
                    href="https://www.coriolis.co.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Coriolis Technologies Pvt. Ltd.
                  </a>
                </span>
                <span
                  className="coriolis-name"
                  onClick={() => {
                    navigate("/public/privacy_policy");
                  }}
                >
                  Privacy policy
                </span>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PublicResultDisplay;

const PercentageDisplay = ({ percentage }) => {
  return (
    <p
      style={{
        color: percentage < 40 ? "red" : "green",
      }}
      className="score-marker-text"
    >
      {percentage}%
    </p>
  );
};

const FailurePage = ({ handleRetry, text }) => {
  return (
    <div className="publicResultPage-FailedSubmission">
      <img
        src={somethingWentWrongLogo}
        alt="Something-Went-Wrong-image"
        className="error-img"
      />
      <span className="error-text">😞Oops! Something went wrong...</span>
      <span className="error-button" onClick={() => handleRetry()}>
        {text}
      </span>
    </div>
  );
};
