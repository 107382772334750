import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  currentModule,
  selectedQuizIndexSelector,
} from "../../store/selectors/coursesSelectors";
import { CURRENT_MODULE, LOAD_MY_COURSES } from "../../store/actiontypes";
import AboutModuleTest from "./AboutModuleTest";
import ExpoTestContent from "./ExpoTestContent";
import TestModuleSection from "./TestModuleSection";
import ExpoTestsDisplay from "./ExpoTestsDisplay";

import { setPublicModuleIndex } from "../../store/actions/courseAction";
import { Button, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function ContentPage(props) {
  const { course } = props;
  const [courseList, setCourseList] = useState([]);
  const [moduleProp, setModuleProp] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const selectedQuizIndex = useSelector(selectedQuizIndexSelector);

  const currentModules = useSelector(currentModule);
  const dispatch = useDispatch();

  const settingCourseList = () => {
    let moduleslist = [];
    course.forEach((element) => {
      moduleslist.push(element.module);
    });
    let obj = [
      {
        course: course[0].course,
        modules: moduleslist,
      },
    ];
    setCourseList(obj);
  };

  useEffect(() => {
    if (course !== null) {
      settingCourseList();
    }
    return () => {
      dispatch({ type: LOAD_MY_COURSES, payload: null });
      dispatch({ type: CURRENT_MODULE, payload: null });
    };
  }, [course]);

  useEffect(() => {
    let newModuleProp =
      currentModules === null
        ? course
          ? course[0].module_details.module
          : ""
        : currentModules;
    setModuleProp(newModuleProp);
  }, [courseList, currentModules]);

  useEffect(() => {
    if (courseList.length > 0) {
      dispatch({
        type: CURRENT_MODULE,
        payload: course[0]?.module_details[0],
      });
      dispatch(setPublicModuleIndex(0));
    }
  }, [courseList]);

  // Effect to handle window resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="courseContent-container">
      <div className="about-module">
        {isMobile && (
          <Button
            className="module-section-header vertical-text-button"
            onClick={() => setDrawerVisible(true)}
          >
            Sections
          </Button>
        )}
        {moduleProp?.mcq || currentModules === undefined ? (
          <ExpoTestContent
            name={
              course
                ? course[0]?.mcq_details[selectedQuizIndex]?.mcq?.test_name
                : "test name"
            }
            description={
              course
                ? course[0]?.mcq_details[selectedQuizIndex]?.mcq
                    ?.test_description
                : "test description"
            }
            course={course ? course : null}
          />
        ) : (
          <AboutModuleTest module={currentModules} course={course} />
        )}
      </div>

      {isMobile ? (
        <>
          <Drawer
            title="Sections"
            placement="right"
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
            extra={
              <Button onClick={() => setDrawerVisible(false)} type="default">
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            }
            width={300}
          >
            <div className="module-section-list">
              <TestModuleSection course={course} />
              <ExpoTestsDisplay course={course} />
            </div>
          </Drawer>
        </>
      ) : (
        <div className="module-section-list">
          <div className="module-section-header">Sections</div>
          <TestModuleSection course={course} />
          <ExpoTestsDisplay course={course} />
        </div>
      )}
    </div>
  );
}

export default ContentPage;
