import "./ModuleForm.scss";
import { Field, Form, Formik } from "formik";
import { StyledInputField } from "../../../../components/StyledFields/StyledInputField";
import * as Yup from "yup";
import { useRef } from "react";

const validationSchema = Yup.object().shape({
  module_name: Yup.string().required("Module Name is required"),
  module_description: Yup.string().required("Module Description is required"),
  module_content: Yup.string()
    .required("Module Content is required")
    .url("Module Content must be a valid URL"),
  module_assignment: Yup.string()
    .required("Module Assignment is required")
    .url("Module Assignment must be a valid URL"),
  estimated_time: Yup.string().required("Estimated Time is required e.g 2"),
});

function ModuleForm({ handleModuleFieldChange, initialValues }) {
  const ref = useRef();
  return (
    <Formik
      initialValues={{
        module_name: initialValues?.module_name
          ? initialValues?.module_name
          : "",
        module_description: initialValues?.module_description
          ? initialValues?.module_description
          : "",
        module_content: initialValues?.module_content
          ? initialValues?.module_content
          : "",
        module_assignment: initialValues?.module_assignment
          ? initialValues?.module_assignment
          : "",
        estimated_time: initialValues?.estimated_time
          ? initialValues?.estimated_time
          : 1,
      }}
      innerRef={ref}
      validationSchema={validationSchema}
      validateOnBlur
      validateOnChange
      enableReinitialize
      validateOnMount
      onSubmit={() => {}}
    >
      {({ setFieldValue, setFieldTouched }) => (
        <Form className="module-form">
          <Field
            label="Module Name"
            id="module_name"
            name="module_name"
            type="textarea"
            showCount
            maxLength={150}
            autoSize={{
              minRows: 1,
              maxRows: 1,
            }}
            component={StyledInputField}
            placeholder="Enter Module Name"
            onChange={(e) => {
              setFieldTouched("module_name", true, true);
              const textValue = e.target.value;
              setFieldValue("module_name", e.target.value);
              handleModuleFieldChange("module_name", textValue, ref);
            }}
          />
          <Field
            type="textarea"
            id="module_description"
            name="module_description"
            label="Module Description"
            placeholder="Enter module description"
            autoSize={{
              minRows: 3,
              maxRows: 3,
            }}
            showCount
            maxLength={255}
            component={StyledInputField}
            onChange={(e) => {
              setFieldTouched("module_description", true, true);
              const textValue = e.target.value;
              setFieldValue("module_description", e.target.value);
              handleModuleFieldChange("module_description", textValue, ref);
            }}
          />
          <div className="form-field-row">
            <div className="form-field">
              <Field
                type="text"
                id="module_content"
                name="module_content"
                label="Module Content"
                placeholder="Enter module content"
                component={StyledInputField}
                onChange={(e) => {
                  setFieldTouched("module_content", true, true);
                  const textValue = e.target.value;
                  setFieldValue("module_content", e.target.value);
                  handleModuleFieldChange("module_content", textValue, ref);
                }}
              />
            </div>
            <div className="form-field">
              <Field
                type="text"
                id="module_assignment"
                name="module_assignment"
                label="Module Assignment"
                placeholder="Enter module assignment"
                component={StyledInputField}
                onChange={(e) => {
                  setFieldTouched("module_assignment", true, true);
                  const textValue = e.target.value;
                  setFieldValue("module_assignment", e.target.value);
                  handleModuleFieldChange("module_assignment", textValue, ref);
                }}
              />
            </div>
          </div>
          <div className="form-field-row">
            <div className="form-field">
              <Field
                type="number"
                id="estimated_time"
                name="estimated_time"
                label="Estimated Time"
                placeholder="Estimated Time in Weeks"
                component={StyledInputField}
                defaultValue={1}
                controls={false}
                onChange={(e) => {
                  const textValue = e;
                  setFieldValue("estimated_time", e);
                  handleModuleFieldChange("estimated_time", textValue, ref);
                }}
              />
            </div>
            <div className="form-field"></div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ModuleForm;
