import { Field, Formik } from "formik";
import { Form, Radio } from "antd";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { wizardStepsSelector } from "../../../../store/selectors/wizardSelectors";
import { StyledInputField } from "../../../../components/StyledFields/StyledInputField";
import {
  handleChange,
  selectOptions,
  stepOneValidateForm,
} from "./CourseFormUtils";
import arrowDown from "../../../../assets/arrow-down.svg";

const CourseForm = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const generalInfo = useSelector(wizardStepsSelector);
  const INITIAL_FORM_VALUES = {
    course_name: generalInfo?.stepOne?.course_name
      ? generalInfo?.stepOne?.course_name
      : "",
    course_description: generalInfo?.stepOne?.course_description
      ? generalInfo?.stepOne?.course_description
      : "",
    category: generalInfo?.stepOne?.category
      ? generalInfo?.stepOne?.category
      : undefined,
    lab_required: generalInfo?.stepOne?.lab_required
      ? generalInfo?.stepOne?.lab_required
      : "YES",
    id: generalInfo?.stepOne?.id ? generalInfo?.stepOne?.id : null,
    // status: generalInfo?.stepOne?.status
    //   ? generalInfo?.stepOne?.status
    //   : "DRAFT",
  };
  return (
    <Formik
      fluid
      innerRef={ref}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={stepOneValidateForm}
      initialValues={INITIAL_FORM_VALUES}
      enableReinitialize
      isInitialValid={false}
    >
      {({ setFieldValue, setFieldTouched }) => (
        <Form className="form" data-testid="form-div">
          <Field
            label="Course Title"
            id="course_name"
            name="course_name"
            type="textarea"
            onChange={(event) => {
              handleChange(event, setFieldValue, "course_name", ref, dispatch);
            }}
            data-testid="course-tittle-input"
            showCount
            maxLength={500}
            autoSize={{
              minRows: 1,
              maxRows: 1,
            }}
            component={StyledInputField}
            placeholder="eg. Learn Python from Scratch"
          />
          <Field
            label="Course Description"
            id="course_description"
            name="course_description"
            className="text-area"
            type="textarea"
            onChange={(event) => {
              handleChange(
                event,
                setFieldValue,
                "course_description",
                ref,
                dispatch
              );
            }}
            data-testid="course-desc-input"
            component={StyledInputField}
            placeholder="It's ok if you can't think of a good description now. You can change it later."
            showCount
            maxLength={500}
            autoSize={{
              minRows: 4,
              maxRows: 5,
            }}
          />
          <Field
            label="Category"
            id="category"
            name="category"
            type="select"
            component={StyledInputField}
            onChange={(event) => {
              setFieldTouched("category", true, true);
              handleChange(event, setFieldValue, "category", ref, dispatch);
            }}
            placeholder={selectOptions[0].value}
            options={selectOptions}
            data-testid="course-select-input"
            suffixIcon={<img src={arrowDown} alt="arrow-down" />}
          />
          <div className="fields">
            <label htmlFor="lab_required" className="tittle">
              Lab Required
            </label>
            <Radio.Group
              data-testid="course-radio-input"
              id="lab_required"
              name="lab_required"
              onChange={(event) => {
                handleChange(
                  event,
                  setFieldValue,
                  "lab_required",
                  ref,
                  dispatch
                );
              }}
              value={INITIAL_FORM_VALUES.lab_required}
              buttonStyle="solid"
              className="radio-button"
            >
              <Radio.Button value="YES">Yes</Radio.Button>
              <Radio.Button value="NO">No</Radio.Button>
            </Radio.Group>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CourseForm;
