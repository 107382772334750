import EnrolledTabScreenTableListItem from "./EnrolledTabScreenListItem";
import { useLocation } from "react-router-dom";

const EnrolledTabScreenTableList = ({
  data,
  unEnrollClickhandler,
  showGraphModal,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return data?.length === 0 ? (
    <div className="userList-container-empty">
      {searchParams.size === 0 ? "No records to show!" : "No results found"}
    </div>
  ) : (
    data?.map((course) => (
      <EnrolledTabScreenTableListItem
        data={course}
        key={course?.enroll_details[0].id}
        unEnrollClickhandler={unEnrollClickhandler}
        showGraphModal={showGraphModal}
      />
    ))
  );
};

export default EnrolledTabScreenTableList;
