/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import StyledModal from "../../components/StyledModal/StyledModal";
import TestTimer from "./TestTimer";
import "./BrowserChangeModal.scss";
import { ARE_YOU_SURE, AUTO_SUBMIT, TIMES_UP } from "../../constants/McqTest";
import "./SubmitTestModal.scss";
const SubmitTestModal = ({
  open,
  setOpen,
  countTabChange,
  onTimeComplete,
  key,
  message,
}) => {
  // eslint-disable-next-line no-unused-vars
  const handleSubmit = () => {
    if (message === TIMES_UP) {
      setOpen({ autoSubmit: false, confirmation: false });
    } else {
      setOpen(false);
    }
  };

  return (
    <StyledModal
      title={message === AUTO_SUBMIT ? "Warning!" : "Test Submission"}
      className="browser-change-modal"
      centered
      open={open}
      onCancel={() => handleSubmit()}
      onOk={() => {
        onTimeComplete();
        handleSubmit();
      }}
      closable={false}
      cancelButtonProps={
        message !== ARE_YOU_SURE && {
          style: { display: "none" },
        }
      }
      okButtonProps={
        message !== ARE_YOU_SURE && {
          style: { display: "none" },
        }
      }
    >
      <div className="submit-mcq-model">
        <p className="message">
          {message}{" "}
          {message !== ARE_YOU_SURE && (
            <span className="timer">
              &nbsp;
              <TestTimer
                key={key}
                testTime={5}
                onTimeComplete={onTimeComplete}
                isSubmitModal={true}
              />
              &nbsp;seconds
            </span>
          )}
        </p>
      </div>
    </StyledModal>
  );
};

export default SubmitTestModal;
