// Import dependencies and styles
import StyledModal from "../StyledModal/StyledModal";
import "./GlobalWizard.scss";
import WizardContent from "./WizardContent";
import PropTypes from "prop-types";

/**
 * @component GlobalWizard
 * A Global wizard component with stepper
 *
 * @props
 * @param {Object} props - The props of the component.
 * @param {boolean} props.isWizardOpen - Indicates whether the wizard modal is open or not.
 * @param {function} props.handleWizardClose - Callback function to close the wizard modal.
 * @param {Array} props.actionSteps - An array of action steps for the wizard.
 * @param {string} props.currentStep - The current step of the wizard.
 *
 * @example
 * const actionSteps = [
 *   {
 *     key: "step1",
 *     title: "Step 1",
 *     container: <div>Step 1 Content</div>,
 *     isBackButtonRequired: false,
 *     nextButtonToolbar: [
 *       <button key="next" onClick={() => handleClick(dispatch, actionSteps)}>Next</button>,
 *       <button key="cancel">Cancel</button>,
 *     ],
 *     onBack: () => handleBack(dispatch, actionSteps),
 *   },
 *   // Add more steps here...
 * ];
 *
 * function ExampleComponent() {
 *   const [isWizardOpen, setIsWizardOpen] = useState(false);
 *   const [currentStep, setCurrentStep] = useState("step1");
 *
 *   const handleWizardClose = () => {
 *     setIsWizardOpen(false);
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={() => setIsWizardOpen(true)}>Open Wizard</button>
 *       <GlobalWizard
 *         isWizardOpen={isWizardOpen}
 *         handleWizardClose={handleWizardClose}
 *         actionSteps={actionSteps}
 *         currentStep={currentStep}
 *       />
 *     </div>
 *   );
 * }
 */

// GlobalWizard Component
function GlobalWizard({
  isWizardOpen,
  handleWizardClose,
  actionSteps,
  currentStep,
}) {
  // Render the GlobalWizard component
  return (
    <StyledModal
      className="wizard-modal"
      title="Create Course"
      centered
      visible={isWizardOpen}
      onCancel={handleWizardClose}
      closable={true}
      width={950}
      footer={null}
    >
      {/* Render the WizardContent component */}
      <WizardContent
        currentStep={currentStep}
        actionSteps={actionSteps}
        handleWizardClose={handleWizardClose}
      />
    </StyledModal>
  );
}

// PropTypes
GlobalWizard.propTypes = {
  isWizardOpen: PropTypes.bool.isRequired,
  handleWizardClose: PropTypes.func.isRequired,
  actionSteps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      container: PropTypes.node.isRequired,
      isBackButtonRequired: PropTypes.bool.isRequired,
      nextButtonToolbar: PropTypes.arrayOf(PropTypes.element),
      onBack: PropTypes.func.isRequired,
    })
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
};

// Export the GlobalWizard component
export default GlobalWizard;
