import "./PreviewModeRepository.scss";
import { ReactComponent as Links } from "../../assets/link.svg";
function PreviewModeRepository(props) {
  const { isStep3, steps, isStep4 } = props;
  return (
    <>
      <div>
        <div className="preview-repo-header">
          {isStep3 ? (
            <h4>Repositories</h4>
          ) : isStep4 ? (
            <h4>MCQ</h4>
          ) : (
            <h3>Repositories</h3>
          )}

          {isStep3 || isStep4 ? null : (
            <p className="preview-header-subtitle">
              (Visible in preview mode only)
            </p>
          )}
        </div>
        {isStep3 || isStep4 ? (
          <>
            <hr />
            <br />
          </>
        ) : null}
        {isStep3 && (
          <div className="details">
            <div className="repo-details">
              <div>Boilerplate code</div>
              <a
                href={isStep3 ? steps?.stepThree?.boilerplate : ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Links />
              </a>
            </div>
            <div className="repo-details">
              <div>Test Repository</div>
              <a
                href={isStep3 ? steps?.stepThree?.test_repo : ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Links />
              </a>
            </div>
            <div className="repo-details">
              <div>Solutions</div>
              <a
                href={isStep3 ? steps?.stepThree?.solutions : ""}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Links />
              </a>
            </div>
          </div>
        )}
        {isStep4 && (
          <div className="details">
            {steps?.stepFour?.map((elem, key) => {
              return (
                <div className="repo-details" key={key}>
                  <div>{elem.test_name}</div>
                  <a
                    className="repo-details-link-icon"
                    href={`#/courses/${steps.stepOne.id}/test/${elem.id}/preview`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Links />
                  </a>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
export default PreviewModeRepository;
