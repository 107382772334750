import "./McqEditSection.scss";
import { useState } from "react";

import { faEye, faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import DisplayInfoMessage from "../../../../components/DisplayInfoMessage/DisplayInfoMessage";
import Spinner from "../../../../components/Spinner/Spinner";
import { wizardDeleteTest } from "../../../../store/actions/wizardActions";
import { useDispatch } from "react-redux";
import { ReactComponent as ExclamationCircleTest } from "../../../../assets/exclamation-circle.svg";
const McqEditSection = (props) => {
  const { elem, course } = props;
  const [isLoading, setisLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();
  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setShowDelete(true);
  };

  const handleNo = (event) => {
    event.stopPropagation();
    setShowDelete(false);
  };

  const handleYes = (event, elem) => {
    event.stopPropagation();
    setisLoading(true);
    dispatch(wizardDeleteTest({ id: elem.id }, setisLoading));
  };
  return (
    <div
      className="mcqEditSection"
      style={{ backgroundColor: showDelete ? "#d1d1d1" : "#f9f9f9" }}
    >
      <div className="left-section">
        <div>{elem.test_name}</div>
        <div className="marks-row">
          {elem.number_of_questions} Questions | {elem.total_marks} Marks
        </div>
      </div>
      <div className="right-section">
        {!showDelete && (
          <>
            {elem.test_status === "IN_COMPLETE" && (
              <div className="draft-test-incomplete-indicator">Incomplete</div>
            )}
            {elem.number_of_questions > 0 ? (
              <Link
                to={`/courses/${course.id}/test/${elem.id}/preview`}
                target="_blank"
              >
                <FontAwesomeIcon
                  title="Preview"
                  icon={faEye}
                  color="#696969"
                  className="icon-hover"
                />
              </Link>
            ) : (
              <Link onClick={(event) => event.preventDefault()}>
                <FontAwesomeIcon
                  title="Preview"
                  icon={faEye}
                  color="#696969"
                  className="icon-not-allowed"
                />
              </Link>
            )}
            <Link
              to={`/courses/${course.id}/test/${elem.id}/details`}
              target="_blank"
            >
              <FontAwesomeIcon
                title="Edit"
                icon={faPencil}
                color="#696969"
                className="icon-hover"
              />
            </Link>
          </>
        )}
        {showDelete ? (
          <div className="deleteModule-container">
            <DisplayInfoMessage
              style={{
                width: "100%",
              }}
              showIcon
              message={
                <div className="deleteModule-textGroup">
                  {isLoading ? "Deleting..." : "Delete this Test ?"}{" "}
                  {isLoading ? (
                    <Spinner small={true} color={"rgba(0,0,0,0.7)"} />
                  ) : (
                    <div>
                      <button
                        data-testid="moduleTile-delete-yes"
                        className="moduleTile-deleteButton"
                        onClick={(event) => handleYes(event, elem)}
                      >
                        Yes
                      </button>{" "}
                      /{" "}
                      <button
                        data-testid="moduleTile-delete-no"
                        className="moduleTile-deleteButton"
                        onClick={handleNo}
                      >
                        No
                      </button>{" "}
                    </div>
                  )}
                </div>
              }
              type={"error"}
            />
          </div>
        ) : (
          <FontAwesomeIcon
            title="Delete"
            icon={faTrashCan}
            color="#696969"
            className="icon-hover"
            onClick={(event) => handleDeleteClick(event)}
          />
        )}
      </div>
      {elem.test_status === "IN_COMPLETE" && (
        <ExclamationCircleTest className="testStatusIncomplete" />
      )}
    </div>
  );
};

export default McqEditSection;
