/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import ReactMarkdown from "react-markdown";
import "./markDown.scss";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import remarkGfm from "remark-gfm";
import { dracula } from "react-syntax-highlighter/dist/cjs/styles/prism";
import CodeCopyBtn from "./copyCodeButton";

function MarkDowns({ content }) {
  const Pre = ({ children }) => {
    return (
      <pre className="markdown-pre" data-testid="copyCodeComp">
        {children}
        <CodeCopyBtn>{children[0]?.props?.children}</CodeCopyBtn>
      </pre>
    );
  };

  return (
    <div className="markdown-container">
      <ReactMarkdown
        children={content}
        remarkPlugins={[remarkGfm]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, "")}
                style={dracula}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
          pre: Pre,
        }}
      />
    </div>
  );
}

export default MarkDowns;
