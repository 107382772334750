import StyledModal from "../../components/StyledModal/StyledModal";
import { Button, Input, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { enrolledCourse } from "../../store/selectors/coursesSelectors";
import {
  createBranchesbyCourse,
  enrollIntoCourse,
  loadEnrolledCourses,
  unEnrollIntoCourse,
} from "../../store/actions/courseAction";
import { isNull } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import constants from "../../constants";

const UserEnrollmentModal = ({ open, setOpen, user, courseList }) => {
  const dispatch = useDispatch();
  const enrolledCourses = useSelector(enrolledCourse);
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
  const [loading, setLoading] = useState({
    spin: false,
    message: "",
  });
  const callBackLoadEnroll = () => {
    setLoading({
      ...loading,
      spin: false,
    });
  };
  function loadUserEnrolledCourses() {
    dispatch(loadEnrolledCourses(user.id, {}, callBackLoadEnroll));
    setLoading({
      ...loading,
      spin: true,
      message: "",
    });
  }
  useEffect(() => {
    loadUserEnrolledCourses();
  }, [user]);
  useEffect(() => {
    getDefaultCourse();
  }, [enrolledCourses]);

  function getDefaultCourse() {
    let dataSource = [];
    if (!isNull(courseList) && !isNull(enrolledCourses)) {
      courseList?.results?.forEach((course, index) => {
        let ifEnrolled = enrolledCourses.find((enroll) => {
          return enroll.enroll_details[0].course.id === course.id;
        });
        let data = {
          key: index,
          CourseName: course.course_name,
          Author: course.created_by,
          isEnrolled: ifEnrolled !== undefined ? true : false,
          course_id: course.id,
          enroll_id: ifEnrolled?.enroll_details[0].id,
        };
        dataSource.push(data);
      });
      setDataSource(dataSource);
      setFilteredData(dataSource);
      setLoading({ ...loading, spin: false });
    }
  }
  const columns = [
    {
      title: "Course Name",
      dataIndex: "CourseName",
      key: "CourseName",
    },
    {
      title: "Author",
      dataIndex: "Author",
      key: "Author",
      render: (_, elem) => (
        <>{elem.Author !== null ? elem.Author.user_name : "Coriolis Team"}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, elem) => (
        <Button
          size="medium"
          className={`enroll-buttons  ${
            elem.isEnrolled ? "unenroll-color" : "enroll-color"
          } `}
          onClick={() => onOk(elem.course_id, elem.isEnrolled, elem.enroll_id)}
          data-testid={"enroll-button-" + elem.course_id}
        >
          {elem.isEnrolled ? constants.UN_ENROLL : constants.ENROLL}
        </Button>
      ),
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const enrollCourseCallback = (respPromise, dispatch, getState) => {
    setLoading({
      ...loading,
      spin: true,
      message: "Please wait.. Enrollment in progress",
    });
    respPromise
      .then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          if (resp["data"]?.data?.course?.lab_required === "YES") {
            setLoading({
              ...loading,
              spin: true,
              message: "Please wait.. Creating Branches",
            });
            dispatch(
              createBranchesbyCourse(
                resp["data"]?.data?.course?.id,
                user.emp_id,
                () => {
                  loadUserEnrolledCourses();
                }
              )
            );
          } else {
            loadUserEnrolledCourses();
          }
        }
      })
      .catch(() => {
        setLoading({ ...loading, spin: false });
      });
  };

  // eslint-disable-next-line no-unused-vars
  const unEnrollCourseCallback = (respPromise, dispatch, getState) => {
    setLoading({
      ...loading,
      spin: true,
      message: "Please wait.. Unenrollment in progress",
    });
    respPromise
      .then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          loadUserEnrolledCourses();
        }
      })
      .catch(() => {
        setLoading({ ...loading, spin: false });
      });
  };

  const onOk = (course_id, isEnrolled, enroll_id) => {
    if (!isEnrolled) {
      dispatch(
        enrollIntoCourse(
          {
            user_id: user.id,
            course_id: course_id,
          },
          enrollCourseCallback
        )
      );
    } else {
      dispatch(unEnrollIntoCourse(enroll_id, unEnrollCourseCallback));
    }
  };

  const handleInputChange = (e) => {
    setFilteredData(
      dataSource.filter((elem) => {
        return elem.CourseName.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      })
    );
  };
  const close = () => {
    setOpen(false);
  };
  return (
    <StyledModal
      title={`Course Enrollment For ${user.user_name}`}
      centered
      open={open}
      closable={false}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={close}
          disabled={loading.spin}
          data-testid="close-button"
        >
          Close
        </Button>,
      ]}
      width={"867px"}
    >
      <div
        className={`${
          dataSource.length > 0 ? "enroll-model-table" : "enroll-model-no-table"
        }`}
        style={{ paddingLeft: "24px", paddingRight: "24px" }}
      >
        {dataSource.length > 0 && (
          <Input
            placeholder="Search Courses"
            allowClear
            onChange={handleInputChange}
            className="input-box"
            data-testid="input-box"
          />
        )}
        <Spin
          tip={dataSource.length > 0 ? loading.message : ""}
          spinning={loading.spin}
          indicator={antIcon}
        >
          {dataSource.length > 0 && (
            <Table columns={columns} dataSource={filteredData} />
          )}
        </Spin>
      </div>
    </StyledModal>
  );
};

export default UserEnrollmentModal;
