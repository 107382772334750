// import React from 'react'

import { useSelector } from "react-redux";
import { generateAccordianStyleForTest } from "../../../../libs/utils";
import { pipelineStatusIcon } from "../../../../libs/utils";
import { currentModule } from "../../../../store/selectors/coursesSelectors";
import { CURRENT_MODULE } from "../../../../store/actiontypes";
import { useDispatch } from "react-redux";
import "./TestDisplay.scss";
const TestsDisplay = ({ quiz, isEnrolled, isPreview }) => {
  const currentModules = useSelector(currentModule);
  const dispatch = useDispatch();
  const onclickhandler = (ele) => {
    dispatch({
      type: CURRENT_MODULE,
      payload: ele,
    });
  };
  return (
    <div className={"test-section-enrolled"}>
      <div className="test-display">
        {/* <ModuleButtons {...props} /> */}
        <>
          {quiz.map((element, index) => {
            return (
              <div
                key={index}
                className={generateAccordianStyleForTest(
                  element,
                  currentModules,
                  isPreview,
                  isEnrolled
                )}
                onClick={() => onclickhandler(element)}
              >
                <p className={"enrolled-title"}>
                  <div>Quiz: {element.mcq?.test_name}</div>

                  <div>{pipelineStatusIcon(element.mcq_progress)}</div>
                </p>
                <p className={"enrolled-subtitle"}>
                  <>{element.mcq?.test_description}</>
                </p>
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
};

export default TestsDisplay;
