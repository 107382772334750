import { useDispatch, useSelector } from "react-redux";
import { selectedCourseSelector } from "../../../../store/selectors/coursesSelectors";
import { useEffect, useState } from "react";
import "./AboutCourse.scss";
import { userSelector } from "../../../../store/selectors/authSelectors";
import Spinner from "../../../../components/Spinner/Spinner";
import { ratingsSelector } from "../../../../store/selectors/ratingSelector";
import { getAllRating } from "../../../../store/actions/ratingActions";
import { REMOVE_RATINGS } from "../../../../store/actiontypes";
import UserReviewCard from "../../../../components/UserReviewCard/UserReviewCard";
import UserFeedbackCard from "../../../../components/UserFeedbackCard/UserFeedbackCard";
import CourseInfo from "../../../../components/CourseInfo/CourseInfo";
import { isPreviewSelector } from "../../../../store/selectors/wizardSelectors";
import PreviewModeRepository from "../../../../components/PreviewModeRespository/PreviewModeRepository";
import { getCoursePipelineStatus } from "../../../../store/actions/courseAction";
import { useParams } from "react-router-dom";

const bool = window.location.href.includes("/public");
const ReviewsSection = (props) => {
  const { courseId } = props;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const ratings = useSelector(ratingsSelector);

  const allRatings = () => {
    setIsLoading(true);
    dispatch(getAllRating({ course: courseId, limit: 3 }));
  };

  useEffect(() => {
    if (courseId !== undefined) {
      allRatings();
    }
    return () => {
      dispatch({ type: REMOVE_RATINGS });
    };
  }, [courseId]);

  useEffect(() => {
    if (ratings?.results?.length >= 0) {
      setIsLoading(false);
    }
  }, [ratings?.results?.length]);

  useEffect(() => {
    return () => {
      dispatch({ type: REMOVE_RATINGS });
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {ratings?.results?.length === 0 ? (
            <div className="no-reviews">No reviews to show</div>
          ) : (
            <div className="aboutReviews-list">
              {ratings?.results?.map((rating) => {
                return (
                  <UserReviewCard
                    key={rating?.id}
                    rating={rating}
                    bordered={true}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

function AboutCourse() {
  const course = useSelector(selectedCourseSelector);
  const user = useSelector(userSelector);
  const isPreview = useSelector(isPreviewSelector);
  const [courseList, setCourseList] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (course?.length > 0) {
      const moduleslist = course.map((element) => element.module);
      const obj = [
        {
          course: course[0].course,
          modules: moduleslist,
        },
      ];
      setCourseList(obj);
    }
  }, [course]);

  useEffect(() => {
    if (!bool && id) {
      dispatch(getCoursePipelineStatus(id));
    }
  }, [id, dispatch]);

  if (!course || course.length === 0) {
    return <Spinner />;
  }

  const createdBy = bool
    ? course[0].course?.created_by?.user_name
    : course[0].created_by?.user_name;
  return (
    <div className="tab-screen-about-container">
      <>
        <CourseInfo
          course={courseList[0]?.course}
          isEnrolled={true}
          createdBy={createdBy}
        />
        {!bool &&
          (isPreview ? (
            <PreviewModeRepository />
          ) : (
            <>
              <div
                className="about-reviewContainer"
                data-testid="about-reviewContainer"
              >
                <UserFeedbackCard
                  userId={user?.id}
                  courseId={courseList[0]?.course?.id}
                />
              </div>
              <div className="about-reviews">
                <div className="reviews-title">Reviews</div>
                <ReviewsSection courseId={courseList[0]?.course?.id} />
              </div>
            </>
          ))}
      </>
    </div>
  );
}

export default AboutCourse;
