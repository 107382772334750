import GlobalBellCurve from "../../../../../../../components/GlobalBellCurve/GlobalBellCurve";
import "./CreateCourseComparsionBellCurve.scss";

function CreateCourseComparsionBellCurve({ data }) {
  const bellCurveDatasets = [
    {
      datapoints: !data ? null : data,
      backgroundColor: "rgba(255, 185, 81, 0.09)",
      lineColor: "#FFB951",
    },
  ];
  // Rendering the Bell Curve component
  return <GlobalBellCurve datasets={bellCurveDatasets} />;
}

export default CreateCourseComparsionBellCurve;
