import { ALL_RATINGS, MY_RATING, REMOVE_RATINGS } from "../actiontypes";

const initialState = { ratings: null, myRating: null };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ALL_RATINGS:
      return { ...state, ratings: payload };
    case MY_RATING:
      return { ...state, myRating: payload };
    case REMOVE_RATINGS:
      return { ...state, ratings: null };
    default:
      return state;
  }
}
