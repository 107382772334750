/* eslint-disable no-unused-vars */
import {
  ALL_USERS,
  CLEAR_SELECTED_USER,
  SELECTED_USER,
} from "../actiontypes/index";

const initialState = { users: null, selectedUser: null };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ALL_USERS:
      return { ...state, users: payload };
    case SELECTED_USER:
      return { ...state, selectedUser: payload };
    case CLEAR_SELECTED_USER:
      return { ...state, selectedUser: null };
    default:
      return state;
  }
}
