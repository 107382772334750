import { useDispatch, useSelector } from "react-redux";
import { CURRENT_MODULE } from "../../store/actiontypes";
import { currentModule } from "../../store/selectors/coursesSelectors";
import Spinner from "../../components/Spinner/Spinner";
import {
  setPublicModuleIndex,
  setSelectedQuizIndex,
} from "../../store/actions/courseAction";
const ExpoTestsDisplay = (props) => {
  const { course } = props;
  const currentModules = useSelector(currentModule);
  const dispatch = useDispatch();

  const onClickHandler = (ele, index) => {
    dispatch({
      type: CURRENT_MODULE,
      payload: ele,
    });
    dispatch(setPublicModuleIndex(course[0]?.module_details.length + index));
    dispatch(setSelectedQuizIndex(index));
  };

  if (course === null) {
    return <Spinner />;
  }

  return (
    <div className={"test-section-enrolled"}>
      <div className="test-display">
        <>
          {course[0]?.mcq_details?.map((element, index) => {
            return (
              <div
                key={index}
                className={
                  currentModules?.mcq?.id === element?.mcq?.id
                    ? "accordian-style-in-progress"
                    : "accordian-style"
                }
                onClick={() => onClickHandler(element, index)} //Pass index
              >
                <p className={"enrolled-title"}>{element.mcq?.test_name}</p>
                <p className={"enrolled-subtitle"}>
                  <>{element.mcq?.test_description}</>
                </p>
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
};

export default ExpoTestsDisplay;
