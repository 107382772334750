/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import { Dropdown, Select } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import {
  PORTAL_ADMIN_USERS_MENU as items,
  PORTAL_ADMIN_ROLES_MENU,
} from "../../constants";
import { PORTAL_ADMIN_ROLES_MENU as options } from "../../constants";
import { useEffect, useState } from "react";
import "../../components/MultiSelectDropDown/MultiSelectDropDown.scss";
import MultiSelectDropDown from "../../components/MultiSelectDropDown/MultiSelectDropDown";
import { useSelector } from "react-redux";
import {
  groupSelector,
  isCancelledSelector,
} from "../../store/selectors/authSelectors";
import { ISCANCELLED } from "../../store/actiontypes";
import { useDispatch } from "react-redux";
import { isCancelled } from "../../store/actions/authActions";
import { useNavigate } from "react-router-dom";
import { avatar } from "../../libs/avatar";

const handleMenuClick = (
  e,
  user,
  editClickHandler,
  archiveClickHandler,
  enrollClickHandler
) => {
  if (e.key === "Edit") editClickHandler(user, e.key);
  if (e.key === "Archive") archiveClickHandler(user, e.key);
  if (e.key === "Enroll") enrollClickHandler(user, e.key);
};

const UserListItem = ({
  user,
  index,
  editClickHandler,
  archiveClickHandler,
  enrollClickHandler,
  roleSelectHandle,
}) => {
  const { user_name, emp_id, role = 0, isArchive } = user;
  const menu_group = [];
  for (const x in user.groups) {
    menu_group.push(PORTAL_ADMIN_ROLES_MENU[user.groups[x].id - 1]);
  }

  const menuProps = {
    items,
    onClick: (e) => {
      return handleMenuClick(
        e,
        user,
        editClickHandler,
        archiveClickHandler,
        enrollClickHandler
      );
    },
  };
  const dispatch = useDispatch();
  const iscancel = useSelector(isCancelledSelector);
  useEffect(() => {
    if (iscancel) {
      setValue(menu_group);
      dispatch(isCancelled(false));
    }
  }, [iscancel]);
  const handleDropdownVisibleChange = (open) => {
    if (
      !open &&
      JSON.stringify(value) !== JSON.stringify(menu_group) &&
      value.length > 0
    ) {
      // Dropdown is closing
      onClickHandle(value); // Call your onClickHandle function
    }
    if (value.length === 0) {
      setValue(menu_group);
    }
  };
  const onClickHandle = (selectedValues) => {
    // Handle the onClick event when the dropdown closes
    roleSelectHandle(role, user_name, user.id, selectedValues, menu_group);
  };
  const [value, setValue] = useState(menu_group);
  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value,
    options,
    onChange: (newValue, selectedOptions) => {
      setValue(selectedOptions);
    },
    placeholder: "Select Groups(At least one is required)",
    maxTagCount: 1,
    onDropdownVisibleChange: handleDropdownVisibleChange,
  };
  const navigate = useNavigate();
  const clickUserHandle = (id) => {
    navigate(`/users/${id}/authored`);
  };
  const { initial, backgroundColor } = avatar(user_name);
  return (
    <div key={emp_id} className="userList-container">
      <div
        onClick={() => {
          clickUserHandle(user.id);
        }}
        className="userList-title"
      >
        <div
          className="userList-avatar"
          style={{
            backgroundColor,
          }}
        >
          {initial}
        </div>
        <div>{user_name}</div>
      </div>
      <div className="userList-title">{emp_id}</div>
      <div className="userList-title">
        <MultiSelectDropDown selectProps={selectProps} />
      </div>
      <div className="userList-title">{isArchive ? "Inactive" : "Active"}</div>
      <div className="userList-option" data-testid="userList-option">
        {!isArchive ? (
          <Dropdown
            menu={menuProps}
            trigger={["click"]}
            placement="bottomRight"
          >
            <MoreOutlined
              style={{
                fontSize: "16px",
                color: "#000",
              }}
            />
          </Dropdown>
        ) : null}
      </div>
    </div>
  );
};

function UserList({
  data,
  editClickHandler,
  archiveClickHandler,
  enrollClickHandler,
  roleSelectHandle,
  // selectedRoleCallback,
}) {
  if (data?.length === 0) {
    return (
      <div className="userList-container-empty">
        <div>No records to show!</div>
      </div>
    );
  }
  return data?.map((user, index) => (
    <UserListItem
      key={user.id}
      user={user}
      idex={index}
      editClickHandler={editClickHandler}
      archiveClickHandler={archiveClickHandler}
      enrollClickHandler={enrollClickHandler}
      roleSelectHandle={roleSelectHandle}
    />
  ));
}

export default UserList;
