// eslint-disable-next-line no-unused-vars
import { useEffect, useRef, useState } from "react";
import "./ModulesList.scss";
import ModuleTile from "./ModuleTile";
import { wizardStepsSelector } from "../../../../store/selectors/wizardSelectors";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { rearrangeWizardStepTwo } from "../../../../store/actions/wizardActions";
import { useDispatch } from "react-redux";

function ModulesList({ isCreating }) {
  const dispatch = useDispatch();
  const modules = useSelector(wizardStepsSelector).stepTwo;
  const modulesCount = useSelector(wizardStepsSelector).stepTwo?.length;
  const [draggedItems, setDraggedItems] = useState(null);
  const containerRef = useRef(null);

  const adjustHeight = () => {
    const containerHeight = containerRef.current.clientHeight;
    containerRef.current.style.minHeight = `${containerHeight + 10}px`;
  };

  useEffect(() => {
    adjustHeight();
  }, [modulesCount]);

  useEffect(() => {
    if (modules !== null) setDraggedItems(modules);
  }, [modules]);

  useEffect(() => {
    if (containerRef.current) {
      adjustHeight();
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    // Reorder the items based on the drag-and-drop result
    const reorderedItems = [...draggedItems];
    const [removed] = reorderedItems.splice(result.source.index, 1); // Remove the dragged item
    reorderedItems.splice(result.destination.index, 0, removed); // Insert it at the destination index
    dispatch(rearrangeWizardStepTwo(reorderedItems));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="modules" direction="vertical">
        {(provided) => (
          <div
            className="modulesListStep-container"
            {...provided.droppableProps}
            ref={(el) => {
              containerRef.current = el;
              provided.innerRef(el);
            }}
          >
            {modules === undefined || modules?.length === 0
              ? !isCreating && (
                  <div className="message">
                    No Modules Yet! Add at least 1 module to create a course.
                  </div>
                )
              : modules.map((module, index) => (
                  <Draggable
                    key={module.id || module.key}
                    draggableId={
                      module.id ? module.id.toString() : module.key.toString()
                    }
                    index={index}
                  >
                    {(provided) => (
                      <div>
                        <ModuleTile
                          data={module}
                          key={index}
                          tileRef={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default ModulesList;
