/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useDispatch } from "react-redux";
import StyledModal from "../../components/StyledModal/StyledModal";
import { archiveUser } from "../../store/actions/adminActions";
import { useState } from "react";

const UserArchiveModal = ({ open, setOpen, user }) => {
  // eslint-disable-next-line no-unused-vars
  const userBody = {
    user_name: user.user_name,
    email: user.email,
  };
  const callbackSuccessUpdate = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(archiveUser(user.id, userBody, callbackSuccessUpdate));
  };

  return (
    <StyledModal
      title={"Please Confirm"}
      centered
      open={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      width={"100"}
      closable={false}
    >
      <div style={{ height: "2px", padding: "15px 24px 20px 24px" }}>
        Are you sure you want to archive {user.user_name} ?
      </div>
    </StyledModal>
  );
};

export default UserArchiveModal;
