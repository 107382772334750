import { useEffect, useState } from "react";
import "./BrowserChangeModalPublic.scss";
import "./SubmitTestModalPublic.scss";
import StyledModal from "./StyledModalPublic";
import { ARE_YOU_SURE, AUTO_SUBMIT, TIMES_UP } from "../../constants/McqTest";
import TestTimer from "../../components/TestContent/TestTimer";
import { useNavigate, useParams } from "react-router-dom";

const SubmitTestModalPublic = ({
  open,
  setOpen,
  key,
  message,
  submitLoader,
  onTimeComplete,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isColor, setisColor] = useState(false);
  const name = "",
    email = "";

  useEffect(() => {
    if (message === AUTO_SUBMIT) {
      const timer = setTimeout(() => {
        handleSubmit();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setisColor(true);
    setIsSubmitting(true);
    setIsLoading(true);
    onTimeComplete(email, name, setisColor);
    sessionStorage.setItem("isLoading", true);
    navigate(`/tests/${id}/start/public/results`);
  };

  return (
    <StyledModal
      title={message === AUTO_SUBMIT ? "Warning!" : "Quiz Submission"}
      className="browser-change-modal"
      centered
      open={open}
      onCancel={handleCancel}
      onOk={() => {
        // onTimeComplete();
        handleSubmit();
      }}
      closable={false}
      cancelButtonProps={
        message !== ARE_YOU_SURE ||
        (isLoading && {
          style: { display: "none" },
        })
      }
      okButtonProps={{
        className: isSubmitting ? "submitting" : "",
        children: (
          <>
            {submitLoader ? <span className="button-loader"></span> : "Submit"}
          </>
        ),
        ...((message !== ARE_YOU_SURE && message !== TIMES_UP) ||
          (isLoading && {
            style: { display: "none" },
          })),
      }}
      maskClosable={false}
      isColor={isColor}
      confirmLoading={isColor}
      isLoading={isLoading}
    >
      <>
        <div className="submit-mcq-model">
          <p className="message">
            {message}{" "}
            {message !== ARE_YOU_SURE && (
              <span className="timer">
                &nbsp;
                <TestTimer
                  key={key}
                  testTime={5}
                  onTimeComplete={handleSubmit}
                  isSubmitModal={true}
                />
                &nbsp;seconds
              </span>
            )}
          </p>
        </div>
      </>
    </StyledModal>
  );
};

export default SubmitTestModalPublic;
