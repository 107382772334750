import NavBar from "../../components/NavBar/NavBar";
import "./PrivacyPolicy.scss";
import Footer from "../../components/Footer/Footer";
const PrivacyPolicy = () => {
  const projectType =
    process.env.REACT_APP_PROJECT_TYPE === "eigeneval"
      ? "EigenEval"
      : "EigenLearn";
  return (
    <>
      <NavBar />
      <div className="privacypolicycontainer">
        <h1 className="heading1">Privacy Policy</h1>
        <p>
          <em>Last updated: 20-10-2024</em>
        </p>
        <h2 className="heading2">Overview</h2>
        <p>
          This Privacy Policy explains how {projectType} handles data on our
          evaluation platform. Our platform is designed to respect your privacy
          and operates with minimal data processing.
        </p>
        <h2 className="heading2">Data Collection</h2>
        <ul>
          <li>
            We do not collect or store any personal information from users.
          </li>
          <li>
            Our application does not use cookies or any tracking technologies.
          </li>
        </ul>
        <h2 className="heading2">Question Evaluation</h2>
        <ul>
          <li>Some quiz questions are evaluated using the OpenAI GPT model.</li>
          <li>
            Only the necessary question data is sent to OpenAI for evaluation
            purposes. No personal data is transmitted or stored.
          </li>
          <li>
            Other than this, we do not share user data with any third-party
            services.
          </li>
          <li>
            The results of the evaluations are processed and displayed on the
            platform, but no user-specific information is stored.
          </li>
        </ul>
        <h2 className="heading2">Third-Party Services</h2>
        <ul>
          <li>
            The only third-party service we use is OpenAI for question
            evaluation. We recommend reviewing OpenAI's Privacy Policy for
            further details on how they handle data.
          </li>
        </ul>
        <h2 className="heading2">Data Storage</h2>
        <ul>
          <li>
            We do not store any user data on our servers beyond what is required
            for quiz evaluation.
          </li>
          <li>
            No personal information is stored or shared with any other services
            or third parties.
          </li>
        </ul>
        <h2 className="heading2">Changes To This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy as needed. The latest version will
          always be available on this page.
        </p>
      </div>
      <Footer />
    </>
  );
};
export default PrivacyPolicy;
