/* eslint-disable no-loss-of-precision */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  clearGraphValues,
  loadBellCurveDataForUser,
  loadCourseGraphDataForUser,
} from "../../../../../../store/actions/graphActions";
import StyledModal from "../../../../../../components/StyledModal/StyledModal";
import Spinner from "../../../../../../components/Spinner/Spinner";
import CourseProgressGraph from "./CourseProgressGraph/CourseProgressGraph";
import "./CreateGraphSummaryModal.scss";
import { useSelector } from "react-redux";
import {
  getBellCurveValues,
  getCourseSummaryValues,
} from "../../../../../../store/selectors/graphSelectors";
import { GRAPH_SUMMARY_MODAL_ID } from "../../../../../../constants";
import CreateCourseComparsionBellCurve from "./CreateCourseComparisonBellCurve/CreateCourseComparsionBellCurve";

function CreateGraphSummaryModal({ isOpen, enrollId, closeGraphModal }) {
  // Redux Dispatch
  const dispatch = useDispatch();

  // State for Loading
  const [isLoading, setIsLoading] = useState(true);

  // State for Course Summary Data
  const courseSummaryData = useSelector((state) =>
    getCourseSummaryValues(state, GRAPH_SUMMARY_MODAL_ID)
  );

  // State for Bell Curve Data
  const bellCurveData = useSelector((state) =>
    getBellCurveValues(state, GRAPH_SUMMARY_MODAL_ID)
  );

  // Effect to load data when the modal is open
  useEffect(() => {
    if (isOpen) {
      dispatch(loadCourseGraphDataForUser(enrollId, GRAPH_SUMMARY_MODAL_ID));
      dispatch(loadBellCurveDataForUser(enrollId, GRAPH_SUMMARY_MODAL_ID));
    }
  }, [isOpen, enrollId, dispatch]);

  // Effect to handle loading state
  useEffect(() => {
    if (isOpen) {
      if (courseSummaryData && bellCurveData) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    }
  }, [isOpen, courseSummaryData, bellCurveData]);

  // Function to handle cancellation and reset the state
  const handleCancel = () => {
    closeGraphModal();
    setIsLoading(true);
    dispatch(clearGraphValues(GRAPH_SUMMARY_MODAL_ID));
  };

  // Rendering the Modal
  return (
    <StyledModal
      visible={isOpen}
      onCancel={handleCancel}
      closable={true}
      centered
      title={<div style={{ marginLeft: "30px" }}>Summary</div>}
      footer={null}
      width={"875px"}
      childrenClassname={"graphsummarymodal-main"}
    >
      <div className="graphSummaryModal-container">
        {isLoading ? (
          // Display a Spinner while loading
          <div className="graphSummary-container-loading">
            <Spinner />
          </div>
        ) : (
          // Display the Course Progress Graph and Bell Curve when data is available
          <div className="graphSummary-container">
            <CourseProgressGraph data={courseSummaryData} />
            <CreateCourseComparsionBellCurve data={bellCurveData[0]} />
          </div>
        )}
      </div>
    </StyledModal>
  );
}

export default CreateGraphSummaryModal;
