/* eslint-disable no-unused-vars */
import { actionFactory } from "../../libs/actionFactory";
import { encodeData } from "../../libs/jwt";
import {
  CLEAR_COURSES,
  GOOGLE_CLIENT_ID,
  ISCANCELLED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../actiontypes/index";

export const loadUserDetails = () => {
  return actionFactory({
    api: "get/api/users/user_details/",
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            const picture = localStorage.getItem("picture");
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { ...resp["data"].data, picture },
            });
          }
        })
        .catch((err) => {
          if (err["response"].data.status.status_message === "Token Expired") {
            clearLocalStorage();
            window.location.replace("/#/login");
          } else {
            clearLocalStorage();
            window.location.reload("/#/login");
          }
        });
    },
  });
};

export const registerUser = (user, picture) => {
  return actionFactory({
    api: "post/api/users/register/",
    actionBody: {
      body: user,
    },
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            // dispatch({
            //   type: LOGIN_SUCCESS,
            //   payload: { ...resp["data"].data.userDetails, picture },
            // });
            //commented beacuse it was calling API ?user=undefined while calling enroll api on userDAshboard page
            dispatch(loadUserDetails());
          } else {
            dispatch({ type: LOGIN_FAIL });
            dispatch(logoutUser());
          }
        })
        .catch((err) => {
          clearLocalStorage();
        });
    },
  });
};

export const validateTokenAction = (userData, handleError = () => {}) => {
  return actionFactory({
    api: "post/api/auth/validate-token",
    failureToast: true,
    actionBody: {
      body: userData,
    },
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            encodeData(resp["data"].data);
            localStorage.setItem("picture", resp["data"].data.picture);
            dispatch(
              registerUser(
                {
                  // emp_id: "CTE-1092",
                  user_name: resp["data"].data.name,
                  email: resp["data"].data.email,
                },
                resp["data"].data.picture
              )
            );
          } else {
            dispatch(logoutUser());
          }
        })
        .catch((_) => {
          clearLocalStorage();
          handleError();
        });
    },
  });
};

export const getClientId = () => {
  return actionFactory({
    api: "get/api/client-id",
    failureToast: true,
    actionBase: GOOGLE_CLIENT_ID,
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: GOOGLE_CLIENT_ID,
            payload: resp["data"].data,
          });
        })
        .catch((_) => {});
    },
  });
};

const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("picture");
};

export const logoutUser = (dispatch) => {
  clearLocalStorage();
  dispatch({ type: CLEAR_COURSES });
  return dispatch({ type: LOGOUT });
};
export const isCancelled = (payload) => ({
  type: ISCANCELLED,
  payload: payload,
});
