import "./Pagination.scss";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuDropDown from "../MenuDropDown/MenuDropDown";

const Pagination = (props) => {
  const {
    defaultValue, // limit value
    onClickHandlerDefaultValue,
    totalCount,
    offSetValue, //offset value
    onClickHandlerOffSetValue,
    userList,
  } = props;
  //const userList = useSelector((state) => AllUserSelector(state));
  const nextPage = () => {
    const offsetMatch = userList?.next?.match(/offset=(\d+)/);
    if (offsetMatch) {
      const nextOffset = parseInt(offsetMatch[1]);
      onClickHandlerOffSetValue(nextOffset);
    }
  };
  const prevPage = () => {
    const offsetMatch = userList?.previous?.match(/offset=(\d+)/);
    if (offsetMatch) {
      const prevoffset = parseInt(offsetMatch[1]);
      onClickHandlerOffSetValue(prevoffset);
    } else {
      if (userList.previous !== null) {
        onClickHandlerOffSetValue(0);
      }
    }
  };
  const option = [
    { label: 5, value: 0 },
    { label: 10, value: 1 },
    { label: 15, value: 2 },
    { label: 20, value: 3 },
    { label: 25, value: 4 },
    { label: 50, value: 5 },
    { label: 100, value: 6 },
  ];

  const getDefaultValue = () => {
    let selected = option.filter((o) => o.label === defaultValue)[0];
    return selected;
  };

  return (
    <div data-testid="pagination-container" className="pagination-container">
      {/*<div className="itemsPerPage"> */}
      <p className="pageDetail">Items per page</p>
      <MenuDropDown
        options={option}
        defaultSelected={() => getDefaultValue()}
        onClickHandle={(selectedVal) => onClickHandlerDefaultValue(selectedVal)}
      />
      <p className="paraInfo1">{totalCount > 0 ? offSetValue + 1 : 0} </p>
      <p className="paraInfo">-</p>
      <p className="paraInfo">
        {offSetValue + defaultValue > totalCount
          ? totalCount
          : offSetValue + defaultValue}{" "}
      </p>
      <p className="paraInfo">of</p>
      <p className="paraInfo2">{totalCount}</p>
      <button
        onClick={prevPage}
        className="pagination-button-prev"
        data-testid="prevPage"
      >
        <FontAwesomeIcon
          size="sm"
          icon={faChevronLeft}
          color={offSetValue > 0 ? "#222222" : "#ADACAA"}
        />
      </button>
      <button
        onClick={nextPage}
        className="pagination-button-next"
        data-testid="nextPage"
      >
        <FontAwesomeIcon
          size="sm"
          icon={faChevronRight}
          color={
            offSetValue + defaultValue < totalCount ? "#222222" : "#ADACAA"
          }
        />
      </button>
      {/*</div> */}
    </div>
  );
};

export default Pagination;
